/* eslint-disable no-async-promise-executor */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import React, { useEffect, useState } from 'react';
import produtoService from '../Services/Produto/produto-service';
import authService from '../Services/auth/auth-service';
import clienteService from '../Services/cliente/cliente-service';
import oportunidadeService from '../Services/oportunidade/oportunidade-service';
import BlueButton from '../components/button/BlueButton';
import MenuComponent from '../components/menu/menu-component';
import FacebookCircularProgress from '../components/progress/CircularProgressBlue';
import BlueTextField from '../components/textfield/BlueTextField';
import MaskUtil from '../util/MaskUtil';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginBottom: 20,
		display: 'flex',
		flexDirection: 'column',
		padding: '15px 20px 15px',
		borderRadius: 10,
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '20px 10px'
		}
	},
    progress: {
        color: '#209BDE',
    },
	tituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '22px',
		textAlign: 'start',
		padding: '0 0px',
		marginBottom: '5px'
	},
	subtituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#7F7F7F',
		fontSize: '16px',
		fontWeight: 'inherit',
		textAlign: 'start',
		padding: '0 0px',
		marginTop: '0px'
	}
}));



export default function VendaPage() {
  const [produtos, setProdutos] = React.useState([]);
  const [, setCliente] = useState([]);
  const [carrinho, setCarrinho] = React.useState([]);
  const [mensagem, setMensagem] = React.useState(null);
  const [clienteCompra, ] = React.useState(null);
  const [qtdeVeiculo, ] = React.useState(0);
  const [load, setLoad] = React.useState(false);
  const [parcela, ] = React.useState(1);
	const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [novoProduto, setNovoProduto] = useState({ produto: null, quantidade: 0, valor: 0, descricao: '', valorTotal: 0 });

  useEffect(() => {
    let usuario = authService.getUserInfo().usuario;

    console.log(usuario)
    clientes(usuario.tz_area_atendimento_microsigaid);
    consultaProdutos();

  }, []);

  const consultaProdutos = async () => {
    const produtosResult = await produtoService.produtos();
    console.log(produtosResult)
    if (produtosResult)
      setProdutos(produtosResult.produtos);


  }
  const handleDialogClose = () => {
    setOpenDialog(false);
    setNovoProduto({ produto: null, quantidade: 0, valor: 0, descricao: '', valorTotal: 0 });
  };

  const clientes = async (areaAtendimentoID) => {

    try {
      let clientesResult = await clienteService.ClientesByRevenda(areaAtendimentoID);

      console.log(clientesResult)
      if (clientesResult) {
        let clientes = [];

        clientesResult.map((x) => {
          clientes.push({
            id: x.ClienteID || '',
            nome: x.Nome || '',
            cpfcnpj: x.CPFCNPJ || '',
            celular: x.Celular || '',
            telefone: x.Telefone || '',
          });
        });

        setCliente(clientes);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleProdutoChange = (event, value) => {
    setNovoProduto({
      ...novoProduto,
      produto: value,
      valor: value ? value.valor : 0,
      descricao: value ? value.descricao : '',
      valorTotal: value ? value.valor * novoProduto.quantidade : 0
    });
  };

  const handleQuantidadeChange = (event) => {
    const quantidade = event.target.value;
    setNovoProduto({
      ...novoProduto,
      quantidade: quantidade,
      valorTotal: quantidade * novoProduto.valor
    });
  };

  const handleAddProduto = () => {
    const produtoAdicionado = {
      id: novoProduto.produto.id,
      nome: novoProduto.produto.nome,
      quantidade: novoProduto.quantidade,
      valor: novoProduto.valor,
      descricao: novoProduto.descricao,
      valorTotal: novoProduto.valorTotal
    };
    setCarrinho([...carrinho, produtoAdicionado]);
    handleDialogClose();
  }
  const validaOportunidade = () => {
    let mensagem = '';
    if (qtdeVeiculo <= 0)
      mensagem = '[Quantidade de Veículos]';
    if (carrinho.length === 0)
      mensagem += ' [Nenhum Produto Adicionado]';
    if (!clienteCompra)
      mensagem += ' [Cliente Compra]';

    if (mensagem.length > 0) {
      setMensagem({
        titulo: 'Aviso',
        descricao: `Verifique se os campos estão preenchidos corretamente - ${mensagem}`
      });
      return false;
    }
    return true;
  }



  const handleComprar = async () => {
    setLoad(true);
    let usuario = authService.getUserInfo().usuario;
    if (validaOportunidade()) {
      let produtos = [];
      console.log(carrinho);
      let total = 0;

      carrinho.map((c) => {
        console.log(c)
        total += parseFloat(c.valor) * parseFloat(c.quantidade);

        produtos.push({
          ProdutoID: c.id,
          Nome: c.produto,
          Quantidade: parseInt(c.quantidade),
          Valor: c.valor,
          descricao: c.descricao
        })
      });

      let oportunidade = {
        Produtos: produtos,
        ClienteID: clienteCompra.id,
        Resumo: `Oportunidade - ${clienteCompra.nome}`,
        ClienteNome: clienteCompra.nome,
        EntidadeCliente: clienteCompra.cpfcnpj.length <= 14 ? 'contact' : 'account',
        TicketMedio: total,
        QtdVeiculos: qtdeVeiculo,
        parcelas: parcela,
        areaAtendimentoID: usuario.tz_area_atendimento_microsigaid,
        Origem: 100000003
      };

      console.log(oportunidade)
      let resultOportunidade = await oportunidadeService.addOportunidade(oportunidade);
      if (resultOportunidade)
        setMensagem({
          titulo: 'Sucesso',
          descricao: 'Oportunidade Gerada com Sucesso.'
        });
      else
        setMensagem({
          titulo: 'Aviso',
          descricao: 'Não foi possível gerar a oportunidade'
        });
    }

    setLoad(false);
  }


  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  return (

    <div>

      <MenuComponent />
			<div style={{ margin: 30 }}>
      <h3 className={classes.tituloStyle}>Vendas</h3>
				<h4 className={classes.subtituloStyle}>
					<span
						onClick={() => {
							const userInfo = authService.getUserInfo();
							if (userInfo.usuario.gestor) {
								window.location.assign('/gestor');
              } else if (userInfo.usuario.revenda) {
                window.location.assign('/revenda');
							} else {
								window.location.assign('/agenda');
							}
						}}
						className={classes.linkStyle}
						style={{ cursor: 'pointer' }}
					>
						Home
					</span> {'>'} Vendas
				</h4>


        <div style={{ marginBottom: 20 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={9}>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <BlueButton
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ height: 55 }}
                      onClick={handleDialogOpen}
                    >
                    <AddShoppingCartIcon />  Adicionar Produto 
                    </BlueButton>
                  </Grid>
                </Grid>
              </div>

      <Paper className={classes.paper}>
      <Grid container spacing={2}>
            {carrinho.length > 0 ? (
              carrinho.map((produto, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Paper elevation={3} style={{ padding: 20 }}>
                    <h4>{produto.nome}</h4>
                    <p>Descrição: {produto.descricao}</p>
                    <p>Valor: {MaskUtil.numberToReal(produto.valor)}</p>
                    <p>Quantidade: {produto.quantidade}</p>
                    <p>Total: {MaskUtil.numberToReal(produto.valorTotal)}</p>
                  </Paper>
                </Grid>
              ))
            ) : (

              <Grid container justifyContent="center" alignItems="center" style={{ height: '100px' }}>
                <Typography variant="subtitle1" color="textSecondary">
                  Nenhum registro para exibir
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
        <div >

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={9}>
          </Grid>
            <Grid item xs={12} sm={3}>
              {
                !load ?
                  <BlueButton 
                  disabled={carrinho.length === 0} 
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  style={{ height: 55 }}
                  onClick={handleComprar}
                  >
                    <AddIcon />
                    Comprar
                  </BlueButton> : <FacebookCircularProgress></FacebookCircularProgress>
              }

          </Grid>
        </Grid>
        </div>

        </div>
        <Dialog
         open={openDialog} 
         style={{ marginTop: 50, marginBottom: 10 }}
         onClose={handleDialogClose} 
         aria-labelledby="adicionar-produto-dialog"
         >
      <DialogTitle>
				<Grid container alignItems="center">
					<Grid item xs={10} sm={11}>
            Adicionar Produtos
					</Grid>
					<Grid item xs={2} sm={1}>
						<IconButton onClick={handleDialogClose}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            options={produtos}
            getOptionLabel={(option) => option.nome}
            onChange={handleProdutoChange}
            renderInput={(params) => <BlueTextField {...params} 
            label={<span>Produto </span>} variant="outlined" fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={<span>Descrição </span>}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            value={novoProduto.descricao}
            InputProps={{ readOnly: true }}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BlueTextField
            label={<span>Valor </span>}
            variant="outlined"
            fullWidth
            value={MaskUtil.numberToReal(novoProduto.valor)}
            InputProps={{ readOnly: true }}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BlueTextField
            label={<span>Quantidade </span>}
            type="number"
            variant="outlined"
            value={novoProduto.quantidade}
            onChange={handleQuantidadeChange}
            fullWidth
            margin="normal"
          />
          </Grid>
          <Grid item xs={12} sm={6}>
          <BlueTextField
            label={<span>Valor Total </span>}
            variant="outlined"
            value={MaskUtil.numberToReal(novoProduto.valorTotal)}
            InputProps={{ readOnly: true }}
            fullWidth
            margin="normal"
          />
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button 
          onClick={handleDialogClose} 
					variant="contained"
					size="large"
					fullWidth
					style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
          >
            Cancelar
          </Button>
          <BlueButton 
          onClick={handleAddProduto} 
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
            Adicionar
          </BlueButton>
        </DialogActions>
      </Dialog>
      {mensagem && (<div>
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{mensagem.titulo}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {mensagem.descricao}

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <BlueButton onClick={() => setMensagem(null)} variant="contained" color="primary">
              Ok
            </BlueButton>
          </DialogActions>
        </Dialog>
      </div>)}
    </div>
  );


}

