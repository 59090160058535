import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import notaFiscalService from '../../Services/NotaFiscal/nota-fiscal-service';
import usuarioService from '../../Services/Usuario/usuario-service';
import authService from '../../Services/auth/auth-service';
import '../../agenda/components/react-big-calendar.css';
import BlueButton from '../../components/button/BlueButton';
import CustomTablePagination from '../../components/pagination/pagination-component';

const useStyles = makeStyles(() => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloPaperStyle: {
    fontFamily: 'Mont, sans-serif',
    fontSize: '19px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    // color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }
}))

export default function ContestacaoNotaFiscal({ nf, handleClose }) {
  const [detalhesContestacao, setDetalheContestacao] = useState([]);
  const [openModalDetalhesContestacao, setOpenModalDetalhesContestacao] = useState(false);
  const [contestacaoSelecionada, setContestacaoSelecionada] = useState(null);
  const classes = useStyles();
  const mobile = useMediaQuery(useTheme().breakpoints.down(800));
  const [tableKey, setTableKey] = useState(0);
  const [, setUsuarioEstoqueAtivo] = useState(false);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [totalDados, setTotalDados] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [dadosBusca, setDadosBusca] = useState('');
  const [dadosFiltrados, setDadosFiltrados] = useState([]);
  const [procurarDadosConstetacao, setProcurarDadosConstetacao] = useState('');
  const [itensContestacaoFiltrados, setItensContestacaoFiltrados] = useState([]);
  const [paginasItensContestacao, setPaginasItensContestacao] = useState(0);
  const [linhasItensContestacao, setLinhasItensContestacao] = useState(8);

  const buscarUsuarioEstoqueAtivo = async () => {
    const response = await usuarioService.usuarioEstoqueAtivo(authService.getUserInfo().usuario.id);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  }

  const procurarContestacao = (term) => {
    if (term) {
      const filtro = detalhesContestacao?.filter((item) =>
        item.motivo?.toLowerCase().includes(dadosBusca.toLowerCase()) ||
        item.ticketNumero?.toLowerCase().includes(dadosBusca.toLowerCase()) ||
        item.observacoes?.toLowerCase().includes(dadosBusca.toLowerCase())
      );
      setDadosFiltrados(filtro);
    }
  };

  const procurarItemContestacao = (term) => {
    if (!term) {
      setItensContestacaoFiltrados(contestacaoSelecionada);
    } else {
      const filtro = contestacaoSelecionada.filter((item) =>
        item.produtoCodigo.toLowerCase().includes(term.toLowerCase()) ||
        item.produtoNome.toLowerCase().includes(term.toLowerCase())
      );
      setItensContestacaoFiltrados(filtro);
    }
  };

  useEffect(() => {
    getDetalheContestacaoNF(nf.id);
    buscarUsuarioEstoqueAtivo();
    setTableKey(tableKey + 1);
    if (!mobile) {
      setOpenModalDetalhesContestacao(false);
    }
  }, [nf.id, mobile]);

  useEffect(() => {
    const filtrarItens = () => {
      if (dadosBusca === '') {
        setDadosFiltrados(detalhesContestacao);
      } else {
        setDadosFiltrados(
          detalhesContestacao?.filter(item =>
            item.motivo?.toLowerCase().includes(dadosBusca.toLowerCase()) ||
            item.ticketNumero?.toLowerCase().includes(dadosBusca.toLowerCase()) ||
            item.observacoes?.toLowerCase().includes(dadosBusca.toLowerCase())
          )
        );
      }
      setPage(0);
    };

    setDadosFiltrados(detalhesContestacao);
    filtrarItens();
  }, [dadosBusca, detalhesContestacao]);

  useEffect(() => {
    const filtrarItensContestacao = () => {
      if (procurarDadosConstetacao === '') {
        setItensContestacaoFiltrados(contestacaoSelecionada);
      } else {
        setItensContestacaoFiltrados(
          contestacaoSelecionada?.filter(item =>
            item.produtoCodigo?.toLowerCase().includes(procurarDadosConstetacao.toLowerCase()) ||
            item.produtoNome?.toLowerCase().includes(procurarDadosConstetacao.toLowerCase())
          )
        );
      }
      setPaginasItensContestacao(0);
    };

    setItensContestacaoFiltrados(contestacaoSelecionada);
    filtrarItensContestacao();
  }, [procurarDadosConstetacao, contestacaoSelecionada]);

  const getDetalheContestacaoNF = async (idNF) => {
    setCarregandoTable(true);
    var responseDetalheContestacao = await notaFiscalService.dadosContestacaoNF(idNF)
    if (responseDetalheContestacao) {
      setDetalheContestacao(responseDetalheContestacao);
      setTotalDados(responseDetalheContestacao.length);
    }
    setCarregandoTable(false);
  }

  const mudarPagina = (event, novaPagina) => {
    setPage(novaPagina);
  };

  const mudarTamanhoPagina = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const alterarPaginaItensContestacao = (event, newPage) => {
    setPaginasItensContestacao(newPage);
  };

  const alterarLinhaItensContestacao = (event) => {
    setLinhasItensContestacao(parseInt(event.target.value, 10));
    setPaginasItensContestacao(0);
  };

  const abrirModalDetalhesContestacao = (rowData) => {
    setOpenModalDetalhesContestacao(true)
    setContestacaoSelecionada(rowData.notaFiscalItems ? rowData.notaFiscalItems : [])
  }

  const fecharModalDetalhesContestacao = () => {
    setOpenModalDetalhesContestacao(false)
  }

  const itensPaginados = dadosFiltrados.slice(page * pageSize, page * pageSize + pageSize);

  const itensContestacaoPaginados = Array.isArray(itensContestacaoFiltrados) && itensContestacaoFiltrados.length > 0
    ? itensContestacaoFiltrados.slice(
      paginasItensContestacao * linhasItensContestacao,
      paginasItensContestacao * linhasItensContestacao + linhasItensContestacao
    )
    : [];


  return (
    <>
      <div style={{ margin: 30 }}>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openModalDetalhesContestacao}
          style={{ marginTop: 45, marginBottom: 10 }}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              fecharModalDetalhesContestacao();
            }
          }}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Grid container alignItems="center">
              <Grid item xs={10} sm={11}>
                Detalhes da Contestação
              </Grid>
              <Grid item xs={2} sm={1}>
                <IconButton onClick={fecharModalDetalhesContestacao}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container justifyContent="flex-end" style={{ marginBottom: 10, marginTop: 10 }}>
              <Grid item xs={mobile ? 12 : 5}>
                <TextField
                  label="Pesquisar"
                  variant="outlined"
                  fullWidth
                  value={procurarDadosConstetacao}
                  onChange={(e) => {
                    const term = e.target.value;
                    setProcurarDadosConstetacao(term);
                    procurarItemContestacao(term);
                  }}
                  style={{ marginBottom: '16px' }}
                  InputProps={{
                    endAdornment: (
                      <>
                        {procurarDadosConstetacao ? (
                          <IconButton
                            onClick={() => {
                              setProcurarDadosConstetacao('');
                              procurarItemContestacao('');
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        ) : (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {carregandoTable ? (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            ) : mobile ? (
              <Grid container spacing={1}>
                {itensContestacaoPaginados.length > 0 ? (
                  itensContestacaoPaginados.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <Paper
                        elevation={1}
                        style={{
                          padding: '8px',
                          margin: '1px 0',
                          borderRadius: '8px',
                        }}
                      >
                        <Typography component="div" variant="body2">
                          <strong>Produto:</strong> {item.produtoNome || '-'}
                        </Typography>
                        <Typography component="div" variant="body2">
                          <strong>Código do Produto:</strong> {item.produtoCodigo || '-'}
                        </Typography>
                        <Typography component="div" variant="body2">
                          <strong>Quantidade:</strong> {item.quantidade || '-'}
                        </Typography>
                        <Typography component="div" variant="body2">
                          <strong>Séries:</strong>{' '}
                          {item.notaFiscalItemSeries ? (
                            <div>
                              {item.notaFiscalItemSeries.map((serie, index) => (
                                <span style={{ padding: '10px' }} key={index}>
                                  {serie.serie}
                                  {index !== item.notaFiscalItemSeries.length - 1 && <br />}
                                </span>
                              ))}
                            </div>
                          ) : (
                            ' -'
                          )}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="body1" align="center" style={{ padding: '16px' }}>
                      Nenhum registro para exibir
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography style={{ fontWeight: 'bold' }} wrap="true">Produto</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{ fontWeight: 'bold' }}>Código do Produto</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{ fontWeight: 'bold' }}>Quantidade</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{ fontWeight: 'bold' }} wrap="true">Séries</Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: '8px 0' }} />
                <Box style={{ flex: 1, overflowX: 'auto' }}>
                  {itensContestacaoPaginados.length > 0 ? (
                    itensContestacaoPaginados.map((item, index) => (
                      <Box key={index} style={{ marginBottom: '8px' }}>
                        <Grid container key={index} style={{ padding: '8px 0' }} wrap="nowrap">
                          <Grid item xs={4}>
                            <Typography component="div" variant="body2">{item.produtoNome || '-'}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography component="div" variant="body2" noWrap>{item.produtoCodigo || '-'}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography component="div" variant="body2" noWrap>{item.quantidade || '-'}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography component="div" variant="body2" wrap="true">
                              {item.notaFiscalItemSeries ? (
                                <div>
                                  {item.notaFiscalItemSeries.map((serie, index) => (
                                    <span style={{ padding: '10px' }} key={index}>
                                      {serie.serie}
                                      {index !== item.notaFiscalItemSeries.length - 1 && <br />}
                                    </span>
                                  ))}
                                </div>
                              ) : (
                                ' -'
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    ))
                  ) : (
                    <Typography component="div" variant="body2" align="center" style={{ padding: '16px' }}>
                      Nenhum registro para exibir
                    </Typography>
                  )}
                </Box>
              </>
            )}

            <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
              <Grid item>
                {itensContestacaoFiltrados?.length > 0 && (
                  <CustomTablePagination
                    count={contestacaoSelecionada ? contestacaoSelecionada.length : 0}
                    page={paginasItensContestacao}
                    rowsPerPage={linhasItensContestacao}
                    onPageChange={alterarPaginaItensContestacao}
                    quantidadeItens={contestacaoSelecionada ? contestacaoSelecionada.length : 0}
                    onRowsPerPageChange={alterarLinhaItensContestacao}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              variant="contained"
              style={{ float: 'right', width: '40%', height: 55 }}
              onClick={fecharModalDetalhesContestacao}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        <Grid className={classes.actions} container>
          <Grid item xs={3} sm={1} md={1}>
            <Button
              onClick={handleClose}
              style={{ color: '#209BDE' }}
              startIcon={<ArrowBackIosIcon />}
            >
              Voltar
            </Button>
          </Grid>
        </Grid>

        <Paper className={classes.paper}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.tituloPaperStyle}>
              <strong>Contestação da Nota Fiscal - {nf.numero}</strong>
            </Grid>
            <Grid item xs={12} className={classes.subtituloStyle}>
              <strong>Número NF:</strong> {nf.numero}
            </Grid>
            <Grid item xs={12} className={classes.subtituloStyle}>
              <strong>Série:</strong> {nf.serie}
            </Grid>
            <Grid item xs={12} className={classes.subtituloStyle}>
              <strong>Status:</strong> {nf.Descricao}
            </Grid>
            <Grid item xs={12} className={classes.subtituloStyle}>
              <strong>Data Emissão:</strong> {nf.dataEmissao}
            </Grid>
            <Grid item xs={12} className={classes.subtituloStyle}>
              <strong>Previsão de entrega:</strong> {nf.dataPrevisaoEntrega?.toLocaleString('pt-BR')}
            </Grid>
            <Grid item xs={12} className={classes.subtituloStyle}>
              <strong>Data entrega:</strong> {nf.dataEntrega?.toLocaleString('pt-BR')}
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.paper}>
          <Grid container spacing={1} style={{ justifyContent: 'space-between' }}>
            <Grid item>
              <strong className={classes.tituloPaperStyle}>
                Constestações
              </strong>
            </Grid>

            <Grid item xs={12} sm={3} style={{ margin: 5, marginBottom: mobile ? 20 : 0 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Pesquisar item"
                value={dadosBusca}
                onChange={(e) => {
                  const term = e.target.value;
                  setDadosBusca(term);
                  procurarContestacao(term);
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {dadosBusca ? (
                        <IconButton
                          onClick={() => {
                            setDadosBusca('');
                            procurarContestacao('');
                          }}
                          size="small"
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {carregandoTable ? (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
              <CircularProgress />
            </Grid>
          ) : detalhesContestacao.length === 0 ? (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100px' }}>
              <Typography variant="subtitle1" color="textSecondary">
                Não há detalhes na constestação.
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid container spacing={1} alignItems="stretch" style={{ marginTop: 15 }}>
                {itensPaginados.map((item, index) => (
                  <Grid item key={index} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                    <Paper
                      style={{
                        padding: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%',
                        boxSizing: 'border-box',
                      }}
                    >
                      <Box padding={1} style={{ flexGrow: 1, marginBottom: 5 }}>
                        <Typography component="div" variant="body2"><strong>Nº do Ticket: </strong> {item.ticketNumero} </Typography>
                        <Typography component="div" variant="body2"><strong>Motivo: </strong> {item.motivo == 'ItensNaoChegaram' ? 'Itens não chegaram' : 'Itens chegaram com defeito'}</Typography>
                        <Typography component="div" variant="body2"><strong>Observações: </strong> {item.observacoes} </Typography>
                      </Box>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                          <BlueButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            style={{ marginBottom: '1px', height: 55 }}
                            onClick={() => abrirModalDetalhesContestacao(item)}
                            startIcon={<AssignmentIcon />}
                          >
                            <span style={{ fontSize: '0.7rem' }}>Detalhes da Contestação</span>
                          </BlueButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>

              <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 15 }}>
                <Grid item>
                  <CustomTablePagination
                    count={totalDados}
                    page={page}
                    rowsPerPage={pageSize}
                    onPageChange={mudarPagina}
                    quantidadeItens={totalDados}
                    onRowsPerPageChange={mudarTamanhoPagina}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </div>
    </>
  )
}