// src/components/CustomSelect.js
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

// Estilos personalizados para o react-select
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%', // Ocupa toda a largura do contêiner pai
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: '55px',
    height: '55px',
    borderColor: state.isFocused ? '#80bdff' : '#ced4da',
    boxShadow: state.isFocused ? '0 0 0 1px #80bdff' : 'none',
    '&:hover': {
      borderColor: '#80bdff',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '55px',
    padding: '0 6px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
    height: '100%',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '55px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const CustomSelect = ({ options, value, onChange, placeholder, isClearable }) => {
  return (
    <Select
      styles={customStyles}
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isClearable={isClearable}
      menuPlacement="auto" // Pode ser "auto" ou "top"
      menuPortalTarget={document.body} // Garante que o menu dropdown não afete o scroll do Dialog
    />
  );
};

// Validação das propriedades
CustomSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  label: PropTypes.string,
};

CustomSelect.defaultProps = {
  value: null,
  placeholder: '',
  isClearable: false,
  label: '',
};

export default CustomSelect;
