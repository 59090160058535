import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	useMediaQuery,
	useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import BlueButton from '../../components/button/BlueButton';
import authService from '../../Services/auth/auth-service';
import ordemServicoServico from '../../Services/OrdemServico/ordem-servico-servico';
import perfilTecnicoService from '../../Services/perfilTecnico/perfil-tecnico-services';

import Select from 'react-select';
import CustomAlert from '../../components/alert/CustomAlert';

export function AgendamentoDialog({ handleClose, atividadeServico, handleOrdemServico, handleDetalhesOrdemServico,osNumero  }) {
	const areaAtendimentoID = authService?.getUserInfo()?.usuario?.tz_area_atendimento_microsigaid;
	const [os, setOS] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [carregandoDetalhesOS, setCarregandoDetalhesOS] = useState(false);

	const [abrirAlert, setAbrirAlert] = React.useState(false);
	const [tipoAlert, setTipoAlert] = React.useState('error');
	const [mensagemAlert, setMensagemAlert] = React.useState('');
	const [loading, setLoading] = useState(false);
	const [, setSelectDisabled] = useState(false);
	const [tecnicoSelecionado, setTecnicoSelecionado] = useState('');
	const [tecnicosAprovados, setTecnicosAprovados] = useState([]);

	const customStyles = {
		control: (provided) => ({
			...provided,
			minHeight: 55,
			height: 55,
		}),
		valueContainer: (provided) => ({
			...provided,
			height: 55,
			display: 'flex',
			alignItems: 'center',
		}),
		input: (provided) => ({
			...provided,
			margin: 0,
		}),
	};
	useEffect(() => {
		const userInfo = JSON.parse(localStorage?.getItem('userInfo'))?.usuario;
		setUsuarioInfo(userInfo);
	}, []);

	const utilMask = {
		phone: function (value) {
			if (!value) return '';
			value = value.replace(/\D/g, '');
			if (value.length === 11) {
				return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
			} else if (value.length === 10) {
				return value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
			} else {
				return value;
			}
		},

		cep: function (value) {
			if (!value) return '';
			value = value.replace(/\D/g, '');
			if (value.length === 8) {
				return value.replace(/(\d{5})(\d{3})/, '$1-$2');
			} else {
				return value;
			}
		}
	};
	const [usuarioInfo, setUsuarioInfo] = useState(null);



	function formatarDataHora(dataHoraString) {
		var dataHora = new Date(dataHoraString);

		// Subtrai 3 horas da data
		dataHora.setHours(dataHora.getHours() - 3);

		function zeroAEsquerda(numero) {
			return numero < 10 ? '0' + numero : numero;
		}

		var dia = zeroAEsquerda(dataHora.getDate());
		var mes = zeroAEsquerda(dataHora.getMonth() + 1);
		var ano = dataHora.getFullYear();
		var hora = zeroAEsquerda(dataHora.getHours());
		var minutos = zeroAEsquerda(dataHora.getMinutes());
		var segundos = zeroAEsquerda(dataHora.getSeconds());

		var dataHoraFormatada = dia + '/' + mes + '/' + ano + ' ' + hora + ':' + minutos + ':' + segundos;

		return dataHoraFormatada;
	}

	const consultaDetalheOS = async () => {
		setCarregandoDetalhesOS(true);
		let response = await ordemServicoServico.detalhesOS(atividadeServico?.na, areaAtendimentoID);

		if (response) {
			let annotations = response[0].annotations;

			let _os = {
				osID: response[0].tz_Ordem_ServicoId || '',
				os: response[0].os || '',
				activityId: response[0].activityId || '',
				cliente: response[0].cliente || '',
				clienteCpfCnpj: response[0].clienteCpfCnpj || '',
				clienteEmail: response[0].clienteEmail?.toLowerCase() || '',
				solicitante: response[0].tz_nome_solicitante || '',
				telefone_solicitante: utilMask.phone(response[0].tz_telefone_solicitante || ''),
				endereco: response[0].tz_Endereco_Rua || '',
				numero: response[0].tz_Endereco_Numero || '',
				cep: utilMask.cep(response[0].tz_endereco_cepIdName || ''),
				cidade: response[0].tz_endereco_cidadeIdName || '',
				estado: response[0].tz_endereco_EstadoIdName || '',
				tecnico: response[0].tz_nome_tecnico || '',
				placa: response[0].placa || '',
				referencia: response[0].tz_referencia || '',
				razaoStatus: response[0].razaoStatus || '',
				inicio: response[0].data_inicio,
				fim: response[0].data_fim,
				na: response[0].na || '',
				cor: response[0].cor || '',
				plataforma: response[0].tz_PlataformaIdName || '',
				rastreador: response[0].tz_RastreadorIdName || '',
				plano: response[0].tz_PlanoIdName || '',
				num_serie_contrato: response[0].tz_Numero_Serie_Contrato || '',
				num_serie_antena_contrato: response[0].tz_Numero_Serie_Antena_Contrato || '',
				fim_garantia: response[0].tz_Data_Fim_Garantia,
				observacao: response[0].tz_Observacoes || '',
				status: response[0].descStatus || '',
				obsCliente: response[0].detalheCliente || '',
				bairro: response[0].tz_endereco_bairro || '',
				modificacao: response[0].modifiedOn || '',
				servico: response[0].serviceIdName || '',
				antena: response[0].tz_Numero_Serie_Antena_Contrato || '',
				clienteID: response[0].clienteID || '',
				id_area_atendimento:
					response[0].tz_area_atendimento_microsigaId || areaAtendimentoID,
				anotacao: annotations && annotations.length > 0 ? annotations[0] : {},
				cenarioVenda: response[0].tz_Cenario_Venda || '',
				marca: response[0].marca || '',
				marcaID: response[0].marcaid || '',
				modelo: response[0].modelo || '',
				modeloID: response[0].modeloid || '',
				tipoServico: response[0].tipoServico || '',
				localAtendimento: response[0].localAtendimento || '',
				tipoServicoAgendamento: response[0].tipoServicoAgendamento || ''
			};
			setOS(_os);
			setCarregandoDetalhesOS(false);
		}
		setCarregandoDetalhesOS(false);
	};

	useEffect(() => {
		const fetchDetalhesOS = async () => {
			await consultaDetalheOS();
			try {
				if (tecnicosAprovados.length > 0) {
					const tecnicoNome = atividadeServico?.nomeTecnico; 
					if (tecnicoNome) {
						const tecnico = tecnicosAprovados.find(
							(t) => t.label === tecnicoNome 
						);
						if (tecnico) {
							setTecnicoSelecionado(tecnico); 
						} else {
							setTecnicoSelecionado(null); 
						}
					}
				}
			} catch (error) {
				// Catch
			}
		};
	
		fetchDetalhesOS();
	}, [osNumero, tecnicosAprovados, atividadeServico]);
	
	
	


	
	const [, setTecnicoDetalhes] = useState({
		idTecnico: '',
		loginTecnico: '',
		nomeTecnico: '',
	});

	useEffect(() => {
		const buscarTecnicoDetalhes = async () => {
			if (tecnicoSelecionado && usuarioInfo) {
				try {
					const response = await perfilTecnicoService.buscarTecnicoEmpresa(usuarioInfo.id);
					if (response && !response.error) {
						const tecnico = response.find(
							(item) => item.idTecnico === tecnicoSelecionado
						);
						if (tecnico) {
							setTecnicoDetalhes({
								idTecnico: tecnico.idTecnico,
								loginTecnico: tecnico.loginTecnico,
								nomeTecnico: tecnico.nomeTecnico,
							});
						} else {
							setTecnicoDetalhes(null);
						}
					}
				} catch (error) {
					// Removido
				}
			}
		};
	
		buscarTecnicoDetalhes();
	}, [tecnicoSelecionado, usuarioInfo]);
	
	
	useEffect(() => {
		const fetchTecnicos = async () => {
			try {
				if (usuarioInfo && usuarioInfo.id && !tecnicosAprovados.length) {
					const response = await perfilTecnicoService.buscarTecnicoEmpresa(usuarioInfo.id);
					if (response && !response.error) {
						setTecnicosAprovados(response.map(tecnico => ({
							value: tecnico.idTecnico,
							label: tecnico.nomeTecnico,
						})));
					} else {
						console.warn('Erro ao buscar técnicos:', response.message);
					}
				}
			} catch (error) {
				console.error('Erro ao buscar técnicos:', error);
			}
		};

		if (usuarioInfo) {
			fetchTecnicos();
		}
	}, [usuarioInfo, tecnicosAprovados]);
	

	const handleChange = async (selectedOption) => {
		setTecnicoSelecionado(selectedOption);
		setLoading(true);

		try {
			const response = await perfilTecnicoService.cadastrarTecnicoOS({
				osNumero: os?.os,
				tecnicoId: selectedOption.value,
			});

			if (!response.error) {
				setSelectDisabled(true);
			} else {
				setSelectDisabled(true);
				setMensagemAlert({ title: 'Atenção!', body: 'Não foi possível obter número da Ordem de Serviço! Entre em contato com suporte!' });
				setTipoAlert('fail');
				setAbrirAlert(true);
			}
		} catch (error) {
			console.error('Erro ao cadastrar técnico:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog
			aria-labelledby="customized-dialog-title"
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					handleClose
				}
			}}
			open={true}
			style={{ marginTop: 35, marginBottom: 10 }}
		>
			<DialogTitle>
				<Grid container alignItems="center">
					<Grid item xs={10} sm={11}>
						Agendamento {atividadeServico?.na}
					</Grid>
					<Grid item xs={2} sm={1}>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent dividers>
				{carregandoDetalhesOS ? (
                    <Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{
						height: isMobile ? '100px' : '300px',
						width: isMobile ? '100%' : '500px',
					}}
				>
						<CircularProgress />
					</Grid>
				) : (
					<>
					<Grid container spacing={1}>

					{tecnicosAprovados.length > 0 ? (
						<>
						<div style={{ marginBottom: '16px', width: '100%' }}>
							<Grid item xs={12}>
							<h3>Técnico responsável</h3>
							</Grid>

								{loading ? (
									<Grid container justifyContent="center" alignItems="center">
										<CircularProgress />
									</Grid>
									) : (
										<Select
										options={tecnicosAprovados}
										value={tecnicoSelecionado} // Técnico previamente selecionado
										onChange={handleChange}
										placeholder="Selecione o Técnico"
										isSearchable
										styles={customStyles} // Estilo personalizado
									/>
								)}
							</div>
							</>
							) : (
								<div></div>
						)}
						<Grid item xs={12}>
						<h3>Informações sobre a NA</h3>
						</Grid>

							<Grid item xs={6}>
								<strong>NA:</strong> {atividadeServico?.na}
							</Grid>
							<Grid item xs={6} sm={6}>
								<strong>Status:</strong> {atividadeServico?.descStatus}
							</Grid>
							<Grid item xs={12}>
								<strong>Cliente:</strong> {atividadeServico?.nomeCliente}
							</Grid>
							<Grid item xs={12}>
								<strong>Solicitante:</strong> {atividadeServico?.solicitanteNome}
							</Grid>
							<Grid item xs={12}>
								<strong>Contato solicitante:</strong> {atividadeServico?.solicitanteTelefone}
							</Grid>
							<Grid item xs={12} sm={6}>
								<strong>Serviço:</strong> {atividadeServico?.servico}
							</Grid>

							<Grid item xs={12}>
								<strong>Assunto:</strong> {atividadeServico?.assunto}
							</Grid>
							<Grid item xs={6} sm={6}>
								<strong>Início:</strong>{' '}
								{atividadeServico?.inicio ? formatarDataHora(atividadeServico?.inicio) : ''}
							</Grid>
							<Grid item xs={6} sm={6}>
								<strong>Término:</strong>{' '}
								{atividadeServico?.termino ? formatarDataHora(atividadeServico?.termino) : 'Não finalizado'}
							</Grid>
							<Grid item xs={12}>
								<strong>Previsão de Início:</strong>{' '}
								{atividadeServico?.previsaoInicio ? formatarDataHora(atividadeServico?.previsaoInicio) : ''}
							</Grid>
							<Grid item xs={12}>
								<strong>Previsão de Término:</strong>{' '}
								{atividadeServico?.previsaoTermino ? formatarDataHora(atividadeServico?.previsaoTermino) : ''}
							</Grid>
						</Grid>
						{os?.localAtendimento !== 'Ponto fixo' && (
							<>
								<Divider style={{ marginTop: 20, marginBottom: 10 }} />
								<h3>Informações de endereço</h3>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<strong>Logradouro:</strong> {atividadeServico?.enderecoRua}
									</Grid>
									<Grid item xs={6} sm={6}>
										<strong>Número:</strong> {atividadeServico?.endereco_Numero}
									</Grid>
									<Grid item xs={6} sm={6}>
										<strong>CEP:</strong> {atividadeServico?.enderecoCepIdName}
									</Grid>
									<Grid item xs={6} sm={6}>
										<strong>Bairro:</strong> {atividadeServico?.enderecoBairro}
									</Grid>
									<Grid item xs={6} sm={6}>
										<strong>Cidade:</strong> {atividadeServico?.enderecoCidadeIdName}
									</Grid>
									<Grid item xs={6} sm={6}>
										<strong>Estado:</strong> {atividadeServico?.enderecoEstadoIdName}
									</Grid>
									<Grid item xs={12} sm={6}>
										<strong>Complemento:</strong> {atividadeServico?.enderecoComplemento}
									</Grid>
								</Grid>
							</>
						)}
					</>
				)}
			</DialogContent>

			<DialogActions style={{ justifyContent: 'space-around' }}>
				<Button
					onClick={handleClose}
					variant="contained"
					size="large"
					fullWidth
					style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
					Voltar
				</Button>
				{atividadeServico.inicio ? (
					<BlueButton
						onClick={() => handleDetalhesOrdemServico(atividadeServico)}
						variant="contained"
						color="primary"
						size="large"
						fullWidth
						style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
						Detalhes
					</BlueButton>
				) : (
					<BlueButton
						onClick={() => handleOrdemServico(atividadeServico)}
						variant="contained"
						color="primary"
						size="large"
						fullWidth
						disabled={atividadeServico.descStatus == 'Concluido'}
						style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
					>
						Iniciar
					</BlueButton>
				)}
			</DialogActions>
			<CustomAlert
				open={abrirAlert}
				message={mensagemAlert}
				type={tipoAlert}
				onClose={() => setAbrirAlert(false)}
			/>
		</Dialog>
	);
}
