import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const BlueTextField = withStyles({
  root: {
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#209BDE',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#209BDE',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#209BDE',
    },
  },
})(props => <TextField {...props} />);

export default BlueTextField;
