import { Grid, Paper, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BlueButton from '../components/button/BlueButton';
import MenuComponent from '../components/menu/menu-component';
import FacebookCircularProgress from '../components/progress/CircularProgressBlue';
import authService from '../Services/auth/auth-service';
import clienteService from '../Services/cliente/cliente-service';
import CadastratoClientePage from './cadastro-cliente-page';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginBottom: 20,
		display: 'flex',
		flexDirection: 'column',
		padding: '15px 20px 15px',
		borderRadius: 10,
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '20px 10px'
		}
	},
	progress: {
		color: '#209BDE',
	},
	tituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '22px',
		textAlign: 'start',
		padding: '0 0px',
		marginBottom: '5px'
	},
	subtituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#7F7F7F',
		fontSize: '16px',
		fontWeight: 'inherit',
		textAlign: 'start',
		padding: '0 0px',
		marginTop: '0px'
	}
}));

export default function ClienteCadastroPage() {
	const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [_clientes, setCliente] = useState([]);
  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    let usuario = authService.getUserInfo().usuario;
    console.log(usuario);
  };

  useEffect(() => {
    const userInfo = authService.getUserInfo();
    if (userInfo && userInfo.usuario) {
      clientes(userInfo.usuario.tz_area_atendimento_microsigaid);
    }
  }, []);
  
  const clientes = async (areaAtendimentoID) => {
    setLoad(true);
    try {
      let clientesResult = await clienteService.ClientesByRevenda(areaAtendimentoID);
      if (clientesResult) {
        const clientes = clientesResult.map((x) => ({
          id: x.ClienteID || '',
          nome: x.Nome || '',
          cpfcnpj: x.CPFCNPJ || '',
          celular: x.Celular || '',
          telefone: x.Telefone || '',
        }));
        setCliente(clientes);
      }
    } catch (error) {
      console.error(error);
    }
    setLoad(false);
  };



	return (
		<div>
			<MenuComponent />
			<div style={{ margin: 30 }}>
				<h3 className={classes.tituloStyle}>Cadastrar Cliente</h3>
				<h4 className={classes.subtituloStyle}>
					<span
						onClick={() => window.location.assign('/revenda')}
						className={classes.linkStyle}
						style={{ cursor: 'pointer' }}
					>
						Home
					</span> {'>'} Cadastrar Cliente
				</h4>
        <div style={{ marginBottom: 20 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
            </Grid>

            <Grid item xs={12} sm={4}>
              <BlueButton
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={handleClick}
                style={{ height: 55 }}
              >
                <span style={{ fontSize: '0.7rem' }}>Cadastrar Cliente</span>
              </BlueButton>
              <CadastratoClientePage isOpen={open} handleClose={handleClose} />
            </Grid>
          </Grid>
        </div>

				<Paper className={classes.paper}>
        {load ? (
              <div style={{ marginLeft: 500, marginTop: 180 }}>
                <FacebookCircularProgress />
              </div>
            ) : (
              <Grid container spacing={2}>
                <Grid container item className={classes.gridHeader}>
                  <Grid item xs={3}>Nome</Grid>
                  <Grid item xs={3}>CPF/CNPJ</Grid>
                  <Grid item xs={3}>Telefone</Grid>
                  <Grid item xs={3}>Celular</Grid>
                </Grid>

                {_clientes.length === 0 ? (
                  <Grid item xs={12} style={{ textAlign: 'center', padding: '20px' }}>
                    Nenhum registro para exibir
                  </Grid>
                ) : (
                  _clientes.map((cliente, index) => (
                    <Grid container item key={cliente.id || index} className={classes.gridRow}>
                      <Grid item xs={3}>{cliente.nome}</Grid>
                      <Grid item xs={3}>{cliente.cpfcnpj}</Grid>
                      <Grid item xs={3}>{cliente.telefone}</Grid>
                      <Grid item xs={3}>{cliente.celular}</Grid>
                    </Grid>
                  ))
                )}
              </Grid>
            )}
				</Paper>
			</div>
		</div>
	);
}
