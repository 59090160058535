import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Paper, TextField, Typography,
  useMediaQuery
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import agendaService from '../Services/Agenda/agenda-service';
import ordemServicoServico from '../Services/OrdemServico/ordem-servico-servico';
import authService from '../Services/auth/auth-service';
import perfilTecnicoService from '../Services/perfilTecnico/perfil-tecnico-services';
import CustomAlert from '../components/alert/CustomAlert';
import BlueButton from '../components/button/BlueButton';
import MenuComponent from '../components/menu/menu-component';
import CustomTablePagination from '../components/pagination/pagination-component.js';
import { OSVistoriaPageTecnico } from '../ordemServico/os-vistoria-page-tecnico';
import FilesExportFilter from '../servicos/components/filesExportFilter.js';
import FilesExportFilterOrcamento from '../servicos/components/filesExportFilterOrcamentos.js';
import './components/ComponentStyles.css';

// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import SearchIcon from '@material-ui/icons/Search';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DownloadIcon from '@mui/icons-material/Download';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

const useStyles = makeStyles(() => ({
  exportExcel: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  paperServico: {
    padding: '10px',
    margin: '10px 0',
    width: '100%',
  },
  textoServicoProduto: {
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'center',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '18px',
    textAlign: 'start',
    padding: '0 0px',
  },
}));

export default function ServicosPage({ handleCloseOrdemServico, microSigaID, nomeTecnico }) {
  const classes = useStyles();

  const [os, setOS] = useState(null);
  const [itensOS, setItensOS] = useState([]);
  const [openDialogVistoria, setOpenDialogVistoria] = useState(false);
  const [_servicos, setServicos] = useState([]);
  const [dataExportacao, setDataExportacao] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const mobile = useMediaQuery(useTheme().breakpoints.down(800))
  const [page, setPage] = useState(0);
  let [pageSize, setPageSize] = useState(8);
  const [quantidadeNA, setQuantidadeNA] = useState(0);
  const [totalDados, setTotalDados] = useState(0);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [tableKey, setTableKey] = useState(0)
  const [pesquisa, setPesquisa] = useState('');
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [nomeTecnicoFiltro, setNomeTecnicoFiltro] = useState('');
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [abrirModalSelecionarMesAnoExportacao, setAbrirModalSelecionarMesAnoExportacao] = useState(false);
  const [efeitoCarregarModalExportacao, setEfeitoCarregarModalExportacao] = useState(false);
  const [mes, setMes] = useState('');
  const [ano, setAno] = useState('');
  const [arquivo, setArquivo] = useState('');
  const [naSelecionada, setNaSelecionada] = useState('');
  const [exportar, setExportar] = useState(false);
  const classificacaoPrestador = authService.getUserInfo().usuario.classificacaoPrestador;
  const [, setShowOrcamento] = useState({});
  const [status, ] = useState('');
  const mapCodigoToMotivo = (motivo) => motivoCodigoMap[motivo] || null;
  const [, setIsSearchMode] = useState(true);
  const [loadingVistoria, setLoadingVistoria] = useState(null);
  const [dadosOrcamentoExportacao, setDadosOrcamentoExportacao] = useState([]);
  const [carregandoBotaoLimpar, setCarregandoBotaoLimpar] = useState(false);
  const [carregandoBotaoPesquisar, setCarregandoBotaoPesquisar] = useState(false);
  const [carregandoTableOrcamento, setCarregandoTableOrcamento] = useState(false);
  const [openModalOrcamento, setOpenModalOrcamento] = useState(false);
  const [pageOrcamento, setPageOrcamento] = useState(0);
  let [pageSizeOrcamento, setPageSizeOrcamento] = useState(8);
  const [, setLoadingData] = useState(true);
  const [orcamentoSelecionado, setOrcamentoSelecionado] = useState('');
	const [, setLoading] = useState(false);
  const [orcamentoFiltrado, setOrcamentoFiltrado] = useState(orcamentoSelecionado ? orcamentoSelecionado : []);
  const [pesquisaOrcamento, setPesquisaOrcamento] = useState('');
  const [exportarOrcamento, setExportarOrcamento] = useState(false);
	const [tecnicosAprovados, setTecnicosAprovados] = useState([]);
	const [usuarioInfo, setUsuarioInfo] = useState(null);
  const [selectedServico, setSelectedServico] = useState(null); 
  const [statusFiltro, setStatusFiltro] = useState('');

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const toggleSelect = (na) => {
    setSelectedServico(selectedServico === na ? null : na); 
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: '55px',
      borderRadius: '5px',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1050,
    }),
  };
  const tecnicoOptions = tecnicosAprovados.map((tecnico) => ({
    value: tecnico.idTecnico,
    label: tecnico.nomeTecnico,
  }));

  const handleTecnicoChange = (selectedOption, osNumero) => {
    const tecnicoId = selectedOption ? selectedOption.value : null;
    
    handleChange(osNumero, tecnicoId);
  };
  const mesOptions = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: new Date(0, i).toLocaleString('pt-BR', { month: 'long' }),
  }));
  
  const anoOptions = Array.from({ length: 10 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return { value: year, label: year.toString() };
  });
  
  const arquivoOptions = [
    { value: 'csv', label: 'Exportar em CSV' },
    { value: 'pdf', label: 'Exportar em PDF' },
    { value: 'excel', label: 'Exportar em EXCEL' },
  ];

  useEffect(() => {
		const userInfo = JSON.parse(localStorage?.getItem('userInfo'))?.usuario;
		setUsuarioInfo(userInfo);

	}, []);
  const motivoCodigoMap = {
    'Apagado': '419400000',
    'Display queimado': '419400001',
    'Teclas falhando': '419400002',
    'Não recebe comandos': '419400003',
    'Mau uso': '419400004',
    'Queimado': '419400005',
    'Infiltração d agua': '419400006',
    'Não comunica': '419400007',
    'Não aciona': '419400008',
    'Não bloqueia': '419400009',
    'Quebrado - Mau uso': '419400010',
    'Senha supervisor': '419400011',
  };

  const abrirModalOrcamento = async (servico) => {
    if (!servico || !servico.NA) return;
  
    const naSelecionadaAtual = servico.NA;
    setNaSelecionada(naSelecionadaAtual);
  
    if (orcamentoSelecionado.length > 0 && orcamentoSelecionado[0]?.na === naSelecionadaAtual) {
      setOpenModalOrcamento(true);
      return;
    }
  
    try {
      setCarregandoTableOrcamento(true);
      const areaAtendimentoID = microSigaID || authService.getUserInfo()?.usuario.tz_area_atendimento_microsigaid;
      const osOrcamento = await ordemServicoServico.listaOrcamentoOS(naSelecionadaAtual, areaAtendimentoID);
  
      const arrayOrcamento = osOrcamento.map((o) => ({
        quantidade: o.quantidade || 'Não informado',
        servico: o.produtoNome || 'Produto não especificado',
        status: o.descStatus_aprovacao || 'Desconhecido',
        na: naSelecionadaAtual,
      }));
  
      setOrcamentoSelecionado(arrayOrcamento);
      setOrcamentoFiltrado(arrayOrcamento); 
      setOpenModalOrcamento(true);
    } catch (error) {
      setMensagemAlert({ title: 'Atenção!', body: 'Não há orçamento para essa NA.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
    } finally {
      setCarregandoTableOrcamento(false);
    }
  };

  const fecharModalOrcamento = () => {
    setPesquisaOrcamento('');
    setPageOrcamento(0);
    setOrcamentoFiltrado(orcamentoSelecionado ? orcamentoSelecionado : []);
    setOpenModalOrcamento(false);
  }

  const buscarDadosOrcamento = (term) => {
    if (!term) {
      setOrcamentoFiltrado(orcamentoSelecionado ? orcamentoSelecionado : []);
    } else {
      const filtro = orcamentoSelecionado.filter((item) =>
        item.servico?.toLowerCase().includes(term.toLowerCase()) ||
        item.status?.toLowerCase().includes(term.toLowerCase())
      );
      setOrcamentoFiltrado(filtro);
    }
  };

  const alterarPaginaOrcamento = (event, newPage) => {
    setPageOrcamento(newPage);
  };

  const alterarLinhasOrcamento = (event) => {
    setPageSizeOrcamento(parseInt(event.target.value, 10));
    setPageOrcamento(0);
  };

const handleLimpar = async () => {
  setCarregandoBotaoLimpar(true);

  setNomeTecnicoFiltro('');
  setStatusFiltro('');
  setStartDate('');
  setEndDate('');
  setPesquisa('');
  setPage(0);

  setServicos([]);

  // Recarregar os dados com filtros resetados
  await servicos(null, null, 0, pageSize, '', '', '');

  setCarregandoBotaoLimpar(false);
};


  useEffect(() => {
    if (carregandoBotaoLimpar) return;

    servicos(
      startDate || null,
      endDate || null,
      0,
      pageSize,
      pesquisa || '',
      nomeTecnicoFiltro || '',
      statusFiltro || ''
    );
  }, [nomeTecnicoFiltro, statusFiltro, startDate, endDate, pesquisa, pageSize]);
    

  const handleOpenDialogVistoria = async (numeroNA) => {
    try {
      setLoadingVistoria(numeroNA);
  
      const areaAtendimentoID = microSigaID || authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
      const na = numeroNA;
  
      const osResult = await ordemServicoServico.detalhesOS(na, areaAtendimentoID);
      const osOrcamento = await ordemServicoServico.listaOrcamentoOS(na, areaAtendimentoID);
  
      if (osResult && osResult.length > 0) {
        let _os = {
          osID: osResult[0].tz_Ordem_ServicoId || '',
          os: osResult[0].os || '',
          activityId: osResult[0].activityId || '',
          cliente: osResult[0].cliente || '',
          clienteEmail: osResult[0].clienteEmail?.toLowerCase() || '',
          solicitante: osResult[0].tz_nome_solicitante || '',
          telefone_solicitante: osResult[0].tz_telefone_solicitante || '',
          endereco: osResult[0].tz_Endereco_Rua || '',
          numero: osResult[0].tz_Endereco_Numero || '',
          cep: osResult[0].tz_endereco_cepIdName || '',
          cidade: osResult[0].tz_endereco_cidadeIdName || '',
          estado: osResult[0].tz_endereco_EstadoIdName || '',
          referencia: osResult[0].tz_referencia || '',
          razaoStatus: osResult[0].razaoStatus || '',
          inicio: osResult[0].data_inicio,
          fim: osResult[0].data_fim,
          na: osResult[0].na || '',
          plataforma: osResult[0].tz_PlataformaIdName || '',
          rastreador: osResult[0].tz_RastreadorIdName || '',
          plano: osResult[0].tz_PlanoIdName || '',
          num_serie_contrato: osResult[0].tz_Numero_Serie_Contrato || '',
          fim_garantia: osResult[0].tz_Data_Fim_Garantia,
          observacao: osResult[0].tz_Observacoes || '',
          status: osResult[0].descStatus || '',
          obsCliente: osResult[0].detalheCliente || '',
          nomeTecnico: osResult[0].nomeTecnico || '',
          bairro: osResult[0].tz_endereco_bairro || '',
          modificacao: osResult[0].modifiedOn || '',
          servico: osResult[0].serviceIdName || '',
          antena: osResult[0].tz_Numero_Serie_Antena_Contrato || '',
          clienteID: osResult[0].clienteID || '',
          id_area_atendimento: osResult[0].tz_area_atendimento_microsigaId || areaAtendimentoID,
          NomeClienteVistoria: osResult[0].cpf_rg || '',
          tipoServico: osResult[0].tipoServico || '',
          localAtendimento: osResult[0].localAtendimento || '',
          tipoServicoAgendamento: osResult[0].tipoServicoAgendamento || '',
          placa: osResult[0].placa || '',
          cor: osResult[0].cor || '',
        };
  
        setOS(_os);
  
        if (osOrcamento && osOrcamento.length > 0) {
          const _itensOS = osOrcamento.map((o) => ({
            ordemID: o.tz_Ordem_ServicoId,
            itemID: o.tz_item_ordem_servicoId,
            produtoId: o.tz_ItemId,
            numeroProduto: o.produtoNumero,
            produto: o.produtoNome,
            quantidade: o.quantidade,
            descStatus: o.descStatus_aprovacao,
            statusAprovacao: o.status_aprovacao,
            motivoOrcamento: mapCodigoToMotivo(o.tz_motivo_orcamento),
          }));
          setItensOS(_itensOS);
        }
  
        setOpenDialogVistoria(true);
      } else {
        throw new Error('Nenhum resultado encontrado.');
      }
    } catch (error) {
      setMensagemAlert({ title: 'Atenção!', body: 'Não foi localizado a OS do serviço, entrar em contato com time de Suporte.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
    } finally {
      setLoadingVistoria(null);
    }
  };
  



  const handleChange = async (osNumero, tecnicoId) => {
    if (!osNumero || !tecnicoId) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Número da OS foi localizado. Por favor, entre em contato com suporte.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }
    setLoading(true);
  
    try {
      const response = await perfilTecnicoService.cadastrarTecnicoOS({
        osNumero,
        tecnicoId,
      });
  
      if (!response.error) {
        setMensagemAlert({
          title: 'Sucesso!',
          body: 'Técnico atribuído com sucesso!',
        });
        setTipoAlert('success');
        setAbrirAlert(true);
  
        await servicos(startDate, endDate, page, pageSize, pesquisa);
      } else {
        setMensagemAlert({
          title: 'Erro!',
          body: response.message || 'Não foi possível atribuir o técnico.',
        });
        setTipoAlert('error');
        setAbrirAlert(true);
      }
    } catch (error) {
      setMensagemAlert({
        title: 'Erro!',
        body: 'Erro ao atribuir o técnico. Tente novamente.',
      });
      setTipoAlert('error');
      setAbrirAlert(true);
    } finally {
      setLoading(false);
    }
  };
  

  const DadosOrcamentoExportacao = (naSelecionada) => {
    if (!naSelecionada) return;
    setEfeitoCarregarModalExportacao(true);
    const servicoSelecionado = _servicos.find(servico => servico.NA === naSelecionada);

    if (servicoSelecionado && servicoSelecionado.Servico_Orcamento && servicoSelecionado.Servico_Orcamento.length > 0) {
      const dadosOrcamento = servicoSelecionado.Servico_Orcamento.map((orcamento, index) => ({
        NA: naSelecionada,
        Produto: orcamento || 'Produto não especificado',
        Status: statuProdutosExportacao(servicoSelecionado.Status_aprovacao_orcamento[index] || 'Desconhecido'),
        Quantidade: servicoSelecionado.Quantidade_Servico_Orcamento[index] || 'Não informado'
      }));

      setDadosOrcamentoExportacao(dadosOrcamento);
      setTimeout(() => {
        setExportarOrcamento(true);
        setEfeitoCarregarModalExportacao(false);
      }, 2000);
      setTimeout(() => {
        setExportarOrcamento(false);
        setEfeitoCarregarModalExportacao(false);
      }, 3000);
    } else {
      setDadosOrcamentoExportacao([]);
      setEfeitoCarregarModalExportacao(false);
    }
  };

  const handleCloseDialogVistoria = () => setOpenDialogVistoria(false)

  const handleAbrirModalSelecionarMesAnoExportacao = () => {
    setAbrirModalSelecionarMesAnoExportacao(true);
  };

  const handleFecharModalSelecionarMesAnoExportacao = () => {
    setAbrirModalSelecionarMesAnoExportacao(false);
    setDataExportacao([]);
    setExportar(false);
    setMes('');
    setAno('');
    setArquivo('');
  }

  const exportarDadosSelecionados = async () => {
    if ((mes != null && mes !== '') && (ano != null && ano !== '') && (arquivo != null && arquivo !== '')) {
      setEfeitoCarregarModalExportacao(true);
      let servicosExportacaoResultado = await agendaService.buscarDadosNAPorPeriodo(microSigaID, mes, ano);
      let data = [];

      if (servicosExportacaoResultado.mensagem === 'Não há registros para serem exibidos para o mês e ano selecionado.') {
        setMensagemAlert({ title: 'Atenção!', body: servicosExportacaoResultado.mensagem });
        setTipoAlert('fail');
        setAbrirAlert(true);
        setDataExportacao([]);
        setEfeitoCarregarModalExportacao(false);
        return;
      } else {
        const formatoData = 'DD/MM/yyyy HH:mm:ss';
        for (let servico of servicosExportacaoResultado) {
          let servicoExcelObjeto = {
            'NA': servico.na,
            'Cliente': servico.nomeCliente,
            'Serviço': servico.servico,
            'Previsão de Início': servico.previsaoInicio ? moment(servico.previsaoInicio).format(formatoData) : 'Não iniciado',
            'Previsão de Término': servico.previsaoTermino ? moment(servico.previsaoTermino).format(formatoData) : 'Não finalizado',
            'Status': await statusNAExportacao(servico.status),
            'OS': servico.os,
          };
          data.push(servicoExcelObjeto);
        }
        setDataExportacao(data);
        setTimeout(() => {
          setExportar(true);
        }, 2000);
        setTimeout(() => {
          handleFecharModalSelecionarMesAnoExportacao(true);
        }, 3000);
        setEfeitoCarregarModalExportacao(false);
      }
    } else {
      setMensagemAlert({ title: 'Atenção!', body: 'É necessário selecionar um mês, ano e tipo de arquivo para exportar os dados.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
    }
  };

  const toggleOrcamento = (na) => {
    setShowOrcamento((prevState) => ({
      ...prevState,
      [na]: !prevState[na],
    }));

    setNaSelecionada(na);
    DadosOrcamentoExportacao(na);
  };

  useEffect(() => {
    if (mobile) {
      setTableKey(tableKey + 1);
    } else {
      setTableKey(tableKey + 1);
    }
    servicos(startDate, endDate, page, pageSize, pesquisa,nomeTecnicoFiltro,statusFiltro);
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      await statusNAExportacao(status);
    };

    fetchData();
  }, [status]);

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoadingData(true);
  
      if (usuarioInfo) {
        const response = await perfilTecnicoService.buscarTecnicoEmpresa(usuarioInfo.id);
        if (response && !response.error) {
          setTecnicosAprovados(response);
        } else {
          console.error('Erro ao buscar técnicos:', response.message);
        }
      }
  
      await servicos(startDate, endDate, page, pageSize, pesquisa);
  
      setLoadingData(false);
      setInitialLoadComplete(true); 
    };
  
    fetchInitialData();
  }, [usuarioInfo]);
  
  useEffect(() => {
    if (initialLoadComplete) {
    servicos(startDate, endDate, page, pageSize, pesquisa, nomeTecnicoFiltro);
    }
  }, [initialLoadComplete]);


  useEffect(() => {
    const prepararDadosExportacao = () => {
      const dados = _servicos.map((servico) => ({
        NA: servico.NA,
        Cliente: servico.Cliente,
        Serviço: servico.Serviço,
        Início: servico.Início ? moment(servico.Início).format('DD/MM/YYYY HH:mm') : 'Não iniciado',
        Término: servico.Término ? moment(servico.Término).format('DD/MM/YYYY HH:mm') : 'Não finalizado',
        Status: statusNAExportacao(servico.Status),
        nomeTecnico: servico.nomeTecnico,
      }));

      setDataExportacao(dados);
    };

    if (_servicos.length > 0) {
      prepararDadosExportacao();
    }
  }, [_servicos]);

  useEffect(() => {
    setOrcamentoFiltrado(orcamentoSelecionado ? orcamentoSelecionado : []);
  }, [orcamentoSelecionado]);

  const valorPesquisa = (nome) => {
    setPesquisa(nome);
    servicos(startDate, endDate, 0, pageSize, nome);
  };

  const limparPesquisa = () => {
    setPesquisa('');
    servicos(startDate, endDate, 0, pageSize, '');
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    servicos(startDate, endDate, newPage, pageSize, pesquisa);
  };

  const mudarTamanhoPagina = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    servicos(startDate, endDate, 0, parseInt(event.target.value, 10), pesquisa);
  };


  const handlePesquisar = async () => {
    if (moment(startDate).isAfter(endDate)) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Data inicial não pode ser maior que a data final.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }
  
    if (moment(startDate).isAfter(moment())) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Data inicial não pode ser maior que a data atual.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }
  
    setCarregandoBotaoPesquisar(true);
    setCarregandoTable(true);
    
    await servicos(startDate, endDate, page, pageSize, pesquisa, nomeTecnicoFiltro, statusFiltro);
    
    setCarregandoTable(false);
    setIsSearchMode(false);
    setCarregandoBotaoPesquisar(false);
  };
  const filtrarServicos = (dadosServicos, nomeTecnicoFiltro) => {
    if (!nomeTecnicoFiltro) return dadosServicos;
  
    return dadosServicos.filter(
      (servico) => servico.nomeTecnico && servico.nomeTecnico === nomeTecnicoFiltro 
    );
  };
  const statusOptions = [
    { value: '', label: 'Todos' },
    { value: 'concluido', label: 'Concluído' },
    { value: 'cancelado', label: 'Cancelado' },
    { value: 'agendado', label: 'Agendado/Aberto' },
];

  const servicos = async (dataInicial, dataFinal, page, pageSize, pesquisa, nomeTecnicoFiltro, statusFiltro) => {
    if (dataInicial && dataFinal && moment(dataInicial).isAfter(dataFinal)) {
      setMensagemAlert({ title: 'Atenção!', body: 'Data inicial não pode ser maior que a data final.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      setCarregandoTable(false);
      return;
    }
    setCarregandoTable(true);
    try {
      const dados = {
        dataInicial: dataInicial ? dataInicial : '',
        dataFinal: dataFinal ? dataFinal : '',
        pagina: page ? page : 0,
        limite: pageSize ? pageSize : 5,
        nome: pesquisa ? pesquisa : '',
        nomeTecnico: nomeTecnicoFiltro ? nomeTecnicoFiltro : '' ,
        status: statusFiltro ? statusFiltro : '' ,
      };
  
      let servicosResult = await agendaService.getNas(dados, microSigaID);
  
      let data = [];
      if (servicosResult.dados) {
        setQuantidadeNA(servicosResult.total || 0);
        const formatoData = 'DD/MM/yyyy HH:mm:ss';
        let dadosServicos = servicosResult.dados;
  
        const servicosFiltrados = filtrarServicos(dadosServicos, nomeTecnicoFiltro);

        if (statusFiltro) {
          dadosServicos = dadosServicos.filter(d => d.Status === statusFiltro);
        }
        for (let servico of servicosFiltrados) {
          let statusAprovacaoOrcamento = [];
          let servicoOrcamento = [];
          let quantidadeServicoOrcamento = [];
  

  
          let servicoNome = servico.servico === servico.servico.toUpperCase() ? servico.servico.toLowerCase() : servico.servico;
          let servicoObjeto = {
            NA: servico.na,
            Cliente: servico.nomeCliente || 'Não informado',
            Assunto: servico.assunto || 'Não informado',
            Serviço: servicoNome || 'Não informado',
            Status_aprovacao_orcamento: statusAprovacaoOrcamento,
            Servico_Orcamento: servicoOrcamento,
            Quantidade_Servico_Orcamento: quantidadeServicoOrcamento,
            Início: servico.inicio ? moment(servico.inicio).format(formatoData) : null,
            Término: servico.termino ? moment(servico.termino).format(formatoData) : null,
            PrevisaoInicio: servico.previsaoInicio ? moment(servico.previsaoInicio).format(formatoData) : null,
            PrevisaoTermino: servico.previsaoTermino ? moment(servico.previsaoTermino).format(formatoData) : null,
            Status: servico.status,
            OS: servico.os,
            Observação: servico.osObservacoes,
            nomeTecnico: servico.nomeTecnico,
            loginTecnico: servico.loginTecnico,
            Endereco: '-',
            Logradouro: servico.enderecoRua,
            Numero: servico.enderecoNumero,
            CEP: servico.enderecoCepIdName,
            Bairro: servico.enderecoBairro,
            Cidade: servico.enderecoCidadeIdName,
            Estado: servico.enderecoEstadoIdName,
            Complemento: servico.enderecoComplemento,
            Veiculo: '-',
            Placa: servico.veiculoIdName,
            PlacaCarreta: servico.placaCarreta,
            Série: servico.numSerieRastreadorInstalado,
          };
          data.push(servicoObjeto);
        }
        setTotalDados(servicosResult.total);
        setServicos(data);
      } else {
        setMensagemAlert('Erro ao consultar serviços.');
        setAbrirAlert(true);
      }
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao consultar serviços.' });
      setTipoAlert('error');
      setAbrirAlert(true);
    }
    setCarregandoTable(false);
  };
    
  useEffect(() => {
    if (initialLoadComplete) {
      servicos(
        startDate || null,
        endDate || null,
        page,
        pageSize,
        pesquisa || '',
        nomeTecnicoFiltro || '',
        statusFiltro || ''
      );
    }
  }, [initialLoadComplete]);

  const statusNAExportacao = (status) => {
    switch (status) {
      case 0:
        return 'Aberta';
      case 1:
        return 'Concluído';
      case 2:
        return 'Cancelado';
      case 3:
        return 'Agendado';
      default:
        return 'Agendado';
    }
  };
  const formatNomeCliente = (nome) => {
    if (!nome) return 'Não informado';
    return nome.charAt(0).toUpperCase() + nome.slice(1).toLowerCase();
  };

  const statuProdutosExportacao = (status) => {
    switch (status) {
      case 'Aprovado':
        return 'Aprovado';
      case 'Aguardando Aprovacao':
        return 'Aguardando Aprovação';
      case 'Recusado':
        return 'Recusado';
      default:
        return 'Desconhecido';
    }
  };

  const styleStatus = (status) => {
    const commonStyle = {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start', 
      color: 'white',
      width: '100%',
      height: 44,
      marginTop: '10px',
      borderRadius: 15,
      paddingLeft:  mobile ? '30%' : '39%',
    };

    const iconStyle = {
      marginRight: '12px',
      fontSize: '20px',
    };

    switch (status) {
      case 0:
        return (
          <div style={{ ...commonStyle, background: '#DEB887' }}>
            <HourglassEmptyIcon style={{ ...iconStyle, color: 'white' }} />
            Aberta
          </div>
        );
      case 1:
        return (
          <div style={{ ...commonStyle, background: 'rgba(3, 201, 169, 1)' }}>
            <CheckCircleIcon style={{ ...iconStyle, color: 'white' }} />
            Concluído
          </div>
        );
      case 2:
        return (
          <div style={{ ...commonStyle, background: 'rgba(255, 99, 132, 1)' }}>
            <CancelIcon style={{ ...iconStyle, color: 'white' }} />
            Cancelado
          </div>
        );
      case 3:
        return (
          <div style={{ ...commonStyle, background: '#3174ad' }}>
            <HourglassEmptyIcon style={{ ...iconStyle, color: 'white' }} />
            Agendado
          </div>
        );
      default:
        return (
          <div style={{ ...commonStyle, background: '#4682B4' }}>
            <HelpOutlineIcon style={{ ...iconStyle, color: 'white' }} />
            Status desconhecido
          </div>
        );
    }
  };

  const orcamentoPaginado = orcamentoFiltrado.slice(pageOrcamento * pageSizeOrcamento, pageOrcamento * pageSizeOrcamento + pageSizeOrcamento);

  return !openDialogVistoria ? (
    <>
      <MenuComponent />
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      <div style={{ margin: 30 }}>
        <h3 className={classes.tituloStyle}>Ordens de Serviço</h3>
        <h4 className={classes.subtituloStyle}>
          <span
            onClick={() => {
              const userInfo = authService.getUserInfo();
              if (userInfo.usuario.gestor) {
                window.location.assign('/gestor');
              } else {
                window.location.assign('/revenda');
              }
            }}
            className={classes.linkStyle}
            style={{ cursor: 'pointer' }}
          >
            Home
          </span>
          {' > '}
          Serviços
          {authService.getUserInfo().usuario.gestor === true &&
            nomeTecnico &&
            nomeTecnico.trim() !== '' ? (
            <>
              {' > '}
              {nomeTecnico}
            </>
          ) : null}
        </h4>

        {authService.getUserInfo().usuario.gestor === true &&
          nomeTecnico &&
          nomeTecnico.trim() !== '' ? (
          <Grid item xs={3} sm={1} md={1} style={{ marginBottom: 20 }}>
            <Button
              onClick={handleCloseOrdemServico}
              style={{ color: '#209BDE' }}
              startIcon={<ArrowBackIosIcon />}
            >
              Voltar
            </Button>
          </Grid>
        ) : null}

        <div style={{ marginBottom: 20 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="start-date"
                  label="Data inicial"
                  type="date"
                  value={startDate ? startDate : ''}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="end-date"
                  label="Data final"
                  type="date"
                  value={endDate ? endDate : ''}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
            <Select
              options={tecnicosAprovados.map((tecnico) => ({
                value: tecnico.nomeTecnico,
                label: tecnico.nomeTecnico,
              }))}
              isLoading={carregandoBotaoLimpar} 
              placeholder={
                carregandoBotaoLimpar ? 'Carregando técnicos...' : 'Selecione o Técnico'
              }
              value={
                nomeTecnicoFiltro
                  ? { value: nomeTecnicoFiltro, label: nomeTecnicoFiltro }
                  : null
              }
              onChange={(selectedOption) =>
                setNomeTecnicoFiltro(selectedOption ? selectedOption.value : '')
              }
              isClearable 
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  minHeight: '55px',
                  borderRadius: '5px',
                  backgroundColor: state.isFocused ? '#f0f8ff' : '#EFF2F8', 
                  borderColor: state.isFocused ? '#007BB5' : '#ccc',
                  boxShadow: state.isFocused ? '0 0 0 2px rgba(0, 123, 181, 0.25)' : null,
                  '&:hover': {
                    borderColor: '#007BB5',
                  },
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: carregandoBotaoLimpar ? '#aaa' : '#000',
                }),
              }}
            />
            </Grid>

            <Grid item xs={12} sm={2}>
            <Select
              options={statusOptions}
              value={statusOptions.find(option => option.value === statusFiltro)}
              onChange={(selectedOption) => setStatusFiltro(selectedOption.value)}
              placeholder="Selecione o Status"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  minHeight: '55px',
                  borderRadius: '5px',
                  backgroundColor: state.isFocused ? '#f0f8ff' : '#EFF2F8', 
                  borderColor: state.isFocused ? '#007BB5' : '#ccc',
                  boxShadow: state.isFocused ? '0 0 0 2px rgba(0, 123, 181, 0.25)' : null,
                  '&:hover': {
                    borderColor: '#007BB5',
                  },
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: carregandoBotaoLimpar ? '#aaa' : '#000',
                }),
              }}
            />
            </Grid>
            <Grid item xs={12} sm={2}>
              <BlueButton
                variant="contained"
                color="primary"
                size="large"
                onClick={handlePesquisar}
                fullWidth
                startIcon={carregandoBotaoPesquisar ? <CircularProgress color="primary" size={20} /> : <SearchIcon />}
                disabled={carregandoBotaoPesquisar}
                style={{ height: 55 }}
              >
                <span style={{ fontSize: '0.7rem' }}>{carregandoBotaoPesquisar ? ' PESQUISANDO...' : ' PESQUISAR'}</span>
              </BlueButton>
            </Grid>

            <Grid item xs={12} sm={2}>
              <BlueButton
                variant="contained"
                color="primary"
                size="large"
                onClick={handleLimpar}
                fullWidth
                startIcon={carregandoBotaoLimpar ? <CircularProgress color="primary" size={20} /> : <ClearIcon />}
                disabled={carregandoBotaoLimpar}
                style={{ height: 55 }}
              >
                <span style={{ fontSize: '0.7rem' }}>{carregandoBotaoLimpar ? ' LIMPANDO...' : ' LIMPAR'}</span>
              </BlueButton>
            </Grid>
          </Grid>
        </div>

        <div>
          <Dialog
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                handleFecharModalSelecionarMesAnoExportacao();
              }
            }}
            open={abrirModalSelecionarMesAnoExportacao}
            style={{ marginTop: 10, marginBottom: 10 }}
            PaperProps={{
              style: {
                maxWidth: '550px',  
                width: '100%', 
              },
            }}
          >
            <DialogTitle>
              <Grid container alignItems="center">
                <Grid item xs={10} sm={11}>
                  Selecione o mês, ano e o formato para exportação:
                </Grid>
                <Grid item xs={2} sm={1}>
                  <IconButton onClick={handleFecharModalSelecionarMesAnoExportacao}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
              <FormControl variant="outlined" fullWidth margin="normal">
              <label>Mês <span style={{ color: 'red' }}> * </span></label>
                <Select
                      options={mesOptions}
                      value={mes ? mesOptions.find((option) => option.value === mes) : null}
                      onChange={(selectedOption) => setMes(selectedOption?.value || '')}
                      placeholder="Selecione o mês"
                      isClearable
                      menuPortalTarget={document.body}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: '55px',
                          borderRadius: '5px',
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        menu: (provided) => ({
                          ...provided,
                          maxHeight: '150px', 
                          overflowY: 'auto', 
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: '150px', 
                        }), 
                      }}
                    />
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
              <label>Ano <span style={{ color: 'red' }}> * </span></label>
                <Select
                  options={anoOptions}
                  value={ano ? anoOptions.find((option) => option.value === ano) : null}
                  onChange={(selectedOption) => setAno(selectedOption?.value || '')}
                  placeholder="Selecione o ano"
                  isClearable
                  menuPortalTarget={document.body}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: '55px',
                      borderRadius: '5px',
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), 
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '150px', 
                      overflowY: 'auto', 
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: '150px', 
                    }),
                  }}
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <label>Arquivo <span style={{ color: 'red' }}> * </span></label>
                <Select
                  options={arquivoOptions}
                  value={arquivo ? arquivoOptions.find((option) => option.value === arquivo) : null}
                  onChange={(selectedOption) => setArquivo(selectedOption?.value || '')}
                  placeholder="Selecione o formato de arquivo"
                  isClearable
                  menuPortalTarget={document.body}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: '55px',
                      borderRadius: '5px',
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), 
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '100px', 
                      overflowY: 'auto', 
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: '150px', 
                    }),
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-around' }}>
              <Button
                variant="contained"
                fullWidth
                disabled={efeitoCarregarModalExportacao}
                onClick={handleFecharModalSelecionarMesAnoExportacao}
                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
              >
                Cancelar
              </Button>
              <BlueButton
                variant="contained"
                color="primary"
                size="large"
                disabled={efeitoCarregarModalExportacao}
                onClick={exportarDadosSelecionados}
                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
              >
                {efeitoCarregarModalExportacao ? 'Exportando...' : 'Exportar'} {efeitoCarregarModalExportacao && <CircularProgress size={20} />}
              </BlueButton>
            </DialogActions>
          </Dialog>

          {exportar && <FilesExportFilter data={dataExportacao} fileName={'Serviços'} fileExport={arquivo} />}
        </div>

        <Dialog
          aria-labelledby="customized-dialog-title"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              fecharModalOrcamento();
            }
          }}
          open={openModalOrcamento}
          style={{ marginTop: 45, marginBottom: 10 }}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Grid container alignItems="center">
              <Grid item xs={10} sm={11}>
                Orçamento
              </Grid>
              <Grid item xs={2} sm={1}>
                <IconButton onClick={fecharModalOrcamento}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Box display="flex" flexDirection="column" style={{ marginBottom: 20 }}>
              <Grid container justifyContent="space-between">
                <Grid item xs={mobile ? 12 : 5}>
                  <TextField
                    label="Pesquisar"
                    variant="outlined"
                    value={pesquisaOrcamento}
                    onChange={(e) => {
                      const term = e.target.value;
                      setPesquisaOrcamento(term);
                      buscarDadosOrcamento(term);
                    }}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <>
                          {pesquisaOrcamento ? (
                            <IconButton
                              onClick={() => {
                                setPesquisaOrcamento('');
                                buscarDadosOrcamento('');
                              }}
                              size="small"
                            >
                              <CloseIcon />
                            </IconButton>
                          ) : (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={mobile ? 12 : 5} style={{ textAlign: 'right', marginTop: mobile ? 10 : 0 }}>
                  <BlueButton
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={efeitoCarregarModalExportacao ? <CircularProgress size={20} /> : <DownloadIcon />}
                    onClick={() => toggleOrcamento(naSelecionada)}
                    fullWidth
                    disabled={efeitoCarregarModalExportacao || orcamentoPaginado[0]?.servico === null}
                    style={{ height: 40 }}
                  >
                    {efeitoCarregarModalExportacao ? 'Exportando...' : 'Exportar'}
                  </BlueButton>
                </Grid>
              </Grid>
            </Box>

            {exportarOrcamento && <FilesExportFilterOrcamento data={dadosOrcamentoExportacao} fileName={`Orcamento_NA_${naSelecionada}`} fileExport={'pdf'} />}

            {carregandoTableOrcamento ? (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            ) : mobile ? (
              <Grid container spacing={2}>
                {orcamentoPaginado.length > 0 && orcamentoPaginado[0].servico !== null ? (
                  orcamentoPaginado.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <Paper style={{ padding: '16px' }}>
                        <Box padding={1} style={{ flexGrow: 1 }}>
                          <Typography component="div" variant="body2">
                            <strong>Serviço/Produto:</strong> {item.servico}
                          </Typography>
                          <Typography component="div" variant="body2">
                            <strong>Status:</strong> {item.status === 'Aguardando Aprovacao' ? 'Aguardando Aprovação' : item.status}
                          </Typography>
                          <Typography component="div" variant="body2">
                            <strong>Quantidade:</strong> {item.quantidade}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  ))
                ) : (
                  <Grid container justifyContent="center" alignItems="center" style={{ margin: 10 }}>
                    <Typography variant="subtitle1" color="textSecondary">
                      Nenhum orçamento encontrado.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ) : (
              orcamentoPaginado.length > 0 && orcamentoPaginado[0].servico !== null && (
                <Grid container>
                  <Grid item xs={6}>
                    <Typography style={{ fontWeight: 'bold' }}>Serviço/Produto</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{ fontWeight: 'bold' }}>Status</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{ fontWeight: 'bold' }}>Quantidade</Typography>
                  </Grid>
                </Grid>
              )
            )}
            {!mobile && (
              <>
                <Box style={{ flex: 1, overflowY: 'auto' }}>
                  {orcamentoPaginado.length > 0 && orcamentoPaginado[0].servico !== null ? (
                    orcamentoPaginado.map((item, index) => (
                      <Grid container key={index} style={{ padding: '8px 0' }} wrap="nowrap">
                        <Grid item xs={6}>
                          <Typography component="div" variant="body2">{item.servico}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography component="div" variant="body2">{item.status === 'Aguardando Aprovacao' ? 'Aguardando Aprovação' : item.status}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component="div" variant="body2">{item.quantidade}</Typography>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 10 }}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Nenhum orçamento encontrado.
                      </Typography>
                    </Grid>
                  )}
                </Box>
              </>
            )}
            {orcamentoFiltrado.length > 8 && (
              <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                <Grid item>
                  {orcamentoSelecionado?.length > 0 && orcamentoSelecionado[0].servico !== null && (
                    <CustomTablePagination
                      count={orcamentoFiltrado.length}
                      page={pageOrcamento}
                      rowsPerPage={pageSizeOrcamento}
                      onPageChange={alterarPaginaOrcamento}
                      quantidadeItens={orcamentoFiltrado.length}
                      onRowsPerPageChange={alterarLinhasOrcamento}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={fecharModalOrcamento}
              variant="contained"
              style={{ float: 'right', width: '40%', height: 55 }}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        <Paper className={classes.paper}>
          <Grid container spacing={1} direction="column">
            <Grid container justifyContent="space-between" item xs={12} sm={12} style={{ marginBottom: 15 }}>
              <TextField
                label="Pesquisar"
                variant="outlined"
                value={pesquisa}
                onChange={(event) => valorPesquisa(event.target.value)}
                style={{ width: mobile ? '100%' : '40%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {pesquisa ? (
                        <IconButton onClick={limparPesquisa}>
                          <ClearIcon />
                        </IconButton>
                      ) : (
                        <SearchIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <BlueButton
                variant="contained"
                color="primary"
                size="large"
                onClick={handleAbrirModalSelecionarMesAnoExportacao}
                startIcon={<DownloadIcon />}
                style={{ height: 55, marginTop: mobile ? 10 : 0, width: mobile ? '100%' : '20%' }}
              >
                <span style={{ fontSize: '0.7rem' }}>Exportar</span>
              </BlueButton>
            </Grid>
          </Grid>

          {carregandoTable || carregandoBotaoLimpar ? (
              <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                <CircularProgress />
              </Grid>
            ) : _servicos.length === 0 ? (
              <Grid container justifyContent="center" alignItems="center" >
                <Typography variant="subtitle1" color="textSecondary">
                  Nenhum serviço encontrado.
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid container spacing={1} alignItems="stretch">
                   {_servicos.map((servico, index) => (
                      <Grid item key={index} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                      <Paper
                        style={{
                          padding: '16px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          minHeight: classificacaoPrestador !== 2 ? 320 : 250,
                          height: '100%',
                          boxSizing: 'border-box',
                        }}
                      >
                        <Box padding={1} style={{ flexGrow: 1 }} key={index}>
                          <Typography component="div" variant="body2">
                          <strong>NA: </strong>{servico.NA}
                          </Typography>
                          <Box>
                          <Typography component="div" variant="body2">
                          <strong>Responsável:</strong> {servico.nomeTecnico || 'Não informado'}
                          </Typography>

                        </Box>
                          <Typography component="div" variant="body2">
                            <strong>Cliente:</strong> {formatNomeCliente(servico.Cliente)}
                          </Typography>
                          <Typography component="div" variant="body2">
                            <strong>Serviço:</strong> {servico.Serviço || 'Não informado'}
                          </Typography>
                          <Typography component="div" variant="body2">
                            <strong>Início:</strong> {servico.PrevisaoInicio ? servico.PrevisaoInicio : 'Não iniciado'}
                          </Typography>
                          <Typography component="div" variant="body2">
                            <strong>Término:</strong> {servico.PrevisaoTermino ? servico.PrevisaoTermino : 'Não finalizado'}
                          </Typography>
                        </Box>
                        
                        <Grid container spacing={1} style={{ marginTop: 5 }}>
                          <Grid item xs={12} sm={12}>
                            <Typography component="div" variant="body2">{styleStatus(servico.Status)}</Typography>
                          </Grid>

                          {classificacaoPrestador !== 2 && (
                          <Grid item xs={12} sm={12}>
                            <BlueButton
                              variant="contained"
                              color="primary"
                              size="large"
                              startIcon={loadingVistoria === servico.NA ? <CircularProgress color="primary" size={20} /> : <AssignmentIcon />}
                              onClick={() => handleOpenDialogVistoria(servico.NA)}
                              fullWidth
                              disabled={loadingVistoria === servico.NA}
                              style={{
                                height: 55,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center', 
                                textAlign: 'left',
                                paddingLeft:  mobile ? '30%' : '40%',
                              }}
                            >
                              <span style={{ fontSize: '0.7rem' }}>Vistoria</span>
                            </BlueButton>
                          </Grid>
                          )}
                          {classificacaoPrestador !== 2 && (
                            <Grid item xs={12} sm={12}>
                              <BlueButton
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<ShoppingBasketIcon />}
                                onClick={() => abrirModalOrcamento(servico)} 
                                fullWidth
                                style={{
                                  height: 55,
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center', 
                                  textAlign: 'left',
                                  paddingLeft:  mobile ? '30%' : '40%',
                                }}
                              >
                                <span style={{ fontSize: '0.7rem' }}>Orçamento</span>
                              </BlueButton>
                            </Grid>
                          )}

                          <Grid item xs={12} sm={12}>
                            <BlueButton
                              onClick={() => toggleSelect(servico.NA)}
                              variant="contained"
                              color="primary"
                              size="large"
                              style={{
                                height: 55,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center', 
                                textAlign: 'left',
                                paddingLeft:  mobile ? '30%' : '40%',
                                marginBottom: '10px',
                              }}
                              startIcon={<PersonIcon />}
                              fullWidth
                            >
                              <span style={{ fontSize: '0.7rem' }}>
                                {selectedServico === servico.NA ? 'Fechar Seleção' : 'Selecionar Técnico'}
                              </span>
                            </BlueButton>
                            <div style={{ minHeight: '60px' }}>
                              {selectedServico === servico.NA && ( 
                                <Select
                                options={tecnicoOptions}
                                isClearable
                                placeholder="Selecione o Técnico"
                                value={tecnicoOptions.find((option) => option.value === nomeTecnicoFiltro)}
                                onChange={(selectedOption) => handleTecnicoChange(selectedOption, servico.OS)}
                                styles={customStyles}
                            />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>

                <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                  <Grid item>
                    {_servicos.length > 0 && _servicos.length >= 8 && (
                      <CustomTablePagination
                        onPageChange={mudarPagina}
                        page={page}
                        count={totalDados}
                        rowsPerPage={pageSize}
                        quantidadeItens={quantidadeNA}
                        onRowsPerPageChange={mudarTamanhoPagina}
                        style={{ marginTop: 20 }}
                      />
                    )}
                    </Grid>
                </Grid>
              </>
            )}

        </Paper>
      </div >
    </>
  ) : (
    <>
      <MenuComponent />
      <OSVistoriaPageTecnico ordemServico={os} handleBack={handleCloseDialogVistoria} tipoVistoria="saida" itensOS={itensOS} />
    </>
  )
}
