import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class NotificacaoService {
  async listarNotificacoesVisaoTecnico(destinatarioNotificacaoId, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      const response = await fetch(`${_configAPITecnico.URI_API}/Notificacao/listarVisaoTecnico?DestinatarioNotificacaoId=${destinatarioNotificacaoId}&Pagina=${dados.Pagina}&Limite=${dados.Limite}&Pesquisa=${dados.Pesquisa}&Status=${dados.Status}`, {
        method: 'GET',
        headers: _headerToken,
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um problema ao buscar as notificações.',
      };
    }
  }

  async visualizarNotificacao(idsNotificacoes) {
    try {
      const _headerToken = authService.headerAPITecnico();
      const response = await fetch(`${_configAPITecnico.URI_API}/Notificacao/notificacaoLida`, {
        method: 'PATCH',
        headers: _headerToken,
        body: JSON.stringify(idsNotificacoes)
      });

      if (!response.ok) {
        return {
          error: true,
          message: `Erro: ${response.status} - ${response.statusText}`,
        };
      }

      const textResponse = await response.text();
      const data = textResponse ? JSON.parse(textResponse) : {};

      if (data.errors) {
        const errorsMessage = Object.values(data.errors).map((error) => error);
        return {
          error: true,
          message: errorsMessage,
        };
      }

      return data;
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar as notificações do técnico.',
      };
    }
  }
}

export default new NotificacaoService();
