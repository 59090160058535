import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import CustomAlert from '../../../components/alert/CustomAlert';
import BlueButton from '../../../components/button/BlueButton';
import vistoriaService from '../../../Services/Vistoria/vistoria-service';

export function EmailModal({ open, handleClose, vistoria, ordemServico, saveVistoria }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(ordemServico?.clienteEmail);
  const [errorEmail, setErrorEmail] = useState('');
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');

  const validateEmail = () => {
    if (!email || !email.trim()) {
      setErrorEmail('Informe o e-mail do cliente!');
      return false;
    }

    const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!REGEX_EMAIL.test(email)) {
      setErrorEmail('Formato de e-mail inválido!');
      return false;
    }

    setErrorEmail('');
    return true;
  };

  async function envioPDF() {
    const dados = {
      AtividadeId: ordemServico.activityId,
      TipoVistoria: vistoria.checkListTipo,
      ImagemBase64: vistoria.vistoriaPdfBase64
    }

    await vistoriaService.enviaPDFVistoria(JSON.stringify(dados));
  }

  async function sendEmail() {
    try {
      setLoading(true);

      if (!validateEmail()) {
        setLoading(false);
        return;
      }

      if (!vistoria.id) {
        await saveVistoria();
      } else {
        if (vistoria.checkListTipo === 'Entrada' && vistoria.statusEntrada === 'Aceito') {
          vistoria.checkListTipo = 'Saida'
        }
      }

      if (vistoria.checkListTipo === 'Saida') {
        vistoria.emailDeSaidaEnviado = true;
      } else {
        vistoria.emailDeEntradaEnviado = true;
      }

      const destinatario = email?.trim();

      const response = await vistoriaService.updateVistoria(vistoria.id_agendamento, vistoria.id_ordem_servico, vistoria)

      if (response.status === 200) {
        const envioEmail = await vistoriaService.enviaEmailChecklist(vistoria, destinatario);
        if (envioEmail.status === 200) {
          await envioPDF();

          setMensagemAlert({ title: 'Sucesso!', body: 'E-mail enviado com sucesso.' });
          setTipoAlert('success');
          setAbrirAlert(true);

          setLoading(false);
          handleClose();
          window.location.reload();
        } else {
          setMensagemAlert({ title: 'Erro!', body: 'Não foi possível enviar o e-mail.' });
          setTipoAlert('error');
          setAbrirAlert(true);

          handleClose();
          setLoading(false);
        }
      } else {
        setMensagemAlert({ title: 'Erro!', body: 'Não foi possível atualizar a vistoria.' });
        setTipoAlert('error');
        setAbrirAlert(true);

        handleClose();
        setLoading(false);
      }

    } catch (error) {
      setLoading(false);

      setMensagemAlert({ title: 'Erro!', body: 'Não foi possível enviar o e-mail.' });
      setTipoAlert('error');
      setAbrirAlert(true);

      handleClose();
    }
  }

  return (
    <>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ marginTop: 35, marginBottom: 10 }}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
      >
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item xs={10} sm={11}>
              Enviar e-mail
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Informe o e-mail do cliente para que ele receba as informações de
            confirmação.
          </DialogContentText>
          <FormControl
            variant="outlined"
            required
            fullWidth
            style={{ marginBottom: '16px' }}>
            <TextField
              variant="outlined"
              type="email"
              autoFocus
              error={errorEmail && errorEmail.trim()}
              helperText={errorEmail}
              label={<span>E-mail <span style={{ color: 'red' }}> *</span></span>}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
            />
          </FormControl>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleClose}
            disabled={loading}
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
          >
            Cancelar
          </Button>
          <BlueButton
            variant="contained"
            color="primary"
            size="large"
            onClick={sendEmail}
            disabled={loading}
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
          >
            {loading ? 'Enviando...' : 'Enviar'} {loading && <CircularProgress size={20} />}
          </BlueButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
