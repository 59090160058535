import { FormControl, TextField } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import {
  Box,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CustomAlert from '../components/alert/CustomAlert';
import BlueButton from '../components/button/BlueButton';
import CustomTablePagination from '../components/pagination/pagination-component';
import auditoriaService from '../Services/Auditoria/auditoria-service';
import gestorService from '../Services/gestor/gestor-service';
import AtividadeDialog from './atividade/atividade-dialog';
import './TabelaTecnicos.css';


const statusColors = {
  inProgress: 'blue',
  finished: 'green',
  notCheckin: '#6495ED',
  notCheckout: 'red',
  timeAlert: 'yellow',
  checkinOutOfTime: '#98FB98',
  default: 'default',
};

const statusMessages = {
  finished: 'Técnico finalizou a atividade',
  inProgress: 'Atividade em andamento',
  notCheckin: 'Técnico não fez checkin',
  notCheckout: 'Técnico não fez checkout',
  timeAlert: 'Prazo de checkout está chegando',
  checkinOutOfTime: 'Técnico realizou o checkin fora do prazo',
  default: '',
};


export default function TabelaTecnicos() {
  const [atividadesTecnicos, setAtividadesTecnicos] = useState([]);
  const [selectedAtividade, setSelectedAtividade] = useState(null);
  const [openDetalhesAtividade, setOpenDetalhesAtividade] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const theme = useTheme();
  const [tecnicos, setTecnicos] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [tecnicoSelecionado, setTecnicoSelecionado] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [totalDados, setTotalDados] = useState(0);
  const [, setBuscaRealizada] = useState(false);

  useEffect(() => {
    atividades('', '', 0, pageSize);
    usuarios();
  }, []);

  const atividades = async (startDate, endDate, page, pageSize, tecnicoSelecionado) => {
    setLoading(true);
    if (startDate && endDate && moment(startDate).isAfter(endDate)) {
      setMensagemAlert({ title: 'Atenção!', body: 'Data inicial não pode ser maior que a data final!' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      setStartDate('');
      setEndDate('');

      setLoading(false);
      return;
    }

    const dados = {
      dataInicial: startDate ? startDate : '',
      dataFinal: endDate ? endDate : '',
      pagina: page ? page : 0,
      limite: pageSize ? pageSize : 8,
      tecnicoNome: tecnicoSelecionado ? tecnicoSelecionado : '',
    }

    const listaAtividades = await gestorService.listaAtividadesTecnicos(dados);

    if (listaAtividades.dados) {
      let dadosAtividades = listaAtividades.dados;
      const atividadesPorTecnico = {};

      dadosAtividades.forEach((a) => {
        const tecnicoNome = a.tecnicoNome || '';
        if (!atividadesPorTecnico[tecnicoNome]) {
          atividadesPorTecnico[tecnicoNome] = [];
        }

        if (a.previsaoInicio) {
          var dataInicioAtual = new Date(a.previsaoInicio);
          dataInicioAtual.setHours(dataInicioAtual.getHours() - 3);
          dataInicioAtual.setTime(dataInicioAtual.getTime() - 180 * 60 * 1000);
          var novaPrevisaoInicio = dataInicioAtual.toISOString().slice(0, 19);
        }
        if (a.previsaoTermino) {
          var dataTerminoAtual = new Date(a.previsaoTermino);
          dataTerminoAtual.setHours(dataTerminoAtual.getHours() - 3);
          dataTerminoAtual.setTime(dataTerminoAtual.getTime() - 180 * 60 * 1000);
          var novaPrevisaoTermino = dataTerminoAtual.toISOString().slice(0, 19);
        }

        atividadesPorTecnico[tecnicoNome].push({
          id: a.atividadeID,
          tecnicoNome: a.tecnicoNome,
          checkin: a.inicio || '',
          checkout: a.termino || '',
          prazoCheckin: novaPrevisaoInicio || '',
          prazoCheckout: novaPrevisaoTermino || '',
          clienteNome: a.clienteNome,
          na: a.atividadeNumero
        });
      });

      const atividadesTransformadas = Object.keys(atividadesPorTecnico).map(
        (nome) => ({
          nome,
          atividades: atividadesPorTecnico[nome],
        }),
      );

      setAtividadesTecnicos(atividadesTransformadas);
      setTotalDados(listaAtividades.total);
    }
    setLoading(false);
  }

  const usuarios = async () => {
    const response = await auditoriaService.listarEmpresasGestor();
    if (!response.error) {
      setTecnicos(response);
    }
  }

  const realizarBusca = () => {
      if ((!startDate || !endDate) && !tecnicoSelecionado) {
      setMensagemAlert({ title: 'Atenção!', body: 'Por favor, selecione um técnico ou ambas as datas!' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }


    if (moment(startDate).isAfter(endDate)) {
      setMensagemAlert({ title: 'Atenção!', body: 'A data inicial não pode ser maior que a data final!' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      setStartDate('');
      setEndDate('');
      return;
    }

    setPage(0);
    atividades(startDate, endDate, 0, pageSize, tecnicoSelecionado);
    setBuscaRealizada(true);
  };

  const limparDados = async () => {
    setPage(0);
    setPageSize(8);
    setEndDate('');
    setStartDate('');
    setTecnicoSelecionado('');
    atividades('', '', 0, pageSize, '');
    setBuscaRealizada(false);
  };
  const customStylesSelect = {
    control: (provided, state) => ({
      ...provided,
      height: '55px',
      minHeight: '55px',
      borderRadius: '4px',
      borderColor: state.isFocused ? '#209BDE' : '#B2B8C3', 
      boxShadow: state.isFocused ? '0 0 0 1px #209BDE' : 'none',
      backgroundColor: '#FFFFFF',
      color: '#000000',
      '&:hover': {
        borderColor: 'black',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '55px',
      padding: '0 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      color: '#000000', 
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '55px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#000000', 
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#000000', 
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      color: '#000000',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#E8F0FE' 
        : undefined,
      color: '#000000', 
      '&:active': {
        backgroundColor: '#E8F0FE', 
      },
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#000000',
      '&:hover': {
        color: '#000000',
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#000000',
      '&:hover': {
        color: '#000000',
      },
    }),
  };
  
  
  const opcoes_tecnicos = tecnicos.map(tecnico => ({
    value: tecnico.nome, 
    label: tecnico.nome,
  }));

  const hours = []; for (let h = 7; h <= 22; h++) { hours.push({ hour: h, minute: 0 }); hours.push({ hour: h, minute: 30 }); }

  const isHourInRange = (hour, startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);

    const checkHour = hour.hour;
    const checkMinute = hour.minute;

    const startHour = start.getHours();
    const startMinute = start.getMinutes();
    const endHour = end.getHours();
    const endMinute = end.getMinutes();

    const isAfterStart =
      checkHour > startHour || (checkHour === startHour && checkMinute >= startMinute);
    const isBeforeEnd =
      checkHour < endHour || (checkHour === endHour && checkMinute <= endMinute);

    return isAfterStart && isBeforeEnd;
  };

  const getCellStatus = (atividade, hour) => {
    const checkinTime = new Date();
    const prazoCheckinTime = new Date();

    const timeCheckin = atividade.checkin.split('T')[1];
    const timePrazoCheckin = atividade.prazoCheckin.split('T')[1];

    if (!isHourInRange(hour, atividade.checkin, atividade.checkout) && !isHourInRange(hour, atividade.prazoCheckin, atividade.prazoCheckout)) {
      return 'default';
    }

    const isCheckinEmpty = atividade.checkin === '';
    if (isCheckinEmpty) {
      return 'notCheckin';
    }

    checkinTime.setHours(
      parseInt(timeCheckin.split(':')[0]),
      parseInt(timeCheckin.split(':')[1]),
    );
    prazoCheckinTime.setHours(
      parseInt(timePrazoCheckin.split(':')[0]),
      parseInt(timePrazoCheckin.split(':')[1]),
    );

    const isCheckinTimeOutOfRange =
      Math.abs(checkinTime - prazoCheckinTime) > 30 * 60 * 1000;

    if (atividade.checkin && atividade.checkout) {
      if (isCheckinTimeOutOfRange) {
        return 'checkinOutOfTime';
      } else if (isHourInRange(hour, atividade.checkin, atividade.checkout)) {
        return 'finished';
      }
    } else {
      if (!atividade.checkin) {
        return isHourInRange(hour, atividade.prazoCheckin, atividade.prazoCheckout) ? 'notCheckin' : 'default';
      }
      if (!atividade.checkout) {
        if (isCheckinTimeOutOfRange) {
          return 'checkinOutOfTime';
        }
        if (isHourInRange(hour, atividade.checkin, atividade.prazoCheckout)) {
          return 'inProgress';
        }
      }
    }

    if (isHourInRange(hour, atividade.checkin, atividade.prazoCheckout)) {
      return 'inProgress';
    }

    return 'default';
  };

  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return `${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
  };

  const LegendaStatus = ({ atividadesTecnicos }) => {
    const statusPresentes = new Set();

    if (atividadesTecnicos && Array.isArray(atividadesTecnicos)) {
      atividadesTecnicos.forEach((tecnico) => {
        if (tecnico.atividades && Array.isArray(tecnico.atividades)) {
          tecnico.atividades.forEach((atividade) => {
            hours.forEach((hour) => {
              const status = getCellStatus(atividade, hour);
              if (status !== 'default') {
                statusPresentes.add(status);
              }
            });
          });
        }
      });
    }

    if (statusPresentes.size === 0) {
      return null;
    }

    return (
      <Box mb={2} display="flex" justifyContent={isMobile ? 'center' : 'flex-end'} width="100%">
        <Paper
          elevation={3}
          style={{
            padding: '10px',
            display: 'flex',
            alignItems: isMobile ? 'flex-start' : 'center',
            flexDirection: isMobile ? 'column' : 'row',
            textAlign: isMobile ? 'center' : 'left',
            width: '100%',
            maxWidth: '100%'
          }}
        >
          <Typography variant="subtitle1" style={{ marginRight: isMobile ? 0 : 16, marginBottom: isMobile ? 8 : 0 }}>
            Legenda:
          </Typography>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            width="100%"
          >
            {Array.from(statusPresentes).map((status) => (
              <Box
                display="flex"
                alignItems="center"
                key={status}
                style={{ marginRight: isMobile ? 0 : 16, marginBottom: isMobile ? 8 : 0 }}
              >
                <Box
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor: statusColors[status],
                    borderRadius: '50%',
                    marginRight: 8
                  }}
                />
                <Typography variant="caption" style={{ color: '#000' }}>
                  {statusMessages[status]}
                </Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      </Box>

    );
  };

  const getHourAndMinuteFromDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return { hour: date.getHours(), minute: date.getMinutes() };
  };

  const handleOpenDetalhesAtividade = (atividade, status) => {
    if (status !== '') {
      const atividadeComStatus = { ...atividade, status: status };
      setSelectedAtividade(atividadeComStatus);
      setOpenDetalhesAtividade(true);
    }
  };

  const handleCloseDetalhesAtividade = () => {
    setOpenDetalhesAtividade(false);
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    atividades(startDate, endDate, newPage, pageSize, tecnicoSelecionado);
  };

  const mudarTamanhoPagina = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    atividades(startDate, endDate, 0, parseInt(event.target.value, 10), tecnicoSelecionado);
  };

  return (
    <>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />

      <Grid container spacing={2} alignItems="center" style={{ marginBottom: 20 }}>
        <Grid item xs={12} sm={3}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              id="start-date"
              label="Data inicial"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              id="end-date"
              label="Data final"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Select
            options={opcoes_tecnicos}
            value={opcoes_tecnicos.find((tecnico) => tecnico.value === tecnicoSelecionado) || null}
            onChange={(selectedOption) => {
              setTecnicoSelecionado(selectedOption ? selectedOption.value : '');
            }}
            placeholder="Selecione o Técnico"
            isClearable
            styles={customStylesSelect}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <BlueButton
            variant="contained"
            color="primary"
            size="large"
            onClick={realizarBusca}
            fullWidth
            startIcon={<SearchIcon />}
            style={{ height: 55 }}
          >
            <span style={{ fontSize: '0.7rem' }}>BUSCAR</span>
          </BlueButton>
        </Grid>

        <Grid item xs={12} sm={2}>
          <BlueButton
            variant="contained"
            color="primary"
            size="large"
            onClick={limparDados}
            fullWidth
            startIcon={<CloseIcon />}
            style={{ height: 55 }}
          >
            <span style={{ fontSize: '0.7rem' }}>LIMPAR</span>
          </BlueButton>
        </Grid>
      </Grid>

      <LegendaStatus atividadesTecnicos={atividadesTecnicos} />

      <Grid container>
        {loading ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : (
          atividadesTecnicos.length === 0 ? (
            <Grid container justifyContent="center" alignItems="center" item xs={12} style={{ margin: 10 }}>
              <Typography variant="body1">Nenhuma atividade foi encontrada.</Typography>
            </Grid>
          ) : (
            atividadesTecnicos.map((tecnico, index) => {
              const activeHours = hours.filter(hour =>
                tecnico.atividades.some(atividade =>
                  isHourInRange(hour, atividade.checkin, atividade.checkout) ||
                  isHourInRange(hour, atividade.prazoCheckin, atividade.prazoCheckout)
                )
              );

              return (
                <Grid item xs={12} key={index}>
                  <Paper style={{ padding: '8px', marginBottom: '16px' }}>
                    <Typography variant="h6" gutterBottom className="tecnico-nome">
                      {tecnico.nome}
                    </Typography>

                    {tecnico.atividades.map((atividade, atividadeIndex) => {
                      const activityHours = activeHours.filter(hour =>
                        isHourInRange(hour, atividade.checkin, atividade.checkout) ||
                        isHourInRange(hour, atividade.prazoCheckin, atividade.prazoCheckout)
                      );

                      return (
                        <Paper
                          key={`${index}-${atividadeIndex}`}
                          style={{ backgroundColor: '#F8F8FF', padding: '8px', marginBottom: '16px' }}
                          onClick={() => {
                            const hour = activityHours.find(hour =>
                              isHourInRange(hour, atividade.checkin, atividade.checkout) ||
                              isHourInRange(hour, atividade.prazoCheckin, atividade.prazoCheckout)
                            );
                            handleOpenDetalhesAtividade(atividade, statusMessages[getCellStatus(atividade, hour)]);
                          }}
                        >
                          <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>
                            {atividade.clienteNome}
                          </Typography>
                          <Box
                            display={isMobile ? 'grid' : 'flex'}
                            gridTemplateColumns={isMobile ? 'repeat(3, 1fr)' : 'none'}
                            justifyContent="center"
                            flexWrap={isMobile ? 'nowrap' : 'wrap'}
                            width="100%"
                          >
                            {activityHours.map((hour) => {
                              const checkin = atividade.checkin ? getHourAndMinuteFromDateTime(atividade.checkin) : null;
                              const checkout = atividade.checkout ? getHourAndMinuteFromDateTime(atividade.checkout) : null;
                              const displayTime = checkin && checkin.hour === hour
                                ? formatTime(atividade.checkin)
                                : checkout && checkout.hour === hour
                                  ? formatTime(atividade.checkout)
                                  : '';
                              return (
                                <Box key={`${hour.hour}:${hour.minute}`} mx={1} mb={isMobile ? 1 : 0}>
                                  <Tooltip title={statusMessages[getCellStatus(atividade, hour)]}>
                                    <Paper
                                      style={{
                                        backgroundColor: statusColors[getCellStatus(atividade, hour)],
                                        width: '55px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '8px',
                                      }}
                                    >
                                      <Typography variant="body2">
                                        {displayTime || `${hour.hour}:${hour.minute === 0 ? '00' : hour.minute}`}
                                      </Typography>
                                    </Paper>
                                  </Tooltip>
                                </Box>
                              );
                            })}
                          </Box>
                          <Grid container spacing={2} style={{ marginTop: '5px' }}>
                            <Grid item xs={6}>
                              <Paper style={{ backgroundColor: '#fff', padding: '8px', marginBottom: '16px' }}>
                                <Typography variant="body2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                  Prazo de Check-in:
                                </Typography>
                                <Typography variant="body2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                  {atividade.prazoCheckin ? formatTime(atividade.prazoCheckin) : 'Não definido'}
                                </Typography>
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              <Paper style={{ backgroundColor: '#fff', padding: '8px', marginBottom: '16px' }}>
                                <Typography variant="body2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                  Prazo de Check-out:
                                </Typography>
                                <Typography variant="body2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                  {atividade.prazoCheckout ? formatTime(atividade.prazoCheckout) : 'Não definido'}
                                </Typography>
                              </Paper>
                            </Grid>
                          </Grid>
                        </Paper>
                      );
                    })}

                  </Paper>
                </Grid>
              );
            })
          )
        )}
      </Grid>


      {atividadesTecnicos.length > 0 && (
        <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
          <Grid item>
            <CustomTablePagination
              count={totalDados}
              page={page}
              rowsPerPage={pageSize}
              onPageChange={mudarPagina}
              quantidadeItens={totalDados}
              onRowsPerPageChange={mudarTamanhoPagina}
            />
          </Grid>
        </Grid>
      )}

      <AtividadeDialog
        open={openDetalhesAtividade}
        handleClose={handleCloseDetalhesAtividade}
        atividade={selectedAtividade}
      />
    </>
  );
}
