import { Box, CircularProgress, Grid, IconButton, MenuItem, Select, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { useState } from 'react';

export default function CustomTablePagination({
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  quantidadeItens,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  const handleBackButtonClick = async (event) => {
    if (!isLoading && page > 0) {
      setIsLoading(true);
      await onPageChange(event, page - 1); 
      setIsLoading(false);
    }
  };

  const handleNextButtonClick = async (event) => {
    if (!isLoading && page < Math.ceil(count / rowsPerPage) - 1) {
      setIsLoading(true);
      await onPageChange(event, page + 1);
      setIsLoading(false); 
    }
  };

  const handleRowsPerPageChange = (event) => {
    onRowsPerPageChange(event, parseInt(event.target.value, 10));
  };

  return (
      <Grid container direction={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems="center" spacing={isMobile ? 2 : 0}>
        <Grid item xs={isMobile ? 12 : 'auto'}>
          <Typography variant="body2">Total: {quantidadeItens}</Typography>
        </Grid>

        <Grid item xs={isMobile ? 12 : 'auto'}>
          <Grid container justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>
              <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0 || isLoading}
                aria-label="Página anterior"
                style={{ opacity: page === 0 || isLoading ? 0.5 : 1 }} 
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                Página {page + 1} de {Math.ceil(count / rowsPerPage)}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1 || isLoading}
                aria-label="Próxima página"
                style={{ opacity: page >= Math.ceil(count / rowsPerPage) - 1 || isLoading ? 0.5 : 1 }} 
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Grid>
            {isLoading && (
              <Grid item>
                <CircularProgress size={20} />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={isMobile ? 12 : 'auto'}>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" style={{ marginRight: 8 }}>Itens por página</Typography>
            <Select
              variant="outlined"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              aria-label="Selecionar número de itens por página"
              style={{ minWidth: '80px', height: '40px' }}
            >
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={16}>16</MenuItem>
              <MenuItem value={32}>32</MenuItem>
              <MenuItem value={64}>64</MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>
  );
}
