import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent,Grid, DialogTitle, CircularProgress, Box, DialogActions,Button,IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BlueButton from '../components/button/BlueButton';
import LockIcon from '@material-ui/icons/Lock';
import TrocarSenha from './trocar-senha';
import perfilService from '../Services/Perfil/perfil-service';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 20px 15px',
        borderRadius: 10,
    },
    infoBox: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '100%',
    },
    infoItem: {
        marginBottom: theme.spacing(1),
    },
    sectionTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    blueButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 'auto',
        paddingBottom: theme.spacing(2),
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
}));

const MinhaContaDialog = ({ open, handleClose }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [perfilInfo, setPerfilInfo] = useState({});
    const [openSenha, setOpenSenha] = useState(false);

    useEffect(() => {
        async function fetchPerfilInfo() {
            setLoading(true);
            const response = await perfilService.getInformacoesTecnico();
            
            if (response.success !== false) {
                setPerfilInfo(response); 
            } else {
                console.error(response.message);
            }
    
            setLoading(false);
        }
    
        if (open) fetchPerfilInfo();
    }, [open]);

    const handleOpenSenha = () => {
        setOpenSenha(true);
    };

    const handleCloseSenha = () => {
        setOpenSenha(false);
    };

    return (
            <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
            style={{ marginTop: 45, marginBottom: 10,zIndex: 1999 }}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                handleClose()
                }
            }}
            fullWidth
            maxWidth="sm"
            >
            <DialogTitle style={{ position: 'sticky', top: 0, zIndex: 1100, backgroundColor: 'white' }}>
            <Grid container alignItems="center">
                <Grid item xs={10} sm={11}>
                Minha conta
                </Grid>
                <Grid item xs={2} sm={1}>
                <IconButton onClick={() => handleClose()}>
                    <CloseIcon />
                </IconButton>
                </Grid>
            </Grid>
            </DialogTitle>

            <DialogContent dividers>
                {loading ? (
                    <div
                        className={classes.loadingContainer}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '300px', 
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <TrocarSenha isOpen={openSenha} handleClose={handleCloseSenha} />
                        <Box>
                            <Box className={classes.infoBox}>
                                <div className={classes.sectionTitle}>Dados Pessoais</div>
                                <div className={classes.infoItem}>
                                    <strong>Nome:</strong> {perfilInfo?.nome}
                                </div>
                                <div className={classes.infoItem}>
                                    <strong>E-mail:</strong> {perfilInfo?.email}
                                </div>
                                <div className={classes.infoItem}>
                                    <strong>Telefone:</strong> {perfilInfo?.telefone1}{' '}
                                    {perfilInfo?.telefone2 != null
                                        ? '| ' + perfilInfo?.telefone2
                                        : ''}
                                </div>
                            </Box>
                            <Box
                                className={classes.infoBox}
                                style={{ marginBottom: 20 }}
                            >
                                <div className={classes.sectionTitle}>Endereço</div>
                                <div className={classes.infoItem}>
                                    <strong>Endereço:</strong> {perfilInfo?.rua},{' '}
                                    {perfilInfo?.numero}
                                </div>
                                <div className={classes.infoItem}>
                                    <strong>Bairro:</strong> {perfilInfo?.bairro}
                                </div>
                                <div className={classes.infoItem}>
                                    <strong>CEP:</strong> {perfilInfo?.cep}
                                </div>
                                <div className={classes.infoItem}>
                                    <strong>Cidade:</strong> {perfilInfo?.cidade} -{' '}
                                    {perfilInfo?.uf}
                                </div>
                            </Box>
                        </Box>

                    </>
                )}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-around' }}>
            <Button
                variant="contained"
                fullWidth
                onClick={() => handleClose()}
                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', float: 'right', height: 55 }}
            >
                Fechar
            </Button>
            <BlueButton
                variant="contained"
                color="primary"
                size="large"
                onClick={handleOpenSenha}
                startIcon={<LockIcon />}
                style={{ marginBottom: '10px', marginTop: '10px', width: '40%', float: 'right', height: 55 }}
            >
                Trocar Senha
            </BlueButton>
        </DialogActions>
        </Dialog>
    );
};

export default MinhaContaDialog;
