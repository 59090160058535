import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const GreenButton = withStyles({
  root: {
    backgroundColor: '#90EE90',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#90EE90',
      color: '#FFF',
    },
  },
})(props => <Button color="default" {...props} />);

export default GreenButton;
