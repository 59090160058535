import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Transfer } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import authService from '../../Services/auth/auth-service';
import perfilTecnicoService from '../../Services/perfilTecnico/perfil-tecnico-services';
import PermissaoService from '../../Services/permissoes/permissoes-service';
import CustomAlert from '../../components/alert/CustomAlert';
import BlueButton from '../../components/button/BlueButton';
import MenuComponent from '../../components/menu/menu-component';
import CustomTablePagination from '../../components/pagination/pagination-component';
import useDebounce from '../../hooks/useDebounce';
import MaskUtil from '../../util/MaskUtil';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
    position: 'relative',
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px',
  },
  TituloPermissao: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    textAlign: 'center',
  },
  acessoRapidoStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#1C69AD',
    fontSize: '17px',
    fontWeight: 'bold',
    textAlign: 'start',
    padding: '10px 5px',
    marginTop: '0px',
    marginBottom: '8px',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonStyle: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: '#209BDE',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1C69AD',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  searchContainer: {
    marginBottom: theme.spacing(2),
  }
}));

export default function PermissoesPage() {
  const classes = useStyles();

  const [dadosTecnico, setDadosTecnico] = useState([]);
  const [totalDados, setTotalDados] = useState(0);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
  const [alertType, setAlertType] = useState('success');
  const [alertOpen, setAlertOpen] = useState(false);
  const theme = useTheme();
  const [allPermissoes, setAllPermissoes] = useState([]);
  const [openViewPermissionsDialog, setOpenViewPermissionsDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [pesquisa, setPesquisa] = useState('');
  const [allTecnicos, setAllTecnicos] = useState([]);
  const debouncedPesquisa = useDebounce(pesquisa, 500);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedTecnico, setSelectedTecnico] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [, setTecnicosComPermissoes] = useState({});
  const [openInfoPopup, setOpenInfoPopup] = useState(false);
  const [infoMode, setInfoMode] = useState('');
  const [tecnicoPermissoes, setTecnicoPermissoes] = useState([]);
  const [openAddPermissionModal, setOpenAddPermissionModal] = useState(false);

  useEffect(() => {
    async function fetchPermissoes() {
      try {
        const response = await PermissaoService.listarPermissoes();

        if (!response.error) {
          const permissoesRecebidas = Array.isArray(response) ? response : response.data;

          const permissoesOrdenadas = permissoesRecebidas.sort((a, b) => 
            moment(b.dataCadastro).valueOf() - moment(a.dataCadastro).valueOf()
          );

          setAllPermissoes(permissoesOrdenadas || []);
          setTotalDados(response.total || permissoesOrdenadas.length || 0); 
        } else {
          setAllPermissoes([]); 
        }
      } catch (error) {
        setAllPermissoes([]);
      }
    }
    fetchPermissoes();
  }, []);

  const handleEdit = async (tecnico) => {
    if (!tecnico || !tecnico.id) {
      setAlertMessage({
        title: 'Erro!',
        body: 'Não foi possível carregar os dados do técnico.',
      });
      setAlertType('error');
      setAlertOpen(true);
      return;
    }
  
    try {
      const response = await perfilTecnicoService.buscarPermissoesTecnico(tecnico.id);
  
      if (!response.error) {
        let permissoesAtribuidas = response.permissoes || [];
  
        if (typeof permissoesAtribuidas === 'string') {
          permissoesAtribuidas = permissoesAtribuidas.split(',').map((perm) => perm.trim());
        }
  
        if (Array.isArray(permissoesAtribuidas) && permissoesAtribuidas.length > 0) {
          setSelectedKeys(permissoesAtribuidas);
        } else {
          setSelectedKeys([]);
        }
      } else {
        setSelectedKeys([]);
      }
  
      setSelectedTecnico(tecnico);
      setOpenEditModal(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        body: 'Não foi possível carregar as permissões do técnico.',
      });
      setAlertType('error');
      setAlertOpen(true);
    }
  };



  const handleAddPermission = async () => {
    if (!selectedTecnico) {
      setAlertMessage({
        title: 'Erro!',
        body: 'Selecione um técnico.',
      });
      setAlertType('error');
      setAlertOpen(true);
      return;
    }
  
    try {
      if (!selectedTecnico.id) {
        throw new Error('ID do técnico não encontrado.');
      }
  
      const response = await perfilTecnicoService.buscarPermissoesTecnico(selectedTecnico.id);
  
      if (response?.permissoes?.length > 0) {
        setAlertMessage({
          title: 'Atenção!',
          body: 'Este técnico já possui permissões atribuídas. Use a opção "Editar" para alterar as permissões.',
        });
        setAlertType('fail');
        setAlertOpen(true);
        return;
      }
  
      if (selectedPermissions.length === 0) {
        setAlertMessage({
          title: 'Erro!',
          body: 'Selecione pelo menos uma permissão.',
        });
        setAlertType('error');
        setAlertOpen(true);
        return;
      }
  
      await perfilTecnicoService.adicionarPermissaoUsuario(selectedTecnico.id, selectedPermissions);
  
      await buscarTecnicos(page, pageSize, debouncedPesquisa);
  
      setOpenAddPermissionModal(false);
      setSelectedTecnico(null);
      setSelectedPermissions([]);
  
      setAlertMessage({
        title: 'Sucesso!',
        body: 'Permissões adicionadas com sucesso.',
      });
      setAlertType('success');
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        body: error.message || 'Não foi possível adicionar as permissões.',
      });
      setAlertType('error');
      setAlertOpen(true);
    }
  };
  
  

  const handleSavePermissoes = async () => {
    try {
      await perfilTecnicoService.adicionarPermissaoUsuario(selectedTecnico.id, selectedKeys);
  
      buscarTecnicos(page, pageSize, debouncedPesquisa);
  
      setAlertMessage({
        title: 'Sucesso!',
        body: selectedKeys.length === 0 
          ? 'Todas as permissões foram removidas com sucesso.'
          : 'Permissões atualizadas com sucesso.',
      });
      setAlertType('success');
      setAlertOpen(true);
      setOpenEditModal(false);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        body: 'Não foi possível atualizar as permissões.',
      });
      setAlertType('error');
      setAlertOpen(true);
    }
  };

  const buscarTecnicos = async (pageParam, pageSizeParam, pesquisaParam) => {
    setCarregandoTable(true);
    try {
      const dados = {
        pagina: pageParam || 0,
        limite: pageSizeParam || 8,
        nome: pesquisaParam || '',
      };
  
      const userInfo = authService.getUserInfo();
      if (!userInfo || !userInfo.usuario) {
        throw new Error('Informações do usuário não disponíveis.');
      }
  
      const area = userInfo.usuario.tz_area_atendimento_microsigaid;
  
      const response = await perfilTecnicoService.listarTecnicos(area, dados);
  
      if (response.dados) {
        const tecnicosFiltrados = response.dados.filter((tecnico) =>
          tecnico.nome.toLowerCase().includes(dados.nome.toLowerCase())
        );
  
        const permissoesMap = {};
        for (const tecnico of tecnicosFiltrados) {
          const permissoesResponse = await perfilTecnicoService.buscarPermissoesTecnico(tecnico.id);
          permissoesMap[tecnico.id] =
            permissoesResponse.permissoes && permissoesResponse.permissoes.length > 0;
        }
  
        const tecnicosComPermissoes = tecnicosFiltrados.filter(tecnico => permissoesMap[tecnico.id]);

        setTecnicosComPermissoes(permissoesMap);
        setDadosTecnico(tecnicosComPermissoes);
        setTotalDados(tecnicosComPermissoes.length);
      } else {
        setDadosTecnico([]);
      }
    } catch (error) {
      console.error('Erro ao buscar técnicos:', error);
    }
    setCarregandoTable(false);
  };

  useEffect(() => {
    buscarTecnicos(page, pageSize, debouncedPesquisa);
  }, [page, pageSize, debouncedPesquisa]);

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
  };

  const mudarTamanhoPagina = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const limparPesquisa = () => {
    setPesquisa('');
  };
  const buscarTodosTecnicos = async (pageParam,pageSizeParam,pesquisaParam) => {
    try {
      const userInfo = authService.getUserInfo();
      if (!userInfo || !userInfo.usuario) {
        throw new Error('Informações do usuário não disponíveis.');
      }
      const dados = {
        pagina: pageParam || 0,
        limite: pageSizeParam || 8,
        nome: pesquisaParam || '',
      };
      const area = userInfo.usuario.tz_area_atendimento_microsigaid;
  
      const response = await perfilTecnicoService.listarTecnicos(area, dados);
      if (response.dados) {
        setAllTecnicos(response.dados);
      } else {
        setAllTecnicos([]); 
      }
    } catch (error) {
      setAllTecnicos([]); 
    }
  };
  useEffect(() => {
    buscarTodosTecnicos(); 
  }, []);
  const handleViewPermissions = async (tecnico) => {
    setSelectedTecnico(tecnico);
    try {
      const response = await perfilTecnicoService.buscarPermissoesTecnico(tecnico.id);
      let permissoes = response.permissoes;
      if (typeof permissoes === 'string') {
        permissoes = permissoes.split(',').map((perm) => perm.trim());
      }
      const permissoesComNomes = permissoes.map((codigo) => {
        const permissao = allPermissoes.find((perm) => perm.codigoPermissao === codigo);
        return permissao ? permissao.nome : codigo; 
      });
      setTecnicoPermissoes(permissoesComNomes);
    } catch (error) {
      setTecnicoPermissoes([]);
    }
    setOpenViewPermissionsDialog(true);
  };

  return (
    <>
      <MenuComponent />
      <CustomAlert
          open={alertOpen}
          message={alertMessage}
          type={alertType}
          onClose={() => setAlertOpen(false)}
      />
      <div style={{ margin: 30 }}>
        <h3 className={classes.tituloStyle}>Configurar Permissões</h3>
        <h4 className={classes.subtituloStyle}>
          <span
            onClick={() => (window.location.href = '/gestor')}
            className={classes.linkStyle}
            style={{ cursor: 'pointer' }}
          >
            Home
          </span>{' '}
          {'>'} Configurar Permissões
        </h4>
      </div>

        <div style={{ margin: 30 }}>
           <Grid container spacing={2} className={classes.searchContainer}>
            <Grid item xs={12} sm={6} md={3}>
              <BlueButton 
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                style={{height: 55 }}
                onClick={() => setOpenAddPermissionModal(true)}
                startIcon={<AddIcon />}
                >
                Cadastrar Permissão
              </BlueButton>
            </Grid>
            <Grid item xs={12} sm={6} md={5}></Grid>
                <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Pesquisar técnico"
                  variant="outlined"
                  fullWidth
                  value={pesquisa}
                  onChange={(e) => setPesquisa(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {pesquisa ? (
                          <IconButton
                            aria-label="limpar pesquisa"
                            onClick={limparPesquisa}
                            edge="end"
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          <SearchIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                </Grid>
            </Grid>
        </div>
        <div style={{ margin: 30 }}>
        <Paper className={classes.paper}>
          <Box>
 
            {carregandoTable ? (
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <Grid container spacing={3}>
                  {dadosTecnico.length > 0 ? (
                    dadosTecnico.map((tecnico) => (
                      <Grid item xs={12} sm={6} md={3} key={tecnico.id}>
                        <Paper
                          elevation={3}
                          style={{ padding: '15px', borderRadius: '8px' }}
                        >

                        <Box padding={1} style={{ flexGrow: 1 }}>
                        <Typography component="div" variant="body2"><strong>Nome Técnico:</strong> {tecnico.nome}</Typography>
                        <Typography component="div" variant="body2"><strong>Email:</strong> {tecnico.login}</Typography>
                        <Typography component="div" variant="body2"><strong>Telefone:</strong> {MaskUtil.phoneMask(tecnico.telefone)}</Typography>
                        </Box>

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <BlueButton
                                variant="contained"
                                color="primary"
                                startIcon={<VisibilityIcon />}
                                onClick={() => handleViewPermissions(tecnico)}
                                fullWidth
                                style={{ height: 55 }}
                              >
                                Ver Permissões
                              </BlueButton>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <BlueButton
                                variant="contained"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={() => handleEdit(tecnico)}
                                fullWidth
                                style={{ height: 55 }}
                              >
                                Editar Permissões
                              </BlueButton>
                          </Grid>

                          </Grid>
                        </Paper>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Box textAlign="center" p={2}>
                        Nenhum técnico encontrado.
                      </Box>
                    </Grid>
                  )}
                </Grid>

                {dadosTecnico.length > 0 && (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    style={{ marginTop: 10 }}
                  >
                    <Grid item>
                      <CustomTablePagination
                        onPageChange={mudarPagina}
                        page={page}
                        count={totalDados}
                        quantidadeItens={totalDados}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={mudarTamanhoPagina}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Box>
        </Paper>
      </div>

      <Dialog 
        open={openEditModal} 
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenEditModal(false)
          }
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        PaperProps={{
          style: {
            width: isMobile ? '95%' : '50%',
            marginTop: isMobile ? '57px' : '100px',
          },
        }}
      >
      <DialogTitle>
        {selectedTecnico ? `Técnico: ${selectedTecnico.nome}` : 'Selecione um técnico'}
        <IconButton
            onClick={() => setOpenEditModal(false)}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Tooltip title="Clique para saber como atribuir permissões" arrow>
        <IconButton
          onClick={() => {
            setOpenInfoPopup(true);
            setInfoMode('editar');
          }}
          style={{ position: 'absolute', right: 50, top: 8 }}
        >
          <InfoIcon style={{ color: '#209BDE' }} /> 
        </IconButton>
      </Tooltip>
      </DialogTitle>

        <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.TituloPermissao}>
              Permissões
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.TituloPermissao}>
              Alterar Permissões
            </Typography>
          </Grid>
        </Grid>

        <Transfer
          dataSource={allPermissoes.map((p, index) => ({
            key: `${p.codigoPermissao}-${index}`,
            title: p.nome,
            description: p.descricao,
          }))} 
          targetKeys={selectedKeys}
          onChange={(nextTargetKeys) => setSelectedKeys(nextTargetKeys)}
          render={(item) => item.title}
          listStyle={{
            width: '45%',
            height: '300px',
          }}
          showSearch
          filterOption={(inputValue, item) =>
            item.title.toLowerCase().includes(inputValue.toLowerCase())
          }
          locale={{
            itemUnit: 'item',
            itemsUnit: 'itens',
            notFoundContent: 'Nenhum dado encontrado',
            searchPlaceholder: 'Pesquisar permissões',
          }}
          style={{ padding: '10px 0px 10px 10px' }}
        />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button 
            onClick={() => setOpenEditModal(false)}
            variant="contained"
            fullWidth
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
            voltar
          </Button>
          <BlueButton 
            onClick={handleSavePermissoes} 
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
            Salvar
          </BlueButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddPermissionModal}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenAddPermissionModal(false)
          }
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        PaperProps={{
          style: {
            width: isMobile ? '95%' : '50%',
            margin: 5, 
            marginTop: isMobile ? '57px' : '85px',
          },
        }}
      >
      <DialogTitle>
        Cadastrar Permissões
        <IconButton
          onClick={() => setOpenAddPermissionModal(false)}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <Tooltip title="Clique para saber como atribuir permissões" arrow>
          <IconButton
            onClick={() => {
              setOpenInfoPopup(true);
              setInfoMode('cadastrar');
            }}
            style={{ position: 'absolute', right: 50, top: 8 }}
          >
            <InfoIcon style={{ color: '#209BDE' }} /> 
          </IconButton>
        </Tooltip>
      </DialogTitle>

        <DialogContent dividers>

          <Box mb={3}>
          <Select
            options={allTecnicos.map((tecnico) => ({
              value: tecnico.id,
              label: tecnico.nome,
            }))}
            onChange={(option) => setSelectedTecnico({ id: option.value, nome: option.label })}
            placeholder="Selecione um técnico"
            styles={{
              container: (base) => ({
                ...base,
                width: '97%', 
                marginRight: '5%',
                padding: '0px 0px 0px 10px'
              }),
            }}
          />
          </Box>
            <Grid container spacing={2}>
            <Grid item xs={6}>
            <Typography variant="h6" className={classes.TituloPermissao}>
              Selecionar Permissão
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.TituloPermissao}>
              Permissão Selecionadas
            </Typography>
          </Grid>
          </Grid>
          <Box>
              <Transfer
                dataSource={allPermissoes.map((p, index) => ({
                  key: `${p.codigoPermissao}-${index}`,
                  title: p.nome,
                  description: p.descricao,
                }))}
                targetKeys={selectedPermissions}
                onChange={(nextTargetKeys) => setSelectedPermissions(nextTargetKeys)}
                render={(item) => item.title}
                listStyle={{
                  width: '45%',
                  height: '300px',
                }}
                showSearch
                filterOption={(inputValue, item) =>
                  item.title.toLowerCase().includes(inputValue.toLowerCase())
                }
                locale={{
                  itemUnit: 'item',
                  itemsUnit: 'itens',
                  notFoundContent: 'Nenhum dado encontrado',
                  searchPlaceholder: 'Pesquisar permissões',
                }}
                style={{ padding: '10px 0px 10px 10px' }}
              />
            </Box>

        </DialogContent>

        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            variant="contained"
            fullWidth
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            onClick={() => setOpenAddPermissionModal(false)}
          >
            Voltar
          </Button>
          <BlueButton
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            onClick={handleAddPermission}
            disabled={!selectedTecnico || selectedPermissions.length === 0}
          >
            Salvar
          </BlueButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInfoPopup}
        aria-labelledby="info-dialog-title"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenInfoPopup(false)
          }
        }}
        fullWidth
        PaperProps={{
          style: {
            width: isMobile ? '95%' : '50%',
            margin: 5, 
            marginTop: isMobile ? '57px' : '0px',
          },
        }}
      >
        <DialogTitle>
          {infoMode === 'editar' ? 'Como Editar Permissões' : 'Como Atribuir Permissões'}
          <IconButton
            onClick={() => setOpenInfoPopup(false)} 
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {infoMode === 'editar' ? (
            <>
              <Typography variant="body1">
                Para editar permissões a um técnico:
              </Typography>
              <ul>
                <li>Escolha as permissões disponíveis na lista Permitir</li>
                <li>Selecione as permissões clicando encima e para permitir clique <ChevronRightIcon style={{ fontSize: '14px', color: '#4096ff' }} /></li>
                <li>Use o botão Salvar para aplicar as permissões selecionadas</li>
                <li>Caso o técnico já possua permissões, utilize a opção Editar</li>
              </ul>
            </>
          ) : (
            <>
              <Typography variant="body1">
                Para atribuir permissões a um técnico:
              </Typography>
              <ul>
                <li>Selecione o técnico no campo de seleção</li>
                <li>Escolha as permissões disponíveis na lista Permitir</li>
                <li>Selecione as permissões clicando encima e para permitir clique <ChevronRightIcon style={{ fontSize: '14px', color: '#4096ff' }} /></li>
                <li>Botão Salvar disponivel depois de selecionar o técnico e selecionar permissões</li>
                <li>Use o botão Salvar para aplicar as permissões selecionadas</li>
                <li>Caso o técnico já possua permissões, utilize a opção Editar</li>
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            fullWidth
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            onClick={() => setOpenInfoPopup(false)} 
          >
            Voltar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openViewPermissionsDialog}
        aria-labelledby="info-dialog-title"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenViewPermissionsDialog(false)
          }
        }}
        fullWidth

        PaperProps={{
          style: {
            width: isMobile ? '95%' : '50%',
            margin: 5, 
            marginTop: isMobile ? '57px' : '0px',
          },
        }}
      >
        <DialogTitle>
          Permissões de {selectedTecnico?.nome}
          <IconButton
            onClick={() => setOpenViewPermissionsDialog(false)}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {Array.isArray(tecnicoPermissoes) && tecnicoPermissoes.length > 0 ? (
            <ul style={{ fontSize: '16px' }}>
              {tecnicoPermissoes.map((permissao, index) => (
                <li key={index}>{permissao}</li>
              ))}
            </ul>
          ) : (
            <Typography>Nenhuma permissão atribuída.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenViewPermissionsDialog(false)}
            variant="contained"
            fullWidth
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    
    </>
  );
}
