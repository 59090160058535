import { Accordion, AccordionDetails, AccordionSummary, Box, ListItem, ListItemIcon, ListItemText, Paper, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FileCopy from '@material-ui/icons/FileCopy';
import AirplayIcon from '@mui/icons-material/Airplay';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContactsIcon from '@mui/icons-material/Contacts';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';
import EngineeringIcon from '@mui/icons-material/Engineering';
import InventoryIcon from '@mui/icons-material/Inventory';
import LineWeight from '@mui/icons-material/LineWeight';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ShopIcon from '@mui/icons-material/Shop';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TimelineIcon from '@mui/icons-material/Timeline';
import TodayIcon from '@mui/icons-material/Today';
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import usuarioService from '../Services/Usuario/usuario-service';
import authService from '../Services/auth/auth-service';
import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuComponent from '../components/menu/menu-component';
import EventCalendar from './components/event-calendar';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 25px 15px',
    borderRadius: 10,
    width: '100%',
  },
  paperMobile: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 5px 5px',
    borderRadius: 10,
    width: '100%',
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  acessoRapidoStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#1C69AD',
    fontSize: '17px',
    fontWeight: 'bold',
    textAlign: 'start',
    padding: '10px 5px',
    marginTop: '0px',
    marginBottom: '8px',
  },
  cardAcesso: {
    flexGrow: 0,
    flexBasis: 'calc(20% - 10px)',
    margin: '5px',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#D4EDFE',
    textAlign: 'start',
    alignItems: 'center',
    justifyContent: 'start',
    display: 'flex',
    minHeight: '60px',
    borderRadius: '10px',
    padding: '5px',
    color: '#1C69AD',
    fontWeight: 'bold',
  },
  TextoCardAcesso: {
    margin: '0',
    padding: '5px',
    color: '#1C69AD',
    fontWeight: 'bold',
    whiteSpace: 'normal'
  },
  IconeCardAcesso: {
    marginLeft: '10px',
    color: '#1C69AD',
    fontSize: '37px'
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('md')]: {
    cardAcesso: {
      flexBasis: 'calc(50% - 10px)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    cardAcesso: {
      flexBasis: 'calc(100% - 10px)',
    },
  },
}));

var menuItems = [
  {
    text: 'Clientes',
    icon: <ContactsIcon />,
    url: '/clientes',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Financeiro',
    icon: <AttachMoneyIcon />,
    url: '/financeiro',
    allowedRoles: ['tecnico'],
  },
  {
    text: 'Oportunidade',
    icon: <ShopIcon />,
    url: '/oportunidades',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Venda',
    icon: <ShoppingCartIcon />,
    url: '/venda',
    allowedRoles: ['revenda'],
  },
  {
    text: 'Gestor',
    icon: <TodayIcon />,
    url: '/gestor',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Agenda',
    icon: <DateRangeIcon />,
    url: '/agenda',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Auditoria',
    icon: <ReceiptLongIcon />,
    url: '/auditoria',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Inventário',
    icon: <InventoryIcon />,
    url: '/inventario',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Ordens de Serviço',
    icon: <ListAltIcon />,
    url: '/ordem-de-servico',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Comunicação',
    icon: <AirplayIcon />,
    url: '/comunicacao',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Notas Fiscais',
    icon: <DescriptionIcon />,
    url: '/notas-fiscais',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Meu Estoque',
    icon: <InventoryIcon />,
    url: '/estoque',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Histórico de Estoque',
    icon: <TimelineIcon />,
    url: '/historico-do-estoque',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Solicitação de Reversa',
    icon: <SettingsBackupRestoreIcon />,
    url: '/solicitacao-reversa',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Usuários',
    icon: <ApartmentIcon />,
    url: '/gestor-tecnico',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Técnicos',
    icon: <EngineeringIcon />,
    url: '/cadastro-tecnico',
    allowedRoles: ['tecnico', 'revenda'],
  },
  {
    text: 'Notícias',
    icon: <LineWeight />,
    url: '/noticias',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Manuais',
    icon: <FileCopy />,
    url: '/manuais',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Notificações',
    icon: <MarkChatUnreadIcon />,
    url: '/notificacoes',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Liberação do Estoque',
    icon: <LockOpenIcon />,
    url: '/liberacaoEstoqueGestor',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Configurar Permissões',
    icon: <PostAddTwoToneIcon />,
    url: '/tecnico-permissoes',
    allowedRoles: ['tecnico'],
  },
];

menuItems.sort((a, b) => a.text.localeCompare(b.text));

const AgendaPage = () => {
  const classes = useStyles();
  const [usuarioInfo, setUsuarioInfo] = useState(null);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const filteredMenuItems = menuItems
    .filter((item) => {
      const isGestor = usuarioInfo && usuarioInfo.gestor === true;
      const isRevenda = usuarioInfo && usuarioInfo.revenda === true;
      const isTecnico = !isGestor && !isRevenda;

      if (item.allowedRoles.includes('gestor') && isGestor) return true;
      if (item.allowedRoles.includes('revenda') && isRevenda) return true;
      if (item.allowedRoles.includes('tecnico') && isTecnico) return true;

      return false;
    })
    .filter((item) => {
      if (!usuarioEstoqueAtivo && ['Meu Estoque', 'Solicitação de Reversa', 'Notas Fiscais', 'Histórico de Estoque'].includes(item.text)) {
        return false;
      }
      return true;
    })
    .filter((item) => {
      const tecnicoPrestador = authService.getUserInfo().usuario.tecnico;
      const restrictedItemsForTecnico = ['Técnicos', 'Solicitação de Reversa', 'Histórico de Estoque', 'Financeiro', 'Notas Fiscais', 'Meu Estoque'];

      if (tecnicoPrestador && restrictedItemsForTecnico.includes(item.text)) {
        return false;
      }
      return true;
    })
    .filter((item) => {
      const permissaoTCL = authService.getUserInfo().usuario.classificacaoPrestador;
      const restrictedItemsForTCL2 = ['Manuais', 'Meu Estoque', 'Notas Fiscais', 'Histórico de Estoque', 'Solicitação de Reversa'];

      if (permissaoTCL === 2 && restrictedItemsForTCL2.includes(item.text)) {
        return false;
      }
      return true;
    });

  const buscarUsuarioEstoqueAtivo = async () => {
    const response = await usuarioService.usuarioEstoqueAtivo(authService.getUserInfo().usuario.id);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  }

  useEffect(() => {
    buscarUsuarioEstoqueAtivo();
    setUsuarioInfo(JSON.parse(localStorage.getItem('userInfo')).usuario);
  }, []);

  return (
    <>
      <MenuComponent />
      <div style={{ margin: 30 }}>
        {usuarioInfo?.revenda ? (
          <>
            <h3 className={classes.tituloStyle}>Bem-vindo!</h3>
            <h4 className={classes.subtituloStyle}>Tela Portal do Técnico</h4>
          </>
        ) : (
          <>
            <h3 className={classes.tituloStyle}>Bem-vindo!</h3>
            <h4 className={classes.subtituloStyle}>Tela inicial do Portal do Técnico</h4>
          </>
        )}


        {!usuarioInfo?.revenda && (
          <Paper className={isMobile ? classes.paperMobile : classes.paper}>
            {isMobile ? (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h4 className={classes.acessoRapidoStyle}>Acesso Rápido</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className={classes.flexContainer}>
                    {filteredMenuItems.map((item) => (
                      <ListItem
                        button
                        key={item.url}
                        onClick={() => window.location.href = item.url}
                        className={classes.cardAcesso}
                      >
                        <ListItemIcon className={classes.IconeCardAcesso}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </ListItem>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ) : (
              <Box className={classes.flexContainer}>
                {filteredMenuItems.map((item) => (
                  <ListItem
                    button
                    key={item.url}
                    onClick={() => window.location.href = item.url}
                    className={classes.cardAcesso}
                  >
                    <ListItemIcon className={classes.IconeCardAcesso}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </Box>
            )}
          </Paper>
        )}
        <Paper className={classes.paper}>
          <EventCalendar />
        </Paper>
      </div>
    </>
  );
};

export default AgendaPage;
