import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import authService from '../Services/auth/auth-service';
import clienteService from '../Services/cliente/cliente-service';
import receitaService from '../Services/cliente/receita-service';
import enderecoService from '../Services/endereco/endereco-service';
import BlueButton from '../components/button/BlueButton';
import FacebookCircularProgress from '../components/progress/CircularProgressBlue';
import useForm from '../hooks/useForm';
import MaskUtil from '../util/MaskUtil';
import ValidacaoUtil from '../util/ValidacaoUtil';


export default function CadastratoClientePage(props) {

  const [load, setLoad] = React.useState(false);
  const [mensagemErro, setMensagem] = React.useState('');
  const [mensagemSucesso, setMensagemSucesso] = React.useState(''); 


  const [open, setOpen] = React.useState(props.isOpen);
  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);


  const pesquisaCliente = async (e) => {
    try {
      const cpfcnpj = e.target.value;
      console.log('Pesquisando CPF/CNPJ:', cpfcnpj);
  
      if (ValidacaoUtil.validarCPFCNPJ(cpfcnpj)) {
        const cliente = await receitaService.pesquisaCNPJ(cpfcnpj);
        console.log('Resposta da pesquisa cliente:', cliente);
        if (!cliente) {
          setMensagem('Cliente não encontrado.');
        }
      } else {
        setMensagem('CPF/CNPJ inválido.');
      }
    } catch (error) {
      setMensagem(`Erro ao pesquisar cliente: ${error.message}`);
    }
  };

  const salvar = async () => {

    setLoad(true);

    console.log(inputs);
    let usuario = authService.getUserInfo().usuario;

    if (inputs) {
      let _validaDados = validaDados(inputs);

      if (_validaDados) {
        let telefone = String(inputs.txtTelefone).replace(/\D/g, '');
        let celular = String(inputs.txtCelular).replace(/\D/g, '');

        let cliente = {
          telefone: telefone.substr(2, telefone.length),
          celular: celular.substr(2, celular.length),
          ddtel: telefone.substr(0, 2),
          ddcel: celular.substr(0, 2),
          email: inputs.txtEmail,
          nome: inputs.txtNome,
          cep: inputs.txtCEP,
          endereco: inputs.txtEndereco,
          bairro: inputs.txtBairro,
          numero: inputs.txtNumero,
          cidade: inputs.txtCidade,
          estado: inputs.txtEstado,
          complemento: inputs.txtComplemento,
          referencia: inputs.txtReferencia,
          cpfcnpj: MaskUtil.cpfcnpjmask(inputs.txtCPFCNPJ),
          vendedorid: usuario.iD_VENDEDOR_CRM,
          AreaAtendimentoID: usuario.tz_area_atendimento_microsigaid
        };

        let cadastroCliente = await clienteService.add(JSON.stringify(cliente));

        if(cadastroCliente)
          setMensagemSucesso('Cliente cadastrado com Sucesso !!!');
        else
          setMensagem('Não foi possivel cadastrar o cliente'); 


        console.log(cadastroCliente);
      }

    }

    setLoad(false);

  }


  const handleFechar = () => {
    changeValue({
      txtCPFCNPJ: '',
      txtNome: '',
      txtTelefone: '',
      txtCelular: '', 
      txtEmail: '',
      txtCEP: '',
      txtEndereco: '',
      txtBairro: '',
      txtNumero: '',
      txtCidade: '',
      txtEstado: '',
      txtComplemento: '',
      txtReferencia: '',
    });

    props.handleClose();
  }

  const validaDados = (inputs) => {

    let campos = '';

    if (!inputs.txtCPFCNPJ)
      campos = '[CPF/CNPJ]';
    if (!inputs.txtNome)
      campos += ' [Nome]';
    if (!inputs.txtTelefone)
      campos += ' [Telefone]';
    if (!inputs.txtCelular)
      campos += ' [Celular]';
    if (!inputs.txtEmail)
      campos += ' [Email]';
    if (!inputs.txtCEP)
      campos += ' [CEP]';
    if (!inputs.txtEndereco)
      campos += ' [Endereço]';
    if (!inputs.txtBairro)
      campos += ' [Bairro]';
    if (!inputs.txtNumero)
      campos += ' [Número]';
    if (!inputs.txtCidade)
      campos += ' [Cidade]';
    if (!inputs.txtEstado)
      campos += ' [Estado]';
    if (!inputs.txtCPFCNPJ || !ValidacaoUtil.validarCPFCNPJ(MaskUtil.cpfcnpjmask(inputs.txtCPFCNPJ))) {
      campos += ' [CPF/CNPJ Inválido]';
    }


    if (campos.length > 0) {
      setMensagem(`Por favor preencher os campos -  ${campos}`);
      return false;
    }
    return true;
  }

  const pesquisaCEP = async (e) => {

    let cep = e.target.value;
    if (cep) {
      let endereco = await enderecoService.pesquisaCEP(cep);
      console.log(endereco);
      if (endereco) {

        changeValue({
          ...inputs,
          txtEndereco: endereco.logradouro,
          txtBairro: endereco.bairro,
          txtEstado: endereco.uf,
          txtCidade: endereco.localidade
        });

        console.log(inputs);
      }
    }
  }

  const { inputs, handleInputChange, handleSubmit, changeValue } = useForm(salvar);
  return (
    <div style={{ margin: 30 }}>
      <Dialog
        open={open}
        aria-labelledby="confirmation-dialog-title"
        style={{ marginTop: 35, marginBottom: 10 }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
				<Grid container alignItems="center">
					<Grid item xs={10} sm={11}>
          Cliente
					</Grid>
					<Grid item xs={2} sm={1}>
						<IconButton onClick={handleFechar}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
        <DialogContent dividers>
        <Grid container spacing={2}>

            <Grid item xs={12} sm={12}>
              <TextField
                name="txtCPFCNPJ"
                fullWidth
                placeholder="CPF/CNPJ Cliente"
                label={<span>CPF/CNPJ <span style={{ color: 'red' }}>*</span></span>}
                onBlur={pesquisaCliente}
                onChange={handleInputChange}
                value={inputs.txtCPFCNPJ}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                name="txtNome"
                placeholder="Nome Cliente"
                label={<span>Nome <span style={{ color: 'red' }}>*</span></span>}
                onChange={handleInputChange}
                value={inputs.txtNome}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputMask
                onChange={handleInputChange}
                value={inputs.txtTelefone}
                mask="(99)9999-9999"
              >
                {() => (
                  <TextField
                    fullWidth
                    name="txtTelefone"
                    label={<span>Telefone <span style={{ color: 'red' }}>*</span></span>}
                    placeholder="Telefone Fixo"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                )}
              </InputMask>
              
            </Grid>
            <Grid item xs={12} md={6}>
              <InputMask
                onChange={handleInputChange}
                value={inputs.txtCelular}
                mask="(99)99999-9999"
              >
                {() => (
                  <TextField
                    name="txtCelular"
                    fullWidth
                    label={<span>Numero Celular <span style={{ color: 'red' }}>*</span></span>}
                    placeholder="Numero Celular"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                name="txtEmail"
                fullWidth
                placeholder="E-mail"
                label={<span>Email <span style={{ color: 'red' }}>*</span></span>}
                onChange={handleInputChange}
                value={inputs.txtEmail}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                name="txtCEP"
                label={<span>CEP <span style={{ color: 'red' }}>*</span></span>}
                fullWidth
                placeholder="CEP"
                onBlur={pesquisaCEP}
                onChange={handleInputChange}
                value={inputs.txtCEP}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                name="txtEndereco"
                fullWidth
                label={<span>Endereço <span style={{ color: 'red' }}>*</span></span>}
                placeholder="Endereço"
                onChange={handleInputChange}
                value={inputs.txtEndereco}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <TextField
                name="txtNumero"
                fullWidth
                label={<span>Número <span style={{ color: 'red' }}>*</span></span>}
                placeholder="Número"
                onChange={handleInputChange}
                value={inputs.txtNumero}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="txtBairro"
                fullWidth
                label={<span>Bairro <span style={{ color: 'red' }}>*</span></span>}
                placeholder="Bairro"
                onChange={handleInputChange}
                value={inputs.txtBairro}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="txtCidade"
                fullWidth
                placeholder="Cidade"
                label={<span>Cidade <span style={{ color: 'red' }}>*</span></span>}
                onChange={handleInputChange}
                value={inputs.txtCidade}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="txtEstado"
                fullWidth
                placeholder="Estado"
                label={<span>Estado <span style={{ color: 'red' }}>*</span></span>}
                onChange={handleInputChange}
                value={inputs.txtEstado}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="txtComplemento"
                fullWidth
                placeholder="Complemento"
                label={<span>Complemento <span style={{ color: 'red' }}>*</span></span>}
                onChange={handleInputChange}
                value={inputs.txtComplemento}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                name="txtReferencia"
                fullWidth
                label={<span>Referencia <span style={{ color: 'red' }}>*</span></span>}
                placeholder="Referencia"
                onChange={handleInputChange}
                value={inputs.txtReferencia}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>

          </Grid>

        </DialogContent>

        <DialogActions style={{ justifyContent: 'space-around' }}>

          <Button onClick={handleFechar} 
            variant="contained"
            size="large"
            fullWidth
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
            Voltar
          </Button>

          <form onSubmit={handleSubmit} style={{ paddingLeft: 10, width: '40%', }} autoComplete="off">

            {!load ? 
              <BlueButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                style={{ marginBottom: '10px', marginTop: '10px', width: '100%', height: 55 }}>
              Cadastrar
              </BlueButton> :
              <FacebookCircularProgress></FacebookCircularProgress>
            }


          </form>
        </DialogActions>

        {mensagemErro.length > 0 && (<div>
          <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Erro</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {mensagemErro}

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <BlueButton onClick={() => setMensagem('')} variant="contained" color="primary">
              Ok
              </BlueButton>
            </DialogActions>
          </Dialog>
        </div>)}

        {mensagemSucesso.length > 0 && (<div>
          <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Sucesso</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {mensagemSucesso}

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <BlueButton onClick={() => setMensagemSucesso('')} variant="contained" color="primary">
              Ok
              </BlueButton>
            </DialogActions>
          </Dialog>
        </div>)}

      </Dialog>
    </div>
  );


}