import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField, Typography,
  useMediaQuery, useTheme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import BlueButton from '../../components/button/BlueButton';
import MenuComponent from '../../components/menu/menu-component';
import CustomTablePagination from '../../components/pagination/pagination-component.js';
import EstoquePage from '../../estoque/estoque-page';
import HistoricoEstoquePage from '../../estoque/historico-estoque-page.js';
import EstoqueSolicitacaReversaPage from '../../estoque/solicitacao-reversa-page';
import NotasFiscaisPage from '../../notafiscal/nota-fiscal-page';
import PerfilTecnicoPage from '../../perfilTecnico/perfil-tecnico-page';
import auditoriaService from '../../Services/Auditoria/auditoria-service';
import authService from '../../Services/auth/auth-service';
import ServicosPage from '../../servicos/servicos-page';

// Icones
import AssignmentIcon from '@material-ui/icons/Assignment';
import ClearIcon from '@material-ui/icons/Clear';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import InventoryIcon from '@mui/icons-material/Inventory';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import TimelineIcon from '@mui/icons-material/Timeline';

const useStyles = makeStyles(() => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  item: {
    marginBottom: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }
}));

export default function GestorTecnicoPage() {
  const classes = useStyles();
  const mobile = useMediaQuery(useTheme().breakpoints.down(800));
  const [tecnicos, setTecnicos] = useState([]);
  const [estoque, setEstoque] = useState(false);
  const [ordemServico, setOrdemServico] = useState(false);
  const [notaFiscal, setNotaFiscal] = useState(false);
  const [reversa, setReversa] = useState(false);
  const [solicitacaoCadastro, setSolicitacaoCadastro] = useState(false);
  const [microSigaID, setMicroSigaID] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [totalDados, setTotalDados] = useState(0);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [permissaoGestor, setPermissaoGestor] = useState(0);
  const [pesquisa, setPesquisa] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [nomeTecnico, setNomeTecnico] = useState('');
  const [loading, setLoading] = useState(true);
  const [expandedId, setExpandedId] = useState(null);
  const [idTecnico, setIdTecnico] = useState('');
  const [historicoEstoque, setHistoricoEstoque] = useState(false);

  const expandirOpcoes = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const dadosTecnicos = async (page, pageSize, pesquisa) => {
    setCarregandoTable(true);
    const dados = {
      pagina: page || 0,
      limite: pageSize || 8,
      nome: pesquisa || '',
    };

    const response = await auditoriaService.listarUsuariosGestor(dados);

    if (!response.error) {
      setTecnicos(response.dados);
      setTotalDados(response.total);
    } else {
      setTecnicos([]);
      setTotalDados(0);
    }
    setCarregandoTable(false);
  };

  const handleOrdemServico = (tz_area_atendimento_microsigaid, nomeTecnico) => {
    setOrdemServico(true);
    setShowDetails(true);
    setNomeTecnico(nomeTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const handleEstoque = (tz_area_atendimento_microsigaid, nomeTecnico, idTecnico) => {
    setEstoque(true);
    setNomeTecnico(nomeTecnico);
    setShowDetails(true);
    setIdTecnico(idTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const handleHistoricoEstoque = (tz_area_atendimento_microsigaid, nomeTecnico, idTecnico) => {
    setHistoricoEstoque(true);
    setNomeTecnico(nomeTecnico);
    setShowDetails(true);
    setIdTecnico(idTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const handleNF = (tz_area_atendimento_microsigaid, nomeTecnico, idTecnico) => {
    setNotaFiscal(true);
    setShowDetails(true);
    setNomeTecnico(nomeTecnico);
    setIdTecnico(idTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const handleReversa = (tz_area_atendimento_microsigaid, nomeTecnico, idTecnico) => {
    setReversa(true);
    setShowDetails(true);
    setNomeTecnico(nomeTecnico);
    setIdTecnico(idTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const handleSolicitacaoCadastroTecnico = (tz_area_atendimento_microsigaid, nomeTecnico) => {
    setSolicitacaoCadastro(true);
    setShowDetails(true);
    setNomeTecnico(nomeTecnico);
    setMicroSigaID(tz_area_atendimento_microsigaid);
  };

  const handleCloseOrdemServico = () => {
    setOrdemServico(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setPage(0);
    setExpandedId(null);
  };

  const handleClose = () => {
    setEstoque(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setIdTecnico('');
    setPage(0);
    setExpandedId(null);
  };

  const handleCloseHistoricoEstoque = () => {
    setHistoricoEstoque(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setPage(0);
    setIdTecnico('');
    setExpandedId(null);
  };

  const handleCloseNFGestorTecnico = () => {
    setNotaFiscal(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setIdTecnico('');
    setPage(0);
    setExpandedId(null);
  };

  const handleCloseReversaGestorTecnico = () => {
    setReversa(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setIdTecnico('');
    setPage(0);
    setExpandedId(null);
  };

  const handleCloseSolicitacaoCadastroTecnico = () => {
    setSolicitacaoCadastro(false);
    limparPesquisa();
    setShowDetails(false);
    setMicroSigaID('');
    setPage(0);
    setExpandedId(null);
  };

  const delayPesquisa = useCallback(
    debounce((nome) => {
      dadosTecnicos(0, pageSize, nome);
    }, 700), []
  );

  const pesquisarDados = (event) => {
    const nome = event.target.value;
    setPesquisa(nome);
    delayPesquisa(nome);
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    dadosTecnicos(newPage, pageSize, pesquisa);
  };

  const mudarTamanhoPagina = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setPage(0);
    dadosTecnicos(0, newSize, pesquisa);
  };

  const limparPesquisa = () => {
    setPesquisa('');
    dadosTecnicos(0, pageSize, '');
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dadosTecnicos(0, pageSize, pesquisa);
      setPermissaoGestor(authService.getUserInfo().usuario.gestor);
      setLoading(false);
    };

    fetchData();
  }, [mobile, pageSize]);

  useEffect(() => {
    return () => {
      delayPesquisa.cancel();
    };
  }, []);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {permissaoGestor ? (
        <>
          {!showDetails && (
            <>
              <MenuComponent />
              <div style={{ margin: 30 }}>
                <h3 className={classes.tituloStyle}>Usuários</h3>
                <h4 className={classes.subtituloStyle}>
                  <span
                    onClick={() => (window.location.href = '/gestor')}
                    className={classes.linkStyle}
                    style={{ cursor: 'pointer' }}
                  >
                    Home
                  </span>{' '}
                  {'>'} Usuários
                </h4>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                  <TextField
                    label="Pesquisar técnico"
                    variant="outlined"
                    value={pesquisa}
                    onChange={pesquisarDados}
                    style={{ width: mobile ? '100%' : '50%' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {pesquisa ? (
                            <IconButton onClick={limparPesquisa}>
                              <ClearIcon />
                            </IconButton>
                          ) : (
                            <SearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <Paper className={classes.paper}>
                  {carregandoTable ? (
                    <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                      <CircularProgress />
                    </Grid>
                  ) :
                    tecnicos.length === 0 ? (
                      <Grid container justifyContent="center" alignItems="center" >
                        <Typography variant="subtitle1" color="textSecondary">
                          Não há técnicos cadastrados para o gestor.
                        </Typography>
                      </Grid>
                    ) : (
                      <>
                        <Grid container spacing={1} alignItems="stretch">
                          {tecnicos.map((tecnico) => (
                            <Grid item key={tecnico.id} xs={12} sm={6} md={3}>
                              <Paper
                                style={{
                                  padding: '16px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  minHeight: '150px',
                                  boxSizing: 'border-box',
                                }}
                              >
                                <Typography component="div" variant="body2" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                  <strong>{tecnico.nome}</strong>
                                </Typography>
                                <Typography component="div" variant="body2" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                  {tecnico.login}
                                </Typography>
                                <Accordion
                                  expanded={expandedId === tecnico.id}
                                  onChange={() => expandirOpcoes(tecnico.id)}
                                  style={{ marginTop: 10 }}
                                >
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography component="div" variant="body2">Visão Gestor</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleEstoque(tecnico.tz_area_atendimento_microsigaid, tecnico.nome, tecnico.id)
                                          }
                                          startIcon={<InventoryIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                          disabled={tecnico.possuiEstoqueLiberado === false}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Estoque</span>
                                        </BlueButton>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleHistoricoEstoque(tecnico.tz_area_atendimento_microsigaid, tecnico.nome, tecnico.id)
                                          }
                                          startIcon={<TimelineIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                          disabled={tecnico.possuiEstoqueLiberado === false}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Histórico de Estoque</span>
                                        </BlueButton>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleNF(tecnico.tz_area_atendimento_microsigaid, tecnico.nome, tecnico.id)
                                          }
                                          startIcon={<AssignmentIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                          disabled={tecnico.classificacaoPrestador === 2}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Notas Fiscais</span>
                                        </BlueButton>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleOrdemServico(tecnico.tz_area_atendimento_microsigaid, tecnico.nome)
                                          }
                                          startIcon={<LocalShippingIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Ordens de Serviço</span>
                                        </BlueButton>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleReversa(tecnico.tz_area_atendimento_microsigaid, tecnico.nome, tecnico.id)
                                          }
                                          startIcon={<LockOpenIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                          disabled={tecnico.classificacaoPrestador === 2 || tecnico.possuiEstoqueLiberado === false}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Solicitação de Reversa</span>
                                        </BlueButton>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <BlueButton
                                          onClick={() =>
                                            handleSolicitacaoCadastroTecnico(tecnico.tz_area_atendimento_microsigaid, tecnico.nome)
                                          }
                                          startIcon={<GroupIcon />}
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          style={{ height: 55 }}
                                        >
                                          <span style={{ fontSize: '0.7rem' }}>Cadastro de Técnicos</span>
                                        </BlueButton>
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>


                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                          style={{ marginTop: 10 }}
                        >
                          <Grid item>
                            {tecnicos.length > 0 && (
                              <CustomTablePagination
                                onPageChange={mudarPagina}
                                page={page}
                                count={totalDados}
                                rowsPerPage={pageSize}
                                quantidadeItens={totalDados}
                                onRowsPerPageChange={mudarTamanhoPagina}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                </Paper>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <MenuComponent />
          <div style={{ margin: 30 }}>
            <h3 className={classes.tituloStyle}>Usuários</h3>
            <h4 className={classes.subtituloStyle}>
              <span
                onClick={() => (window.location.href = '/gestor')}
                className={classes.linkStyle}
                style={{ cursor: 'pointer' }}
              >
                Home
              </span>{' '}
              {'>'} Usuários
            </h4>

            <Paper className={classes.paper}>
              <Grid container justifyContent="center">
                <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>
                  Você não tem permissão para acessar esta página!
                </h2>
              </Grid>
            </Paper>
          </div>
        </>
      )
      }
      {
        ordemServico && showDetails && (
          <ServicosPage
            microSigaID={microSigaID}
            handleCloseOrdemServico={handleCloseOrdemServico}
            nomeTecnico={nomeTecnico}
          />
        )
      }
      {
        estoque && showDetails && (
          <EstoquePage
            microSigaID={microSigaID}
            handleClose={handleClose}
            nomeTecnico={nomeTecnico}
            idTecnico={idTecnico} />
        )
      }
      {
        notaFiscal && showDetails && (
          <NotasFiscaisPage
            microSigaID={microSigaID}
            handleCloseNFGestorTecnico={handleCloseNFGestorTecnico}
            nomeTecnico={nomeTecnico}
            idTecnico={idTecnico}
          />
        )
      }
      {
        reversa && showDetails && (
          <EstoqueSolicitacaReversaPage
            microSigaID={microSigaID}
            handleCloseReversaGestorTecnico={handleCloseReversaGestorTecnico}
            nomeTecnico={nomeTecnico}
            idTecnico={idTecnico}
          />
        )
      }
      {
        solicitacaoCadastro && showDetails && (
          <PerfilTecnicoPage
            microSigaID={microSigaID}
            handleCloseSolicitacaoCadastroestorTecnico={handleCloseSolicitacaoCadastroTecnico}
            nomeTecnico={nomeTecnico}
          />
        )
      }
      {
        historicoEstoque && showDetails && (
          <HistoricoEstoquePage
            microSigaID={microSigaID}
            handleCloseHistoricoEstoque={handleCloseHistoricoEstoque}
            nomeTecnico={nomeTecnico}
            idTecnico={idTecnico} />
        )
      }
    </>
  );
}
