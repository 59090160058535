import { Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import MenuComponent from '../components/menu/menu-component';



export default function HomePage()
{
  useEffect(()=>{
  },[]);

  return(
    <div>
      <MenuComponent/>
      <Container component='main'>

      </Container>
    </div>
  );


}