import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class PermissaoService {

  async listarPermissoes() {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Permissao/listar`, {
        method: 'GET',
        headers: _headerToken,
      });
  
      const data = await response.json();
      let errorsMessage = null;
  
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }
  
      if (response.status === 200) {
        return {
          error: false,
          data: data.items || data, 
          total: data.total || data.length || 0,
        };
      }
  
      return {
        error: true,
        message: data.erro || errorsMessage || 'Erro desconhecido.',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar as permissões.',
      };
    }
  }



  async criarPermissao(dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      const response = await fetch(`${_configAPITecnico.URI_API}/Permissao`, {
        method: 'POST',
        headers: {
          ..._headerToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dados),
      });
  
      const data = await response.json();
  
      if (response.status === 201 || response.status === 200) {
        return { error: false, result: data };
      }
  
      return {
        error: true,
        message: data.erro || 'Erro ao criar a permissão.',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao criar a permissão.',
      };
    }
  }

  async atualizarPermissao(permissaoId, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Permissao/${permissaoId}`, {
        method: 'PUT',
        headers: {
          ..._headerToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dados),
      });

      const data = await response.json();

      if (response.status === 200) {
        return { error: false, result: data };
      }

      return { error: true, message: data.erro || 'Erro ao atualizar a permissão.' };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao atualizar a permissão.',
      };
    }
  }

  async deletarPermissao(permissaoId) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Permissao/${permissaoId}`, {
        method: 'DELETE',
        headers: _headerToken,
      });

      if (response.status === 204) {
        return { error: false, message: 'Permissão deletada com sucesso.' };
      }

      const data = await response.json();
      return { error: true, message: data.erro || 'Erro ao deletar a permissão.' };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao deletar a permissão.',
      };
    }
  }
}

export default new PermissaoService();
