/**
 * Função para validação do formulário de vistoria
 * @param {Object} tecnico
 */
export function validateTecnico(tecnico) {
  const newErrors = {};

  // valida os campos
  newErrors.nome = !tecnico?.nome || tecnico?.nome == ''
    ? { message: 'Informe o seu nome' }
    : undefined;
  newErrors.dataNascimento = !tecnico?.dataNascimento || tecnico?.dataNascimento == ''
    ? { message: 'Informe a data de nascimento' }
    : undefined;
    newErrors.nomeMae = !tecnico?.nomeMae || tecnico?.nomeMae == ''
    ? { message: 'Informe o nome da mãe' }
    : undefined;
  newErrors.cnpj = !tecnico?.cnpj || tecnico?.cnpj == ''
    ? { message: 'Informe o CNPJ' }
    : undefined;
  newErrors.cpf = !tecnico?.cpf || tecnico?.cpf == ''
    ? { message: 'Informe o CPF' }
    : undefined;
  newErrors.documentoPessoalAnexo = tecnico?.documentoPessoalAnexo.length == 0
    ? { message: 'Selecione o documento pessoal (RG/CPF)' }
    : undefined;
  newErrors.logradouro = !tecnico?.endereco.logradouro || tecnico?.endereco.logradouro == ''
    ? { message: 'Informe o logradouro' }
    : undefined;
  newErrors.numero = !tecnico?.endereco.numero || tecnico?.endereco.numero == ''
    ? { message: 'Informe o número' }
    : undefined;
  newErrors.bairro = !tecnico?.endereco.bairro || tecnico?.endereco.bairro == ''
    ? { message: 'Informe o bairro' }
    : undefined;
  newErrors.cep = !tecnico?.endereco.cep || tecnico?.endereco.cep == ''
    ? { message: 'Informe o CEP' }
    : undefined;
  newErrors.municipio = !tecnico?.endereco.municipio || tecnico?.endereco.municipio == ''
    ? { message: 'Informe o município' }
    : undefined;
  newErrors.uf = !tecnico?.endereco.uf || tecnico?.endereco.uf == ''
    ? { message: 'Informe o estado' }
    : undefined;
  newErrors.telefone1 = !tecnico?.endereco.telefone1 || tecnico?.endereco.telefone1 == ''
    ? { message: 'Informe o telefone 1' }
    : undefined;
  newErrors.email = !tecnico?.endereco.email || tecnico?.endereco.email == ''
    ? { message: 'Informe o e-mail' }
    : undefined;
  newErrors.conhecimentoTecnico = !tecnico?.conhecimentoTecnico
    ? { message: 'Selecione a opção de formação técnica' }
    : undefined;
  newErrors.categoria = tecnico?.categoria.length == 0
    ? { message: 'Selecione a categoria do técnico' }
    : undefined;
  newErrors.certificadoAnexos = tecnico?.conhecimentoTecnico === 'true'
    && tecnico?.certificadoAnexos.length == 0
    ? { message: 'Selecione o(s) certificado(s)' }
    : undefined;
  newErrors.pontoFixoArquivos = tecnico?.categoria.includes('PontoFixo')
    && tecnico?.pontoFixoArquivos.length == 0
    ? { message: 'Selecione o(s) arquivo(s) do ponto fixo' }
    : undefined;
  newErrors.comprovanteResidenciaAnexo = tecnico?.comprovanteResidenciaAnexo.length == 0
    ? { message: 'Selecione o comprovante de residência' }
    : undefined;

  // remove campos vazios
  Object.keys(newErrors).map((key) => {
    if (!newErrors[key]) delete newErrors[key];
  });

  return newErrors;
}
