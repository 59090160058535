import {
    Box,
    CircularProgress,
    Grid,
    makeStyles,
    Paper,
    Typography
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CustomAlert from '../../components/alert/CustomAlert';
import BlueButton from '../../components/button/BlueButton';
import MenuComponent from '../../components/menu/menu-component';
import CustomTablePagination from '../../components/pagination/pagination-component';
import auditoriaService from '../../Services/Auditoria/auditoria-service';
import authService from '../../Services/auth/auth-service';
import gestorService from '../../Services/gestor/gestor-service';
import FilesExportFilterEstoque from '../../servicos/components/filesExportFilterEstoque';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 20px 25px 20px', 
        borderRadius: 10,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 10px 25px 10px', 
        },
    },
    progress: {
        color: '#209BDE',
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        marginBottom: '10px',
    },
    searchBox: {
        display: 'flex',
        gap: '10px',
        marginBottom: '20px',
        flexWrap: 'wrap',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px',
    },
    button: {
        height: '56px', 
    },
}));

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: '56px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? 'rgba(32, 155, 222, 0.1)'
            : state.isSelected
            ? 'white'
            : 'white',
        color: state.isFocused || state.isSelected ? 'black' : 'black', 
        cursor: 'pointer',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'Black',
    }),
};


export default function AgendaTecnicoPage() {
    const classes = useStyles();
    const [agenda, setAgenda] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tecnicoId, setTecnicoId] = useState('');
    const [erro, setErro] = useState(null);
    const [, setEmpresas] = useState([]);
    const [page, setPage] = useState(0);
    const [abrirAlert, setAbrirAlert] = useState(false);
    const [totalDados, setTotalDados] = useState(0);
    const [pageSize, setPageSize] = useState(8);
    const [selectedEmpresa, setSelectedEmpresa] = useState(null);
    const [opcoesTecnicos, setOpcoesTecnicos] = useState([]); 
    const [tipoAlert, setTipoAlert] = useState('error');
    const [tecnicoNome, setTecnicoNome] = useState('');
    const [buscaRealizada, setBuscaRealizada] = useState(false);
    const [mensagemAlert, setMensagemAlert] = useState('');
    const [loadingBuscar, setLoadingBuscar] = useState(false);
    const [loadingLimpar, setLoadingLimpar] = useState(false);
    const [loadingExportacao, setLoadingExportacao] = useState(false);
    const [dadosExportacao, setDadosExportacao] = useState([]);

    const StatusBadge = ({ status }) => {
        let statusText = '';
        let bgColor = '';
        let IconComponent = null;

        switch (status) {
            case 'Iniciado':
                statusText = 'Iniciado';
                bgColor = 'rgba(3, 201, 169, 1)'; 
                IconComponent = CheckCircleIcon;
                break;
            case 'NaoIniciado':
                statusText = 'Não iniciado';
                bgColor = 'rgba(255, 99, 132, 1)'; 
                IconComponent = CancelIcon;
                break;
            default:
                statusText = 'Desconhecido';
                bgColor = '#000';
                IconComponent = CancelIcon;
                break;
        }

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    padding: '8px 16px',
                    borderRadius: 15,
                    backgroundColor: bgColor,
                    marginTop: '10px',
                }}
            >
                <IconComponent style={{ marginRight: '8px', color: '#fff' }} />
                {statusText}
            </div>
        );
    };

    useEffect(() => {
        const fetchEmpresas = async () => {
            setLoading(true);
            try {
                const response = await auditoriaService.listarEmpresasGestor();
                if (!response.error && Array.isArray(response)) {
                    setEmpresas(response);

                    const opcoes = response.map((empresa) => ({
                        value: String(empresa.local_gestor_responsavelid),
                        label: empresa.nome, 
                    }));
                    setOpcoesTecnicos(opcoes);
                } else {
                    setErro(response.message || 'Erro ao buscar empresas.');
                }
            } catch (error) {
                setErro('Erro ao buscar empresas.');
            } finally {
                setLoading(false);
            }
        };
        fetchEmpresas();
    }, []);

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        buscarAgenda(newPage, pageSize);
    };

    const mudarTamanhoPagina = (event) => {
        const novoPageSize = parseInt(event.target.value, 10);
        setPageSize(novoPageSize);
        setPage(0);
        buscarAgenda(0, novoPageSize);
    };
    
    const buscarAgenda = async (currentPage = 0, currentPageSize = 8) => {
        if (!tecnicoId) {
            setMensagemAlert({
                title: 'Atenção!',
                body: 'Selecione o técnico para buscar.',
            });
            setTipoAlert('fail');
            setAbrirAlert(true);
            return;
        }

        setBuscaRealizada(true); 
        setLoadingBuscar(true); 
        setErro(null);
        try {
            const dados = {
                tecnicoId,
                tecnicoNome,
                pagina: currentPage,
                limite: currentPageSize,
            };

            const agendaData = await gestorService.buscarAgendaTecnicos(dados);

            if (agendaData.error) {
                setErro(agendaData.message || 'Erro ao buscar os dados.');
                setAgenda([]);
                setTotalDados(0);
            } else if (agendaData.dados) {
                setAgenda(agendaData.dados); 
                setTotalDados(agendaData.total || 0);
                setPage(currentPage);
            } else {
                setErro('Nenhum dado encontrado.');
                setAgenda([]);
                setTotalDados(0);
            }
        } catch (error) {
            setErro('Houve um erro ao buscar a agenda.');
            setAgenda([]);
            setTotalDados(0);
        } finally {
            setLoadingBuscar(false);
        }
    };

    const limparFiltros = async () => {
        setLoadingLimpar(true); 
        
        await new Promise((resolve) => setTimeout(resolve, 300));

        setTecnicoId('');
        setTecnicoNome('');
        setSelectedEmpresa(null);
        setPageSize(8); 
        setPage(0); 
        setBuscaRealizada(false);
        setAgenda([]);
        setTotalDados(0);
        setErro(null);

        setDadosExportacao([]);
        setLoadingLimpar(false);
    };
    useEffect(() => {
    carregarDadosExportacao();
    }, [totalDados, tecnicoId]);

    const carregarDadosExportacao = async () => {
    if (!totalDados) return; 
    
    setLoadingExportacao(true); 
    try {
        const response = await gestorService.buscarAgendaTecnicos({
        tecnicoId,
        tecnicoNome,
        pagina: 0,
        limite: totalDados,
        });

        if (response && response.dados) {
        setDadosExportacao(
            response.dados.map((item) => ({
            Atividade: item.atividadeNumero,
            Técnico: item.tecnicoNome,
            Cliente: item.clienteNome || 'Não informado',
            'Previsão Início': new Date(item.previsaoInicio).toLocaleString(),
            'Previsão Término': new Date(item.previsaoTermino).toLocaleString(),
            Status: item.inicio ? 'Iniciado' : 'Não iniciado',
            }))
        );
        } else {
        setDadosExportacao([]);
        }
    } catch (error) {
        console.error('Erro ao carregar dados de exportação:', error);
        setDadosExportacao([]);
    } finally {
        setLoadingExportacao(false);
    }
    };



    return (
        <div>
            <MenuComponent />
            <CustomAlert
                open={abrirAlert}
                message={mensagemAlert}
                type={tipoAlert}
                onClose={() => setAbrirAlert(false)}
            />
            <div style={{ margin: 30 }}>
                <h3 className={classes.tituloStyle}>Agendas dos Técnicos</h3>
                <h4 className={classes.subtituloStyle}>
                    <span
                        onClick={() => {
                            const userInfo = authService.getUserInfo();
                            if (userInfo && userInfo.usuario && userInfo.usuario.gestor) {
                                window.location.assign('/gestor');
                            } else {
                                window.location.assign('/revenda');
                            }
                        }}
                        className={classes.linkStyle}
                        style={{ cursor: 'pointer' }}
                    >
                        Home
                    </span>{' '}
                    {'>'} Agendas
                </h4>

                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ marginBottom: '10px' }}
                >
                    <Grid item xs={12} sm={3} md={3}>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <div style={{ width: '100%' }}>
                            <Select
                                options={opcoesTecnicos}
                                value={selectedEmpresa}
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setTecnicoId(String(selectedOption.value));
                                        setTecnicoNome(selectedOption.label);
                                        setSelectedEmpresa(selectedOption);
                                    } else {
                                        setTecnicoId('');
                                        setTecnicoNome('');
                                        setSelectedEmpresa(null);
                                    }
                                }}
                                placeholder="Selecione um Técnico"
                                isClearable
                                styles={customStylesSelect}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} >
                        <BlueButton
                            style={{ height: 55, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => buscarAgenda(0, pageSize)}
                            disabled={loadingBuscar || loadingLimpar} 
                        >
                            {loadingBuscar ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                <>
                                    <SearchIcon style={{ marginRight: 8 }} />
                                    Buscar
                                </>
                            )}
                        </BlueButton>
                    </Grid>
                    <Grid item xs={12} sm={3} md={1} >
                        <BlueButton
                            style={{ height: 55, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={limparFiltros}
                            disabled={loadingBuscar || loadingLimpar} 
                        >
                            {loadingLimpar ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                <>
                                    <ClearIcon style={{ marginRight: 8 }} />
                                    Limpar
                                </>
                            )}
                        </BlueButton>
                    </Grid>
                </Grid>

                <Paper className={classes.paper}>

                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="flex-end"
                        style={{ marginBottom: '10px' }}
                    >
                        <Grid item xs={12} sm={3} md={9}>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                        {loadingExportacao ? (
                        <CircularProgress size={24} />
                        ) : dadosExportacao.length > 0 ? (
                        <FilesExportFilterEstoque fileName={`Agenda_${tecnicoNome || 'Tecnico'}`} data={dadosExportacao} />
                        ) : (
                        <BlueButton 
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<DownloadIcon />}
                            disabled
                            style={{ width: '100%', height: 55 }}
                        >
                        Exportar
                        </BlueButton>
                        )}
                        </Grid>
                    </Grid>
                    {loading ? (
                        <Grid container alignItems="center" justifyContent="center">
                            <CircularProgress className={classes.progress} />
                        </Grid>
                    ) : (
                        <>
                            {erro && (
                                <Box justifyContent="center" display="flex" mb={2}>
                                    <p style={{ color: 'red' }}>{erro}</p>
                                </Box>
                            )}

                            {agenda.length > 0 ? (
                                <Grid container spacing={2} style={{ paddingBottom: 20 }}>
                                    {agenda.map((item) => (
                                        <Grid item xs={12} sm={6} md={3} key={item.atividadeID}>
                                            <Paper elevation={3} style={{ padding: '16px' }}>
                                                <Typography component="div" variant="body2" gutterBottom>
                                                    <strong>Atividade:</strong> {item.atividadeNumero}
                                                </Typography>
                                                <Typography component="div" variant="body2" gutterBottom>
                                                    <strong>Técnico:</strong> {item.tecnicoNome}
                                                </Typography>
                                                <Typography component="div" variant="body2" gutterBottom>
                                                    <strong>Cliente:</strong> {item.clienteNome || 'Não informado'}
                                                </Typography>
                                                <Typography component="div" variant="body2" gutterBottom>
                                                    <strong>Previsão Início:</strong> {new Date(item.previsaoInicio).toLocaleString()}
                                                </Typography>
                                                <Typography component="div" variant="body2">
                                                    <strong>Previsão Término:</strong> {new Date(item.previsaoTermino).toLocaleString()}
                                                </Typography>

                                                <StatusBadge status={item.inicio ? 'Iniciado' : 'NaoIniciado'} />
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                !loading && (
                                    <Box justifyContent="center" display="flex" mt={2}>
                                        <Typography variant="body1" color="textSecondary">
                                            {buscaRealizada
                                                ? tecnicoId
                                                    ? 'Nenhuma agenda encontrada para o técnico selecionado.'
                                                    : 'Selecione um Técnico para localizar a Agenda.'
                                                : 'Selecione o técnico e clique em "Buscar" para exibir as agendas disponíveis.'}
                                        </Typography>
                                    </Box>
                                )
                            )}

                            {/* Paginação sempre visível */}
                            {totalDados > 0 && (
                                <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                                    <Grid item>
                                        <CustomTablePagination
                                            count={totalDados}
                                            page={page}
                                            rowsPerPage={pageSize}
                                            quantidadeItens={totalDados}
                                            onPageChange={(event, newPage) => mudarPagina(event, newPage)} 
                                            onRowsPerPageChange={(event) => mudarTamanhoPagina(event)}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </Paper>

            </div>
        </div>
    );
}
