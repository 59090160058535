import {
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import React, { useEffect, useRef, useState } from 'react';
import CustomAlert from '../../components/alert/CustomAlert';
import BlueButton from '../../components/button/BlueButton';
import ativacaoService from '../../Services/Ativacao/ativacao-service';
import authService from '../../Services/auth/auth-service';
import CustomSelect from './CustomSelect';

export default function FormularioAtivacaoComponent({ activityId, na: propNa }) {
  const [opcaoForm, setOpcaoForm] = useState(null);
  const [numSerie, setNumSerie] = useState('');
  const [formAtivacao, setFormAtivacao] = useState(false);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [isLoadingInicio, setIsLoadingInicio] = useState(false);
  const [isLoadingPesquisar, setIsLoadingPesquisar] = useState(false);
  const [selectedTipoServico, setSelectedTipoServico] = useState(null);
  const [selectedTipoVeiculo, setSelectedTipoVeiculo] = useState(null);
  const [errors, setErrors] = useState({});
  const tecnicoID = authService?.getUserInfo()?.usuario?.id;
  const [na, setNa] = useState('');
  const isMounted = useRef(true);


  useEffect(() => {
    if (propNa) {
      setNa(propNa);
      setErrors((prevErrors) => ({ ...prevErrors, NumNA: '' }));
    }
  }, [propNa]);
  
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Definição das opções para os selects
  const tipoServicoOptions = [
    { value: 1, label: 'Instalação/Reinstalação' },
    { value: 2, label: 'Manutenção' },
    { value: 3, label: 'Troca de RI' },
    { value: 4, label: 'Desinstalação' },
    { value: 5, label: 'Instalação de acessório' },
    { value: 6, label: 'Upgrade' },
  ];

  const tipoVeiculoOptions = [
    { value: 0, label: 'Motocicleta' },
    { value: 1, label: 'Veículo de Passeio' },
    { value: 2, label: 'Caminhão' },
    { value: 3, label: 'Carreta' },
    { value: 4, label: 'Volksnet' },
  ];

  const showAlert = (title, body, type = 'error') => {
    setMensagemAlert({ title, body });
    setTipoAlert(type);
    setAbrirAlert(true);
  };

  const handlePaste = async () => {
    if (navigator.clipboard && navigator.clipboard.readText) {
      try {
        const text = await navigator.clipboard.readText();
        const isValid = /^[a-zA-Z0-9]+$/.test(text);
        if (!isValid) {
          showAlert(
            'Atenção!',
            'O número de série deve conter apenas letras e números.',
            'error'
          );
          return;
        }
        setNumSerie(text);
        // Limpar erro se existir
        setErrors((prevErrors) => ({ ...prevErrors, numSerie: '' }));
      } catch (error) {
        showAlert('Atenção!', 'Não foi possível colar!', 'error');
      }
    }
  };

  const validateFormAtivacao = () => {
    const newErrors = {};

    if (!numSerie) {
      newErrors.numSerie = 'Número de Série é obrigatório.';
    }

    // Adicione outras validações específicas, se necessário

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormAtivacao = async (event) => {
    event.preventDefault();

    if (!validateFormAtivacao()) {
      showAlert('Atenção!', 'Por favor, corrija os erros no formulário.', 'fail');
      return;
    }

    const data = new FormData(event.target);
    const numSerieForm = data.get('txtSerie');

    try {
      if (opcaoForm === 1) {
        setIsLoadingInicio(true);
        const inventarioResult = await ativacaoService.ultimoInventario(numSerieForm);

        if (inventarioResult?.status === '-1') {
          let mensagemErro = inventarioResult.erro;
          let mensagemAjustada = '';

          if (mensagemErro.toLowerCase().startsWith('nao existe inventario valido')) {
            mensagemAjustada =
              'Não existe inventário válido para o formulário de ativação enviado.';
            if (mensagemErro.includes('Tempo limite para envio do Formulario')) {
              mensagemAjustada +=
                ' O tempo para enviar outro formulário já expirou. Por favor, entre em contato com o suporte.';
            }
          }

          showAlert('Atenção!', mensagemAjustada, 'fail');
          setFormAtivacao(false);
        } else {
          setFormAtivacao(true);
          setNumSerie(numSerieForm);
        }
        setIsLoadingInicio(false);
      } else if (opcaoForm === 2) {
        setIsLoadingPesquisar(true);
        const statusResult = await ativacaoService.status(numSerieForm);

        if (statusResult?.status === 0) {
          let successMessage = statusResult.historico;
          if (successMessage.startsWith('STATUS DA ATIVAÇÃO: CONCLUIDO')) {
            successMessage = 'Status da ativação: Concluído com sucesso.';
          }
          showAlert('Sucesso!', successMessage, 'success');
        } else if (statusResult?.status === -99) {
          showAlert(
            'Status da Ativação:',
            'Divergência de Tec. entre B.I. e inventário de ativação Origem, contate o Suporte.',
            'fail'
          );
        } else {
          showAlert('Atenção!', statusResult.historico, 'fail');
        }
        setIsLoadingPesquisar(false);
      }
    } catch (error) {
      console.error('Erro inesperado:', error);
      showAlert(
        'Atenção!',
        'Ocorreu um erro inesperado. Tente novamente mais tarde.',
        'error'
      );
    }
  };

  const handleEnviaFormularioAtivacao = async (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    const dataFormAtivacao = {
      NumSerie: numSerie,
      Placa: data.get('txtPlaca'),
      Renavam: data.get('txtRenavam'),
      Chassi: data.get('txtChassi'),
      CorVeiculo: data.get('txtCorVeiculo'),
      Hodometro: data.get('txtHodometro'),
      Celular: data.get('txtCelular'),
      ICCDAnterior1: data.get('txtICCD1'),
      ICCDAnterior2: data.get('txtICCD2'),
      NumSerieAnterior: data.get('txtNumSerieAnterior'),
      NumAntenaAnterior: data.get('txtNumAntena'),
      ModeloVeiculo: data.get('txtModeloVeiculo'),
      TipoServicoAtivacao: selectedTipoServico ? selectedTipoServico.value : '',
      TipoVeiculo: selectedTipoVeiculo ? selectedTipoVeiculo.value : '',
      NumNA: na,
      ActivityID: activityId,
      TecnicoID: tecnicoID,
    };

    // Validações
    const newErrors = {};

    if (!dataFormAtivacao.Placa) {
      newErrors.Placa = 'É obrigatório o preenchimento da placa.';
    }

    if (!dataFormAtivacao.TipoServicoAtivacao) {
      newErrors.TipoServicoAtivacao = 'É obrigatório o preenchimento do tipo de serviço.';
    }

    if (!dataFormAtivacao.TipoVeiculo) {
      newErrors.TipoVeiculo = 'É obrigatório o preenchimento do tipo de veículo.';
    }

    if (!dataFormAtivacao.NumNA) {
      newErrors.NumNA = 'É obrigatório o preenchimento do NA.';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      showAlert('Atenção!', 'Por favor, corrija os erros no formulário.', 'fail');
      return;
    }

    setIsLoadingPesquisar(true);
    try {
      showAlert('Sucesso!', 'Formulário enviado com sucesso!', 'success');
      // Opcional: Resetar o formulário ou estados
      setFormAtivacao(false);
      setSelectedTipoServico(null);
      setSelectedTipoVeiculo(null);
      setErrors({});
    } catch (error) {
      showAlert('Erro!', 'Erro ao enviar o formulário. Tente novamente.', 'error');
    } finally {
      setIsLoadingPesquisar(false);
    }
  };

  return (
    <Container style={{ padding: '5px' }}>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      <form onSubmit={handleFormAtivacao}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={3}>
            <TextField
              name="txtSerie"
              label={
                <span>
                  Número de Série <span style={{ color: 'red' }}>*</span>
                </span>
              }
              variant="outlined"
              fullWidth
              value={numSerie}
              onChange={(e) => setNumSerie(e.target.value)}
              onInput={(e) => {
                const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                setNumSerie(value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Colar número de série">
                    <IconButton
                      onClick={handlePaste}
                      edge="end"
                      aria-label="Colar número de série"
                    >
                      <ContentPasteIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
              error={!!errors.numSerie}
              helperText={errors.numSerie}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={3}>
            <BlueButton
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              style={{ height: 55 }}
              onClick={() => setOpcaoForm(1)}
              startIcon={
                isLoadingInicio ? <CircularProgress size={20} /> : <RefreshIcon />
              }
              disabled={isLoadingInicio}
            >
              <span style={{ fontSize: '0.7rem' }}>
                {isLoadingInicio ? ' INICIANDO...' : ' INICIAR ATIVAÇÃO'}
              </span>
            </BlueButton>
          </Grid>
          <Grid item xs={12} md={5} lg={3}>
            <BlueButton
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              style={{ height: 55 }}
              onClick={() => setOpcaoForm(2)}
              startIcon={
                isLoadingPesquisar ? <CircularProgress size={20} /> : <SearchIcon />
              }
              disabled={isLoadingPesquisar}
            >
              <span style={{ fontSize: '0.7rem' }}>
                {isLoadingPesquisar ? ' PESQUISANDO...' : ' PESQUISAR ATIVAÇÃO'}
              </span>
            </BlueButton>
          </Grid>
        </Grid>
      </form>

      {formAtivacao && (
        <form onSubmit={handleEnviaFormularioAtivacao}>
          <Grid container spacing={3} style={{ marginTop: 10 }}>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.TipoServicoAtivacao}
              >
                <CustomSelect
                  options={tipoServicoOptions}
                  value={selectedTipoServico}
                  label={
                    <span>
                      Serviço <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  onChange={setSelectedTipoServico}
                  placeholder="Selecione o Tipo de Serviço"
                  isClearable
                />
                {errors.TipoServicoAtivacao && (
                  <span style={{ color: 'red', fontSize: '0.75rem' }}>
                    {errors.TipoServicoAtivacao}
                  </span>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.TipoVeiculo}
              >
                <CustomSelect
                  options={tipoVeiculoOptions}
                  value={selectedTipoVeiculo}
                  onChange={setSelectedTipoVeiculo}
                  placeholder="Selecione o Tipo de Veículo"
                  isClearable
                />
                {errors.TipoVeiculo && (
                  <span style={{ color: 'red', fontSize: '0.75rem' }}>
                    {errors.TipoVeiculo}
                  </span>
                )}
              </FormControl>
            </Grid>
            {/* Outros Campos do Formulário */}
            <Grid item xs={12} md={4}>
              <TextField
                name="txtPlaca"
                label={
                  <span>
                    Placa <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.Placa}
                helperText={errors.Placa}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtRenavam"
                label="Renavam"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtChassi"
                label="Chassi"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtCorVeiculo"
                label="Cor do Veículo"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtModeloVeiculo"
                label="Modelo do Veículo"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtCelular"
                label="Celular"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtICCD1"
                label="ICCD1 Anterior"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtICCD2"
                label="ICCD2 Anterior"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtNumSerieAnterior"
                label="Número de Série Anterior"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                name="txtNumAntena"
                label="Número Antena Anterior"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtHodometro"
                label="Hodômetro"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="txtNA"
                label={
                  <span>
                    NA <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                variant="outlined"
                fullWidth
                value={na} // Usando o estado 'na'
                disabled 
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.NumNA}
                helperText={errors.NumNA}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
            <Grid item xs={12} sm={3} style={{ textAlign: 'right', marginTop: '20px' }}>
              <BlueButton
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
                style={{ height: 55 }}
                startIcon={
                  isLoadingPesquisar ? <CircularProgress size={20} /> : <SendIcon />
                }
                disabled={isLoadingPesquisar}
              >
                <span style={{ fontSize: '0.7rem' }}>
                  {isLoadingPesquisar ? ' ENVIANDO...' : ' ENVIAR'}
                </span>
              </BlueButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Container>
  );
}
