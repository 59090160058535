import { FormControl, FormHelperText, Grid, Select } from '@material-ui/core';
import React from 'react';

export function VistoriaItems({
  vistoria,
  handleInputChange,
  classes,
  errors,
  disabledEditExit,
}) {
  return (
    <>
      <div className={classes.subtitle}>Itens - entrada / saída</div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Lanterna Traseira / Setas</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.lanternas_traseiras}>
              <Select
                native
                disabled
                id="drpLanTraseira"
                value={vistoria?.lanternas_traseiras ?? ''}
                onChange={(event) =>
                  handleInputChange('lanternas_traseiras', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.lanternas_traseiras && (
                <FormHelperText>{errors.lanternas_traseiras.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="drpLanTraseiraSaida"
                  disabled
                  value={vistoria?.lanternas_traseiras_saida || vistoria?.lanternas_traseiras || ''}
                  onChange={(event) =>
                    handleInputChange('lanternas_traseiras_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Lanterna Dianteira / Setas</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.lanternas_dianterias}>
              <Select
                native
                disabled
                id="select_lanternas_dianterias"
                value={vistoria?.lanternas_dianterias ?? ''}
                onChange={(event) =>
                  handleInputChange('lanternas_dianterias', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.lanternas_dianterias && (
                <FormHelperText>{errors.lanternas_dianterias.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_lanternas_dianterias_saida"
                  disabled
                  value={vistoria?.lanternas_dianterias_saida || vistoria?.lanternas_dianterias || ''}
                  onChange={(event) =>
                    handleInputChange('lanternas_dianterias_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Luz de Freio / Brake Light</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.luz_freio}>
              <Select
                native
                disabled
                id="select_luz_freio"
                value={vistoria?.luz_freio ?? ''}
                onChange={(event) =>
                  handleInputChange('luz_freio', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.luz_freio && (
                <FormHelperText>{errors.luz_freio.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_luz_freio_saida"
                  disabled
                  value={vistoria?.luz_freio_saida || vistoria?.luz_freio || ''}
                  onChange={(event) =>
                    handleInputChange('luz_freio_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Luz de Ré</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.luz_re}>
              <Select
                native
                disabled
                id="select_luz_re"
                value={vistoria?.luz_re ?? ''}
                onChange={(event) => handleInputChange('luz_re', event.target.value)}
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.luz_re && (
                <FormHelperText>{errors.luz_re.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_luz_re_saida"
                  disabled
                  value={vistoria?.luz_re_saida || vistoria?.luz_re || ''}
                  onChange={(event) => handleInputChange('luz_re_saida', event.target.value)}
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Pisca Alerta</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.pisca_alerta}>
              <Select
                native
                disabled
                id="select_pisca_alerta"
                value={vistoria?.pisca_alerta ?? ''}
                onChange={(event) => handleInputChange('pisca_alerta', event.target.value)}
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.pisca_alerta && (
                <FormHelperText>{errors.pisca_alerta.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_pisca_alerta_saida"
                  disabled
                  value={vistoria?.pisca_alerta_saida || vistoria?.pisca_alerta || ''}
                  onChange={(event) => handleInputChange('pisca_alerta_saida', event.target.value)}
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Farois Alto e Baixo</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.farol_baixo}>
              <Select
                native
                disabled
                id="select_farol_baixo"
                value={vistoria?.farol_baixo ?? ''}
                onChange={(event) => handleInputChange('farol_baixo', event.target.value)}
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.farol_baixo && (
                <FormHelperText>{errors.farol_baixo.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_farol_baixo_saida"
                  disabled
                  value={vistoria?.farol_baixo_saida || vistoria?.farol_baixo || ''}
                  onChange={(event) => handleInputChange('farol_baixo_saida', event.target.value)}
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Vidros Portas Tras.</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.vidros_porta_traseira}>
              <Select
                native
                disabled
                id="select_vidros_porta_traseira"
                value={vistoria?.vidros_porta_traseira ?? ''}
                onChange={(event) =>
                  handleInputChange('vidros_porta_traseira', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.vidros_porta_traseira && (
                <FormHelperText>{errors.vidros_porta_traseira.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_vidros_porta_traseira_saida"
                  disabled
                  value={vistoria?.vidros_porta_traseira_saida || vistoria?.vidros_porta_traseira || ''}
                  onChange={(event) =>
                    handleInputChange('vidros_porta_traseira_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Vidros Portas Diant.</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.vidros_portas_dianteiras}>
              <Select
                native
                disabled
                id="select_vidros_portas_dianteiras"
                value={vistoria?.vidros_portas_dianteiras ?? ''}
                onChange={(event) =>
                  handleInputChange('vidros_portas_dianteiras', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.vidros_portas_dianteiras && (
                <FormHelperText>{errors.vidros_portas_dianteiras.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_vidros_portas_dianteiras_saidas"
                  disabled
                  value={vistoria?.vidros_portas_dianteiras_saidas || vistoria?.vidros_portas_dianteiras || ''}
                  onChange={(event) =>
                    handleInputChange('vidros_portas_dianteiras_saidas', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Vidro Traseiro</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.vidro_traseiro}>
              <Select
                native
                disabled
                id="select_vidro_traseiro"
                value={vistoria?.vidro_traseiro ?? ''}
                onChange={(event) =>
                  handleInputChange('vidro_traseiro', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.vidro_traseiro && (
                <FormHelperText>{errors.vidro_traseiro.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_vidro_traseiro_saida"
                  disabled={disabledEditExit}
                  value={vistoria?.vidro_traseiro_saida || vistoria?.vidro_traseiro || ''}
                  onChange={(event) =>
                    handleInputChange('vidro_traseiro_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Retrovisor Motorista e Carona</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.retrovisor}>
              <Select
                native
                disabled
                id="select_retrovisor"
                value={vistoria?.retrovisor ?? ''}
                onChange={(event) =>
                  handleInputChange('retrovisor', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.retrovisor && (
                <FormHelperText>{errors.retrovisor.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_retrovisor_saida"
                  disabled
                  value={vistoria?.retrovisor_saida || vistoria?.retrovisor || ''}
                  onChange={(event) =>
                    handleInputChange('retrovisor_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Bancos, Estofados e Tapetes</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.bancos}>
              <Select
                native
                disabled
                id="select_bancos"
                value={vistoria?.bancos ?? ''}
                onChange={(event) =>
                  handleInputChange('bancos', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.bancos && (
                <FormHelperText>{errors.bancos.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_bancos_saida"
                  disabled
                  value={vistoria?.bancos_saida || vistoria?.bancos || ''}
                  onChange={(event) =>
                    handleInputChange('bancos_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Buzina</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.buzina}>
              <Select
                native
                disabled
                id="select_buzina"
                value={vistoria?.buzina ?? ''}
                onChange={(event) =>
                  handleInputChange('buzina', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.buzina && (
                <FormHelperText>{errors.buzina.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_buzina_saida"
                  disabled
                  value={vistoria?.buzina_saida || vistoria?.buzina || ''}
                  onChange={(event) =>
                    handleInputChange('buzina_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Condições do Painel</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.cond_painel}>
              <Select
                native
                disabled
                id="select_cond_painel"
                value={vistoria?.cond_painel ?? ''}
                onChange={(event) =>
                  handleInputChange('cond_painel', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.cond_painel && (
                <FormHelperText>{errors.cond_painel.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_cond_painel_saida"
                  disabled
                  value={vistoria?.cond_painel_saida || vistoria?.cond_painel || ''}
                  onChange={(event) =>
                    handleInputChange('cond_painel_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Outros Equipamentos</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.outros_equipamentos}>
              <Select
                native
                disabled
                id="select_outros_equipamentos"
                value={vistoria?.outros_equipamentos ?? ''}
                onChange={(event) =>
                  handleInputChange('outros_equipamentos', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.outros_equipamentos && (
                <FormHelperText>{errors.outros_equipamentos.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_outros_equipamentos_saida"
                  disabled
                  value={vistoria?.outros_equipamentos_saida || vistoria?.outros_equipamentos || ''}
                  onChange={(event) =>
                    handleInputChange('outros_equipamentos_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Velocímetro</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.velocimetro}>
              <Select
                native
                disabled
                id="select_velocimetro"
                value={vistoria?.velocimetro ?? ''}
                onChange={(event) =>
                  handleInputChange('velocimetro', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.velocimetro && (
                <FormHelperText>{errors.velocimetro.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_velocimetro_saida"
                  disabled
                  value={vistoria?.velocimetro_saida || vistoria?.velocimetro || ''}
                  onChange={(event) =>
                    handleInputChange('velocimetro_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Computador de Bordo</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.computador_bordo}>
              <Select
                native
                disabled
                id="select_computador_bordo"
                value={vistoria?.computador_bordo ?? ''}
                onChange={(event) =>
                  handleInputChange('computador_bordo', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.computador_bordo && (
                <FormHelperText>{errors.computador_bordo.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_computador_bordo_saida"
                  disabled
                  value={vistoria?.computador_bordo_saida || vistoria?.computador_bordo || ''}
                  onChange={(event) =>
                    handleInputChange('computador_bordo_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Limpador de Vidro Dianteiro e Traseiro</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.limpador_dianteiro}>
              <Select
                native
                disabled
                id="select_limpador_dianteiro"
                value={vistoria?.limpador_dianteiro ?? ''}
                onChange={(event) =>
                  handleInputChange('limpador_dianteiro', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.limpador_dianteiro && (
                <FormHelperText>{errors.limpador_dianteiro.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_limpador_dianteiro_saida"
                  disabled
                  value={vistoria?.limpador_dianteiro_saida || vistoria?.limpador_dianteiro || ''}
                  onChange={(event) =>
                    handleInputChange('limpador_dianteiro_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Acendedor de Cigarro</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.acendedor_cigarro}>
              <Select
                native
                disabled
                id="select_acendedor_cigarro"
                value={vistoria?.acendedor_cigarro ?? ''}
                onChange={(event) =>
                  handleInputChange('acendedor_cigarro', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.acendedor_cigarro && (
                <FormHelperText>{errors.acendedor_cigarro.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_acendedor_cigarro_saida"
                  disabled
                  value={vistoria?.acendedor_cigarro_saida || vistoria?.acendedor_cigarro || ''}
                  onChange={(event) =>
                    handleInputChange('acendedor_cigarro_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs={12} sm={6} container>
          <span className={classes.itemText}>Sistema de Som</span>
        </Grid>
        <Grid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={vistoria?.entrada ? 6 : 12}>
            <FormControl variant="outlined" fullWidth error={!!errors.sistema_som}>
              <Select
                native
                disabled
                id="select_sistema_som"
                value={vistoria?.sistema_som ?? ''}
                onChange={(event) =>
                  handleInputChange('sistema_som', event.target.value)
                }
              >
                <optgroup>
                  <option value='' disabled hidden />
                  <option value={'CF'}>Conforme</option>
                  <option value={'NC'}>Não conforme</option>
                  <option value={'NP'}>Não possui</option>
                </optgroup>
              </Select>
              {errors.sistema_som && (
                <FormHelperText>{errors.sistema_som.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {vistoria?.entrada && (
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  id="select_sistema_som_saida"
                  disabled
                  value={vistoria?.sistema_som_saida || vistoria?.sistema_som || ''}
                  onChange={(event) =>
                    handleInputChange('sistema_som_saida', event.target.value)
                  }
                >
                  <optgroup>
                    <option value='' disabled hidden />
                    <option value={'CF'}>Conforme</option>
                    <option value={'NC'}>Não conforme</option>
                    <option value={'NP'}>Não possui</option>
                  </optgroup>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}