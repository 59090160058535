import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logoOmnilink from '../../../src/assets/img/logoOmni.png';
import { AvlLatariaVistoria } from './AvlLatariaVistoria';
import { InformacoesVeiculo } from './InformacoesVeiculo';
import { VistoriaEntrada } from './VistoriaEntrada';
import { VistoriaSaida } from './VistoriaSaida';

import { Box, CircularProgress, Grid, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BlueButton from '../../components/button/BlueButton';

import CheckIcon from '@material-ui/icons/Check';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@mui/icons-material/Close';
import vistoriaService from '../../Services/Vistoria/vistoria-service';
import { InformacoesVistoria } from './InformacoesVistoria';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  subTitle: {
    width: '100%',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 25,
    marginBottom: 20,
  },
  attention: {
    marginTop: 30,
    width: '100%',
    padding: 10,
    border: '1px solid #d3d3d3',
    textAlign: 'center',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 30,
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    maxWidth: 350
  }
}));

export function EmailVistoriaCliente({ vistoria, os }) {
  const classes = useStyles();
  const history = useHistory();
  const [status, setStatus] = useState('');
  const [mensagemFeedback, setMensagemFeedback] = useState('');

  const [loading, setLoading] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);

  useEffect(() => {
    const checkStatusOnLoad = async () => {

      // if (vistoria.statusEntrada === 'Aceito' && !vistoria.dataEnvioEmailDeSaida) {
      //   history.push('/aprovado');
      // }
      // else if (vistoria.statusSaida === 'Aceito') {
      //   history.push('/aprovado');
      // }
    };

    checkStatusOnLoad();
  }, [vistoria]);

  const handleAceitarVistoria = async () => {
    try {
      setLoading(true);

      const { id_agendamento, id_ordem_servico, checkListTipo } = vistoria;

      if (checkListTipo === 'Entrada') {
        const aceitarEntradaResponse = await vistoriaService.aceitarEntrada(id_agendamento, id_ordem_servico);

        if (!aceitarEntradaResponse?.error) {
          const atualizaChecklistTipo = await vistoriaService.updateVistoria(id_agendamento, id_ordem_servico, {
            ...vistoria, aprovado: true, statusEntrada: 'Aceito'
          });

          if (!atualizaChecklistTipo?.error) {
            console.log('Não foi possível atualizar vistoria, tente novamente.');
          }
          setStatus('aceita');
          setMensagemFeedback('Vistoria aceita com sucesso!');

        } else {
          alert('Não foi possível aceitar vistoria, tente novamente.');
          setMensagemFeedback('Não foi possível aceitar vistoria, tente novamente.');
        }
      } else {
        const aceitarSaidaResponse = await vistoriaService.aceitarSaida(id_agendamento, id_ordem_servico);

        if (aceitarSaidaResponse?.error) {
          setMensagemFeedback('Não foi possível aceitar vistoria, tente novamente.');
        } else {
          setStatus('aceita');
          setMensagemFeedback('Vistoria aceita com sucesso!');
        }
      }

      setLoading(false);
      setOpenCheck(true);
    } catch (error) {
      setLoading(false);
      console.error('An error occurred:', error.message);
      setMensagemFeedback('Não foi possível aceitar vistoria, tente novamente.');
    }
  };

  const handleRecusarVistoria = async () => {
    setLoading(true);

    if (vistoria?.checkListTipo === 'Entrada') {
      const response = await vistoriaService.recusarEntrada(
        vistoria?.id_agendamento,
        vistoria?.id_ordem_servico
      );
      setLoading(false);

      if (!response?.error) {
        setStatus('recusada');
        setMensagemFeedback('Vistoria recusada com sucesso!');
      } else {
        setMensagemFeedback('Não foi possível recusar vistoria, tente novamente.');
      }

      setOpenCheck(true);
    } else {
      const response = await vistoriaService.recusarSaida(
        vistoria?.id_agendamento,
        vistoria?.id_ordem_servico
      );
      setLoading(false);

      if (!response?.error) {
        setStatus('recusada');
        setMensagemFeedback('Vistoria recusada com sucesso!')
      } else {
        setMensagemFeedback('Não foi possível recusar vistoria, tente novamente.');
      }
      setOpenCheck(true);
    }
  };

  const formatarData = (data) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
    const dataFormatada = new Date(data).toLocaleString('pt-BR', options);
    return `${dataFormatada.substr(0, 10)} às ${dataFormatada.substr(12)}.`;
  };

  return (
    <>
      <div className={classes.paper}>

        <img style={{ paddingTop: 5 }} src={logoOmnilink}></img>

        {vistoria.statusEntrada === 'Aceito' && vistoria.checkListTipo === 'Entrada' || vistoria.statusSaida === 'Aceito' && vistoria.checkListTipo === 'Saida' ? (
          <div className={classes.paper}>
            A vistoria foi aprovada com sucesso, muito obrigado!
            <br />
            <br />
            <br />
          </div>
        ) : null}

        <InformacoesVistoria vistoria={vistoria} os={os} />

        {vistoria?.checkListTipo === 'Saída' ? (
          <VistoriaSaida vistoria={vistoria} />
        ) : (
          <>
            <InformacoesVeiculo vistoria={vistoria} />

            <AvlLatariaVistoria vistoria={vistoria} classes={classes} />

            <VistoriaEntrada vistoria={vistoria} />
          </>
        )}

        <span className={classes.subTitle} style={{ marginTop: 30 }}>
          Observações
        </span>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {vistoria.observacao || ' - '}
          </Grid>
        </Grid>


        <span className={classes.attention}>
          <strong>ATENÇÃO</strong>
          <p style={{ textAlign: 'justify' }}>
            Prezado cliente, por favor, esteja atento ao checklist que o técnico preencherá antes do início da instalação, validando partes mecânicas e elétricas do veículo, garantindo que tudo esteja em bom funcionamento.
            <br />
            A Omnilink não se responsabiliza por objetos pessoais deixados no interior do veículo durante o serviço. Solicitamos a retirada dos objetos antes do início do serviço.
          </p>
        </span>

        <Box className={classes.buttonGroup}>
          <BlueButton
            disabled={vistoria.statusEntrada === 'Aceito' && vistoria.checkListTipo === 'Entrada' || vistoria.statusSaida === 'Aceito' && vistoria.checkListTipo === 'Saida'}
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            onClick={handleAceitarVistoria}
          >
            {loading ? (
              <CircularProgress size={25} />
            ) : (
              <>
                <CheckIcon /> ACEITAR VISTORIA DE{' '}
                {vistoria.checkListTipo === 'Entrada' ? 'ENTRADA' : 'SAÍDA'}
              </>
            )}
          </BlueButton>

          <BlueButton
            disabled={vistoria.checkListTipo === 'Entrada' && vistoria.statusEntrada != null || vistoria.checkListTipo === 'Saida' && vistoria.statusSaida != null}
            type="submit"
            variant="contained"
            color="error"
            fullWidth
            size="large"
            onClick={handleRecusarVistoria}
          >
            {loading ? (
              <CircularProgress size={25} />
            ) : (
              <>
                <CloseIcon /> RECUSAR VISTORIA DE {' '}
                {vistoria.checkListTipo === 'Entrada' ? 'ENTRADA' : 'SAÍDA'}
              </>
            )}
          </BlueButton>
        </Box>

        <Grid item xs={12} style={{ marginTop: 30 }}>
          Data da Vistoria: {formatarData(vistoria.data_cadastro)}
        </Grid>
      </div>

      <Box mt={20}>
        <Snackbar
          open={openCheck}
          autoHideDuration={1500}
          onClose={() => {
            if (status === 'aceita') {
              history.push('/aprovado');
            } else if (status === 'recusada') {
              history.push('/recusado');
            }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert variant="filled" severity="info">
            {mensagemFeedback}
          </Alert>
        </Snackbar>
      </Box>
    </>
  )
}
