import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import 'moment/locale/pt-br';
import '../agenda/components/react-big-calendar.css';

import Grid from '@material-ui/core/Grid';
import MenuComponent from '../components/menu/menu-component';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function VistoriaGestorPage() {
  const rows = [
    { name: 'John Doe', status: 'Ativo', city: 'New York' },
    { name: 'Jane Smith', status: 'Suspenso', city: 'Los Angeles' },
    // Add more rows as needed
  ];
  const classes = useStyles();
  return (
    <>
      <MenuComponent />

      <Container component="main" style={{ paddingBottom: 40 }}>
        <Grid container spacing={2} lg={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Técnico</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Agenda</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.agenda}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Container>
    </>
  );
}
