import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomAlert from '../../components/alert/CustomAlert';
import BlueButton from '../../components/button/BlueButton';
import authService from '../../Services/auth/auth-service';
import ordemServicoServico from '../../Services/OrdemServico/ordem-servico-servico';
import DataUtils from '../../util/dataUtils';
import { AgendamentoDialog } from './agendamento-dialog';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    marginTop: 37,
    height: 465,
  },
  cardHeader: {
    backgroundColor: '#209BDE',
    color: 'white',
    fontSize: '16px',
  },
  listItem: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  scrollableList: {
    maxHeight: 400,
    overflowY: 'auto',
  },
  title: {
    fontWeight: 'bold',
  },
  value: {
    fontWeight: 'normal',
  },
  status: {
    textAlign: 'center',
    display: 'block',
    borderRadius: 5,
    color: 'white',
    width: 'fit-content',
    padding: '5px',
  },
}));

const DailyAgenda = ({ eventos, dataSelecionada, loading }) => {
  const classes = useStyles();
  const history = useHistory();
  const [atividadeServico, setAtividadeServico] = useState(null);
  const [openDialogConfirmaInicioAtividade, setOpenDialogConfirmaInicioAtividade] = useState(false);
  const [tipoAlert, setTipoAlert] = React.useState('error');
  const [abrirAlert, setAbrirAlert] = React.useState(false);
 
  const [mensagemAlert, setMensagemAlert] = React.useState('');
  
  const [isTCL, setIsTCL] = useState(false);

  useEffect(() => {
    const userInfo = authService.getUserInfo();
    if (userInfo && userInfo.usuario) {
      setIsTCL(userInfo.usuario.classificacaoPrestador === 2);
    }
  }, []);

  const fechaDetalhesAtividade = () => {
    setAtividadeServico(null);
  };

  
  const handleOrdemServico = () => {
    setOpenDialogConfirmaInicioAtividade(true);
  };

  const handleDetalhesOrdemServico = () => {
    history.push({
      pathname: `/ordemservico/${atividadeServico.na}`,
    });
  };


  const handleDetalheAtividade = (evento) => {
    const possuiOs = evento.os; 

    if (!possuiOs && !isTCL) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Não foi possível obter número da Ordem de Serviço! Entre em contato com suporte, você será direcionado a pagina principal.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }
    setAtividadeServico(evento);
  };
  
  const handleIniciarAtividadeDeServico = async () => {
    const iniciaAtividadeDeServico = await ordemServicoServico.iniciarAtividadeDeServico(
      atividadeServico.atividadeID,
    );

    if (iniciaAtividadeDeServico) {
      if (iniciaAtividadeDeServico.erro) {
        setMensagemAlert({
          title: 'Atenção!',
          body: 'Erro ao iniciar atividade de serviço!'
        });
        setTipoAlert('fail');
        setAbrirAlert(true);
      } else {
        history.push({
          pathname: `/ordemservico/${atividadeServico.na}`,
        });
      }
    } else {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'A atividade já foi iniciada!'
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
    }
  };


  const getStatusStyle = (status) => {
    const statusStyles = {
      0: {
        background: '#3174ad',
        label: 'Agendado',
      },
      1: {
        background: '#73ae2e',
        label: 'Concluído',
      },
      2: {
        background: 'red',
        label: 'Cancelado',
      },
      3: {
        background: '#3174ad',
        label: 'Agendado',
      },
    };

    return statusStyles[status] || statusStyles.default;
  };

  return (
    <>
      <CustomAlert
          open={abrirAlert}
          message={mensagemAlert}
          type={tipoAlert}
          onClose={() => setAbrirAlert(false)}
      />


      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Typography>
              Atividades da Agenda - {dataSelecionada.toLocaleDateString('pt-BR')}
            </Typography>
          }
        />
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Divider />
            {eventos.length === 0 ? (
              <Typography variant="body2" style={{ padding: '16px' }}>
                Não foram encontradas atividades nesse período.
              </Typography>
            ) : (
              <List className={classes.scrollableList}>
                {eventos.map((evento, index) => (
                  <React.Fragment key={evento.na}>
                    <ListItem className={classes.listItem} onClick={() => handleDetalheAtividade(evento)}>
                      <ListItemIcon>
                        <EventNoteIcon />
                      </ListItemIcon>
                      <Box>
                        <ListItemText
                          primary={
                            <Typography component="span" className={classes.title}>
                              {evento.assunto}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography component="span" variant="body2" color="textSecondary">
                                <strong>Responsável:</strong> <span className={classes.value}>{evento.nomeTecnico || 'Sem Responsável Atribuido'}</span>
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2" color="textSecondary">
                                <strong>Cliente:</strong> <span className={classes.value}>{evento.nomeCliente}</span>
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2" color="textSecondary">
                                <strong>NA:</strong> <span className={classes.value}>{evento.na}</span>
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2" color="textSecondary">
                                <strong>Série:</strong> <span className={classes.value}>{evento.numSerieRastreadorInstalado}</span>
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2" color="textSecondary">
                                <strong>Placa:</strong> <span className={classes.value}>{evento.veiculoIdName}</span>
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2" color="textSecondary">
                                <strong>Serviço:</strong> <span className={classes.value}>{evento.servico}</span>
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2" color="textSecondary">
                                <strong>Previsão de Início:</strong> <span className={classes.value}>{DataUtils.formatarHora(evento.previsaoInicio)}</span>
                              </Typography>
                            </>
                          }
                        />
                        {getStatusStyle(evento.status) && (
                          <Box
                            className={classes.status}
                            style={{
                              background: getStatusStyle(evento.status).background,
                            }}
                          >
                            {getStatusStyle(evento.status).label}
                          </Box>
                        )}
                      </Box>
                    </ListItem>
                    {index < eventos.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            )}
          </>
        )}
      </Card>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openDialogConfirmaInicioAtividade}
        style={{ marginTop: 45, marginBottom: 10 }}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenDialogConfirmaInicioAtividade(false)
          }
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ position: 'sticky', top: 0, zIndex: 1100, backgroundColor: 'white' }}>
          <Grid container alignItems="center">
            <Grid item xs={10} sm={11}>
              Iniciar Atividade de Serviço
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton onClick={() => setOpenDialogConfirmaInicioAtividade(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start', marginBottom: '16px' }}>
            Depois de iniciada, a atividade não poderá mais ser interrompida. Tem certeza de que deseja
            iniciar a atividade?
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setOpenDialogConfirmaInicioAtividade(false)}
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', float: 'right', height: 55 }}
          >
            Fechar
          </Button>

          <BlueButton
            variant="contained"
            fullWidth
            onClick={handleIniciarAtividadeDeServico}
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
          >
            Confirmar
          </BlueButton>
        </DialogActions>
      </Dialog>

      {atividadeServico && (
        <AgendamentoDialog
          fullscreen
          osNumero={atividadeServico.os} 
          atividadeServico={atividadeServico}
          handleClose={fechaDetalhesAtividade}
          handleOrdemServico={handleOrdemServico}
          handleDetalhesOrdemServico={handleDetalhesOrdemServico}
        />
      )}
    </>
  );
};

export default DailyAgenda;