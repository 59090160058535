import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import AgendaPage from './agenda/agenda-page';
import AtividadeServicoPage from './atividade-servico/atividade-servico-page';
import AuditoriaPage from './auditoria/auditoria-page';
import ListagemClientePage from './cliente/listagem-cliente-page';
import RevendaPage from './cliente/principal-cliente-page';
import ComunicacaoPage from './comunicacao/comunicacao-page';
import DocumentosPage from './documentos/documentos-page';
import EstoquePage from './estoque/estoque-page';
import HistoricoEstoquePage from './estoque/historico-estoque-page';
import EstoqueSolicitacaReversaPage from './estoque/solicitacao-reversa-page';
import FinanceiroPage from './financeiro/financeiro-page';
import RemanufaturaGestorPage from './gestor/estoque/remanufatura-page';
import GestorPage from './gestor/gestor-page';
import GestorNaPage from './gestor/na-gestor-page';
import GestorTecnicoPage from './gestor/tecnico/gestor-tecnico-page';
import VistoriaGestorPage from './gestor/vistoria-gestor-page';
import HomePage from './home/home-page';
import InventarioPage from './inventario/inventario-page';
import LiberacaoEstoqueGestorPage from './liberacaoEstoque/liberacao-estoque-gestor-page';
import LoginPage from './login/login-page';
import NotasFiscaisPage from './notafiscal/nota-fiscal-page';
import NoticiasPage from './noticias/noticias-page';
import NotificacoesGestorPage from './notificacoes/gestor/notificacoes-page';
import NotificacoesPage from './notificacoes/tecnico/notificacao-page';
import OportunidadePage from './oportunidade/oportunidade-page';
import OrdemServicoPage from './ordemServico/ordemServico-page';
import EsqueciSenhaPage from './password/esqueci-senha-page';
import PerfilTecnicoPage from './perfilTecnico/perfil-tecnico-page';
import RedefinirSenhaPage from './redefinirSenha/redefinir-senha-page';
import authService from './Services/auth/auth-service';
import ServicosPage from './servicos/servicos-page';
import PermissoesPage from './tecnico/permissoes/permissoes-page';
import TecnicoPage from './tecnico/tecnico-page';
import VendaPage from './venda/venda-page';
import AprovadaPage from './vistoria/aprovado';
import RecusadaPage from './vistoria/recusado';
import VistoriaPage from './vistoria/vistoriaPage';
import AgendaTecnicoPage from './gestor/agenda/agendas-tecnicos-page';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authService.getUserInfo() != null || authService.getUserInfo() != undefined ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={LoginPage} />
      <Route path='/health' />
      <PrivateRoute path="/clientes" component={ListagemClientePage} />
      <PrivateRoute path="/financeiro" component={FinanceiroPage} />
      <PrivateRoute path="/gestor" component={GestorPage} />
      <PrivateRoute path="/nagestor" component={GestorNaPage} />
      <PrivateRoute path="/home" component={HomePage} />
      <PrivateRoute path="/oportunidades" component={OportunidadePage} />
      <PrivateRoute path="/agenda" component={AgendaPage} />
      <PrivateRoute path="/revenda" component={RevendaPage} />
      <PrivateRoute path="/ordemservico/:na?" component={OrdemServicoPage} />
      <PrivateRoute path="/atividadeservico/:na?" component={AtividadeServicoPage} />
      <PrivateRoute path="/inventario" component={InventarioPage} />
      <PrivateRoute path="/solicitacao-reversa" component={EstoqueSolicitacaReversaPage} />
      <PrivateRoute path="/manuais" component={DocumentosPage} />
      <PrivateRoute path="/estoque" component={EstoquePage} />
      <PrivateRoute path="/auditoria" component={AuditoriaPage} />
      <PrivateRoute path="/notas-fiscais" component={NotasFiscaisPage} />
      <PrivateRoute path="/venda" component={VendaPage} />
      <PrivateRoute path="/ordem-de-servico" component={ServicosPage} />
      <PrivateRoute path="/noticias" component={NoticiasPage} />
      <PrivateRoute path="/vistoriagestor" component={VistoriaGestorPage} />
      <PrivateRoute path="/comunicacao" exact component={ComunicacaoPage} />
      <PrivateRoute path="/gestor-tecnico" exact component={GestorTecnicoPage} />
      <PrivateRoute path="/cadastro-tecnico" exact component={PerfilTecnicoPage} />
      <PrivateRoute path="/liberacao-estoque-gestor" exact component={LiberacaoEstoqueGestorPage} />
      <PrivateRoute path="/notificacoes" exact component={NotificacoesPage} />
      <PrivateRoute path="/historico-do-estoque" exact component={HistoricoEstoquePage} />
      <PrivateRoute path="/notificacoes-gestor" exact component={NotificacoesGestorPage} />
      <PrivateRoute path="/remanufatura" exact component={RemanufaturaGestorPage} />
      <PrivateRoute path="/historicoEstoque" exact component={HistoricoEstoquePage} />
      <PrivateRoute path="/notificacoesgestor" exact component={NotificacoesGestorPage} />
      <PrivateRoute path="/tecnico-permissoes" exact component={PermissoesPage} />
      <PrivateRoute path="/agendas-do-tecnico" exact component={AgendaTecnicoPage} />
      <Route path="/recuperar-senha" component={RedefinirSenhaPage} />
      <Route path="/esqueci-a-senha" component={EsqueciSenhaPage} />
      <Route path="/credeciamento-tecnico" component={TecnicoPage} />
      <Route path="/vistoria" component={VistoriaPage} />
      <Route path="/aprovado" component={AprovadaPage} />
      <Route path="/recusado" component={RecusadaPage} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
