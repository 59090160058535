import { Button, Container, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import EventNoteIcon from '@mui/icons-material/EventNote';
import InventoryIcon from '@mui/icons-material/Inventory';
import '../agenda/components/react-big-calendar.css';
import MenuComponent from '../components/menu/menu-component';
import { MaterialTableCustom } from '../components/table/material-table-custom';
import authService from '../Services/auth/auth-service';
import estoqueService from '../Services/Estoque/estoque-service';
import DetalheEstoquePage from './detalhe-estoque-page';
import DetalheOrdemServicoPage from './detalhe-ordemservico-page';

export default function GestorPage() {

  const [estoqueItens, setEstoque] = useState([]);
  const [ordemServico, setordemServico] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showDetails2, setShowDetails2] = useState(false);
  const [estoqueProduto, setEstoqueProduto] = useState(null);
  useEffect(() => {
    estoque();
  }, []);

  const handleClose = () => {
    setEstoqueProduto(null);
    setShowDetails(false);
  };
  
  const handleCloseNa = () => {
    setordemServico(null);
    setShowDetails2(false);
  };

  //CONSULTA ESTOQUE
  const estoque = async () => {
    let area = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

    let estoqueLista = await estoqueService.exibirEstoque(area);

    if (estoqueLista) {
      let _estoqueItens = [];

      estoqueLista.map((m) => {
        _estoqueItens.push({
          area: m.area || '',
        });
      });
      setEstoque(_estoqueItens);
    }
    console.log(estoqueLista)
  };

  
  const handleClick = (nota) => {
    setEstoqueProduto(nota);
    setShowDetails(!showDetails);
  };
  
  
  const handleClickNA = (nota) => {
    setordemServico(nota)
    setShowDetails2(!showDetails2);
  };

  return (
    <>
      <MenuComponent />
      
      <Container component="main" style={{ paddingBottom: 40 }}  >
        <Grid container spacing={2} lg={12}>
          <Grid item lg={5} xs={12}>
            <MaterialTableCustom
              title="Poder terceiro"
              columns={[
                {
                  title: 'Técnico',
                  field: 'area',
                  width: '100%',
                },
                {
                  title: 'Estoque Técnico',
                  field: '',
                  width: '100%',
                  render: (rowEstoque) => (
                    <Button onClick={() => handleClick(rowEstoque)}>
                      <InventoryIcon />
                    </Button>
                  ),
                  sorting: false,
                },            
                {
                  title: 'Ordem de Serviço',
                  field: '',
                  width: '100%',
                  render: (rowData) => (
                    <Button onClick={() => handleClickNA(rowData)}>
                      <EventNoteIcon />
                    </Button>
                  ),
                  sorting: false,
                },
              ]}
              data={estoqueItens}
              localization={{
                body: {
                  emptyDataSourceMessage: 'Nenhum registro para exibir',
                },
                toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar',
                },
                pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{from}-{to} de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página',
                },
            
              }}
              options={{
                pageSize: 10,
              }}
            />

            {estoqueProduto && showDetails  && <DetalheEstoquePage handleClose={handleClose} />}
            {ordemServico && showDetails2  && <DetalheOrdemServicoPage handleClose={handleCloseNa} />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
