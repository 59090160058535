import React, { useEffect, useState } from 'react';
import {
  Paper, Grid, TextField, CircularProgress,
  Typography, InputAdornment, IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import OportunidadeService from '../Services/oportunidade/oportunidade-service';
import MaskUtil from '../util/MaskUtil';
import authService from '../Services/auth/auth-service';
import MenuComponent from '../components/menu/menu-component';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px'
    }
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    marginBottom: '5px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    textAlign: 'start',
    marginTop: '0px',
    fontWeight: 400, 
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
  },
  tableHeader: {
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
    padding: '10px',
    textAlign: 'left',
  },
  tableRow: {
    borderBottom: '1px solid #e0e0e0',
    padding: '10px',
    textAlign: 'left',
  },
  noData: {
    textAlign: 'center',
    padding: '20px 0',
  },
}));

export default function OportunidadePage() {
  const classes = useStyles();
  const [oportunidades, setOportunidades] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const userInfo = authService.getUserInfo();
    const areaAtendimentoID = userInfo.usuario.tz_area_atendimento_microsigaid;
    fetchOportunidades(areaAtendimentoID);
  }, []);

  const fetchOportunidades = async (areaAtendimentoID) => {
    try {
      setLoading(true);
      const result = await OportunidadeService.oportunidadesRevenda(areaAtendimentoID);
      if (result) {
        const oportunidadesFormatadas = result.map((x) => ({
          id: x.OportunidadeID || '',
          oportunidade: x.Oportunidade || '',
          datacriacao: x.DataCriacao || '',
          qtdveiculo: x.qtdVeiculo || 0,
          vl_licenca: MaskUtil.numberToReal(x.valorTotalLicenca) || MaskUtil.numberToReal(0),
          vl_hardware: MaskUtil.numberToReal(x.valorTotalHardware) || MaskUtil.numberToReal(0),
          ticket_medio: MaskUtil.numberToReal(x.TicketMedio) || MaskUtil.numberToReal(0),
          status: getStatus(x.Status),
        }));
        setOportunidades(oportunidadesFormatadas);
        setFilteredData(oportunidadesFormatadas);
      }
    } catch (error) {
      console.error('Erro ao buscar oportunidades:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 0: return 'Aberta';
      case 1: return 'Ganha';
      case 2: return 'Perdida';
      default: return '';
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    if (term) {
      const filtered = oportunidades.filter((item) =>
        item.oportunidade.toLowerCase().includes(term.toLowerCase()) ||
        item.status.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(oportunidades);
    }
  };

  return (
    <>
      <MenuComponent />
      <div style={{ margin: 30 }}>
        <h3 className={classes.tituloStyle}>Oportunidades</h3>
        <h4 className={classes.subtituloStyle}>
          <span
            onClick={() => window.location.assign('/revenda')}
            style={{ cursor: 'pointer' }}
          >
            Home
          </span>
          {' > Oportunidades'}
        </h4>

        <Grid container justifyContent="flex-end">
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Pesquisar"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ marginBottom: '16px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm ? (
                      <IconButton onClick={() => handleSearch('')} size="small">
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <SearchIcon />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Paper className={classes.paper}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : filteredData.length > 0 ? (
            <Grid container spacing={2}>
              <Grid container item xs={12} style={{ backgroundColor: '#f5f5f5', padding: '10px' }}>
                <Grid item xs={3} className={classes.tableHeader}>Oportunidade</Grid>
                <Grid item xs={2} className={classes.tableHeader}>Data Criação</Grid>
                <Grid item xs={2} className={classes.tableHeader}>Status</Grid>
                <Grid item xs={1} className={classes.tableHeader}>Qtd Veículos</Grid>
                <Grid item xs={2} className={classes.tableHeader}>Ticket Médio</Grid>
                <Grid item xs={1} className={classes.tableHeader}>Licença</Grid>
                <Grid item xs={1} className={classes.tableHeader}>Hardware</Grid>
              </Grid>
              {filteredData.map((row) => (
                <Grid container item xs={12} key={row.id}>
                  <Grid item xs={3} className={classes.tableRow}>{row.oportunidade}</Grid>
                  <Grid item xs={2} className={classes.tableRow}>{row.datacriacao}</Grid>
                  <Grid item xs={2} className={classes.tableRow}>{row.status}</Grid>
                  <Grid item xs={1} className={classes.tableRow}>{row.qtdveiculo}</Grid>
                  <Grid item xs={2} className={classes.tableRow}>{row.ticket_medio}</Grid>
                  <Grid item xs={1} className={classes.tableRow}>{row.vl_licenca}</Grid>
                  <Grid item xs={1} className={classes.tableRow}>{row.vl_hardware}</Grid>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div className={classes.noData}>
              <Typography variant="subtitle1" color="textSecondary">
                Nenhum registro para exibir
              </Typography>
            </div>
          )}
        </Paper>
      </div>
    </>
  );
}
