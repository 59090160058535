import config from '../../config';
import authService from '../auth/auth-service';

const _configAPITecnico = config.configAPITecnico();

class AgendaService {

  async AgendaTecnico(areaAtendimentoID, dtInicio, dtFim,LoginTecnico) {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/agenda/tecnico?AreaAtendimentoId=${areaAtendimentoID}&DataInicial=${dtInicio}&DataFinal=${dtFim}&LoginTecnico=${LoginTecnico}`,
        {
          method: 'GET',
          headers: authService.headerAPITecnico(),
        },
      );

      if (response.status === 200) return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async AgendaTecnicoTotal(areaAtendimentoID,LoginTecnico) {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/agenda/tecnico/total?AreaAtendimentoId=${areaAtendimentoID}&LoginTecnico=${LoginTecnico}`,
        {
          method: 'GET',
          headers: authService.headerAPITecnico(),
        },
      );

      if (response.status === 200) return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async getNas(dados, microSigaID) {
    try {
      let tz_area_atendimento_microsigaid = '';
      
      if(microSigaID){
        tz_area_atendimento_microsigaid = microSigaID;
      }else{
        tz_area_atendimento_microsigaid = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
      }

      let response = await fetch(`${_configAPITecnico.URI_API}/agenda/nas?AreaAtendimentoId=${tz_area_atendimento_microsigaid}&DataInicial=${dados.dataInicial}&DataFinal=${dados.dataFinal}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.nome}&Status=${dados.status}`, {
        method: 'GET',
        headers: authService.headerAPITecnico(),
      });

      const data = await response.json();
      let errorsMessage = null;
      if(data.errors){
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      console.log(error)
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados.',
      };
    }
  }

  async buscarDadosNAPorPeriodo(microSigaID, mes, ano) {
    try {
      let tz_area_atendimento_microsigaid = '';
      
      if(microSigaID){
        tz_area_atendimento_microsigaid = microSigaID;
      }else{
        tz_area_atendimento_microsigaid = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
      }

      let response = await fetch(`${_configAPITecnico.URI_API}/agenda/nasMes?AreaAtendimentoId=${tz_area_atendimento_microsigaid}&Mes=${mes}&Ano=${ano}`, {
        method: 'GET',
        headers: authService.headerAPITecnico(),
      });

      const data = await response.json();
      let errorsMessage = null;
      if(data.errors){
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      console.log(error)
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados.',
      };
    }
  }

  async buscarDadosNA(AreaAtendimentoId, NumeroNA) {
    try {
      const url = `${_configAPITecnico.URI_API}/Agenda/buscarNa?AreaAtendimentoId=${AreaAtendimentoId}&NumeroNA=${NumeroNA}`;
      
      const response = await fetch(url, {
        method: 'GET',
        headers: authService.headerAPITecnico(),
      });
  
      const data = await response.json();
  
      if (response.status === 200) {
        return data; 
      } else {
        const errorsMessage = Object.values(data.errors || {}).map((error) => error);
        return {
          error: true,
          message: data.erro || errorsMessage,
        };
      }
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados.',
      };
    }
  }
  
}

export default new AgendaService();
