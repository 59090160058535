import {
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import UpdateIcon from '@material-ui/icons/Update';
import Alert from '@material-ui/lab/Alert';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import React, { useEffect, useState } from 'react';
import baseInstaladaService from '../../Services/BaseInstalada/base-instalada-service';
import sensoresService from '../../Services/Sensores/sensores-service';
import authService from '../../Services/auth/auth-service';
import CustomAlert from '../../components/alert/CustomAlert';
import BlueButton from '../../components/button/BlueButton';
import CustomSelect from '../component/CustomSelect';

export default function BaseInstaladaComponent(props) {
  const [, setOpcaoForm] = useState(null);
  const [numSerie, setNumSerie] = useState(null);
  const [formBaseInstalada, setFormBaseInstalada] = useState(false);
  const [sensores, setSensores] = useState([]);
  const [alertBaseInstaladaTotal, setAlertBaseInstaladaTotal] = useState(false);
  const mensagemAlertBaseInstaladaTotal =
    'A série pesquisada não possui base instalada disponível para ser visualizada.';
  const usuarioID = authService.getUserInfo().usuario.id;
  const [sensorConfig1, setSensorConfig1] = useState(null);
  const [sensorConfig2, setSensorConfig2] = useState(null);
  const [sensorConfig3, setSensorConfig3] = useState(null);
  const [sensorConfig4, setSensorConfig4] = useState(null);
  const [sensorPainel1Selecionado, setSensorPainel1Selecionado] = useState(false);
  const [sensorPainel2Selecionado, setSensorPainel2Selecionado] = useState(false);
  const [tipoTravaBau, setTipoTravaBau] = useState(null);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [efeitoCarregando, setEfeitoCarregando] = useState(false);
  const [efeitoCarregandoBaseInstalada, setEfeitoCarregandoBaseInstalada] = useState(false);
  const [serie, setSerie] = useState('');

  const tipoTravaBauOptions = [
    { value: 1, label: 'Solenoide' },
    { value: 2, label: 'Motorizada' },
    { value: 3, label: 'Inteligente' },
  ];

  const mapSensoresToOptions = (sensores) =>
    sensores.map((sensor) => ({
      value: sensor.id || sensor.value || null,
      label: sensor.descricao || sensor.label || 'Sem descrição',
    }));

  useEffect(() => {
    sensoresConfiguraveis();
  }, []);

  const handleBaseInstalada = async (event) => {
    event.preventDefault();

    const data = new FormData(event.target);

    let numSerie = data.get('txtSerie');

    if (numSerie === '') {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'É obrigatório o preenchimento do número da série.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    setEfeitoCarregando(true);

    let inventarioResult = await baseInstaladaService.total(props.na, numSerie);

    if (inventarioResult && inventarioResult.total > 0) {
      setAlertBaseInstaladaTotal(false);
      setFormBaseInstalada(true);
      setNumSerie(numSerie);
      setEfeitoCarregando(false);
    } else {
      setFormBaseInstalada(false);
      setAlertBaseInstaladaTotal(true);
      setEfeitoCarregando(false);
    }
  };
  const handlePaste = async () => {
    if (navigator.clipboard && navigator.clipboard.readText) {
      try {
        const text = await navigator.clipboard.readText();

        const isValid = /^[a-zA-Z0-9]+$/.test(text);
        if (!isValid) {
          setMensagemAlert({
            title: 'Atenção!',
            body: 'O número de série deve conter apenas letras e números.',
          });
          setTipoAlert('fail');
          setAbrirAlert(true);
          return;
        }
        setSerie(text);
      } catch (error) {
        setMensagemAlert({ title: 'Atenção!', body: 'Não foi possivel colar!' });
        setTipoAlert('fail');
        setAbrirAlert(true);
      }
    }
  };
  
  const sensoresConfiguraveis = async () => {
    let sensoresResult = await sensoresService.configuravel();
    if (sensoresResult) setSensores(sensoresResult);
  };

  const isTrue = (value) => {   return value !== null && value !== undefined && value !== 'false'; };

  const formBaseInstaladaHandle = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    if (
      sensorPainel1Selecionado !== 'Sensor de Painel' &&
      sensorPainel2Selecionado !== 'Sensor de Painel'
    ) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'É obrigatório selecionar a opção de "Sensor de Painel" no sensor configurável 1 ou 2.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    setEfeitoCarregandoBaseInstalada(true);

    const baseInstalada = {
      Numero_Serie: numSerie,
      Bau_Lateral: isTrue(data.get('tgBauLateral')),
      Teclado_Multimidia: isTrue(data.get('tgTecladoMultimidia')),
      Bau_Intermediario: isTrue(data.get('tgBauIntermediario')),
      Trava_Bau_Intermediria: isTrue(data.get('tgTravaBauIntermediario')),
      Telemetria: isTrue(data.get('tgTelemetria')),
      Boto_Panico: isTrue(data.get('tgBotaoPanico')),
      Bateria_Backup: isTrue(data.get('tgBateriaBackup')),
      Setas_Pulsantes: isTrue(data.get('tgSetasPulsantes')),
      Ignicao: isTrue(data.get('tgIgnicao')),
      Teclado: isTrue(data.get('tgTecladoCompacto')),
      Sirene: isTrue(data.get('tgSirene')),
      Bloqueio_Solenoide: isTrue(data.get('tgBloqueioSolenoide')),
      Engate_Eletronico: isTrue(data.get('tgEngateEletronico')),
      Bloqueio_Eletronico: isTrue(data.get('tgBloqueioEletronico')),
      Trava_Bau_Lateral: isTrue(data.get('tgTravaBauLateral')),
      Engate_Aspiral: isTrue(data.get('tgEngateEspiral')),
      Temperatura: isTrue(data.get('tgTemperatura')),
      Portas_Cabine: isTrue(data.get('tgPortaCabine')),
      Bau_Traseiro: isTrue(data.get('tgBauTraseiro')),
      Trava_Bau_Traseira: isTrue(data.get('tgBauTraseira')),
      painel: isTrue(data.get('tgPainel')),
      Trava_Quinta_Roda: isTrue(data.get('tgTravaQuintaRoda')),
      Setas_Continuas: isTrue(data.get('tgSetasContinuas')),
      tipo_trava_bau: tipoTravaBau ? parseInt(tipoTravaBau.value) : null,
      sensor_configuravel1: sensorConfig1 ? parseInt(sensorConfig1.value) : null,
      sensor_configuravel2: sensorConfig2 ? parseInt(sensorConfig2.value) : null,
      sensor_configuravel3: sensorConfig3 ? parseInt(sensorConfig3.value) : null,
      sensor_configuravel4: sensorConfig4 ? parseInt(sensorConfig4.value) : null,
    };

    let atualizaResult = await baseInstaladaService.atualiza(
      JSON.stringify(baseInstalada),
      props.na,
      usuarioID
    );
    if (atualizaResult) {
      setMensagemAlert({
        title: 'Sucesso!',
        body: 'Base Instalada atualizada com sucesso!',
      });
      setTipoAlert('success');
      setAbrirAlert(true);
      setEfeitoCarregandoBaseInstalada(false);
    } else {
      setMensagemAlert({
        title: 'Erro!',
        body: 'Não foi possível atualizar a base instalada.',
      });
      setTipoAlert('error');
      setAbrirAlert(true);
      setEfeitoCarregandoBaseInstalada(false);
    }
  };

  const handleSensor1Change = (selectedOption) => {
    setSensorConfig1(selectedOption || null);
    setSensorPainel1Selecionado(selectedOption ? selectedOption.label : false);
  };
  
  const handleSensor2Change = (selectedOption) => {
    setSensorConfig2(selectedOption || null);
    setSensorPainel2Selecionado(selectedOption ? selectedOption.label : false);
  };

  return (
    <Container style={{ padding: '5px' }}>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      <div>
        <form onSubmit={handleBaseInstalada} required>
          <Grid style={{ flexGrow: 1 }} container spacing={3}>
            <Grid item xs={12} sm={5} md={3}>
              <TextField
                name="txtSerie"
                variant="outlined"
                label={
                  <span>
                    Número de Série <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                fullWidth
                value={serie}
                onChange={(e) => setSerie(e.target.value)}
                onInput={(e) => {
                  const value = e.target.value
                  e.target.value = value.replace(/[^a-zA-Z0-9]/g, '')
                  setSerie(e.target.value)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Colar número de série">
                      <IconButton onClick={handlePaste} edge="end">
                        <ContentPasteIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={5} md={3}>
              <BlueButton
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                style={{ height: 55 }}
                startIcon={efeitoCarregando ? <CircularProgress size={20} /> : <SearchIcon />}
                disabled={efeitoCarregando}
              >
                <span style={{ fontSize: '0.7rem' }}>
                  {efeitoCarregando ? ' PESQUISANDO...' : ' PESQUISAR BASE'}
                </span>
              </BlueButton>
            </Grid>

            <Grid xs={12} md={8} item>
              {alertBaseInstaladaTotal && (
                <Alert severity="error">{mensagemAlertBaseInstaladaTotal}</Alert>
              )}
            </Grid>
          </Grid>
        </form>

        {formBaseInstalada && (
          <form onSubmit={formBaseInstaladaHandle} required>
            <Grid container style={{ flexGrow: 1 }} spacing={3}>
              {/* Checkbox Inputs */}
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgBauLateral" color="primary" />}
                  label="Baú Lateral"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgTecladoMultimidia" color="primary" />}
                  label="Teclado Multimidia"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgBauIntermediario" color="primary" />}
                  label="Baú Intermediário"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgTravaBauIntermediario" color="primary" />}
                  label="Trava Baú Intermediário"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgTelemetria" color="primary" />}
                  label="Telemetria"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgBotaoPanico" color="primary" />}
                  label="Botão Pânico"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgBateriaBackup" color="primary" />}
                  label="Bateria Backup"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgSetasPulsantes" color="primary" />}
                  label="Setas Pulsantes"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgIgnicao" color="primary" />}
                  label="Ignição"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgTecladoCompacto" color="primary" />}
                  label="Teclado Compacto"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgSirene" color="primary" />}
                  label="Sirene"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgBloqueioSolenoide" color="primary" />}
                  label="Bloqueio Solenoide"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgEngateEletronico" color="primary" />}
                  label="Engate Eletrônico"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgBloqueioEletronico" color="primary" />}
                  label="Bloqueio Eletrônico"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgTravaBauLateral" color="primary" />}
                  label="Trava Baú Lateral"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgEngateEspiral" color="primary" />}
                  label="Engate Espiral"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgTemperatura" color="primary" />}
                  label="Temperatura"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgPortaCabine" color="primary" />}
                  label="Portas Cabine"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgBauTraseiro" color="primary" />}
                  label="Baú Traseiro"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgBauTraseira" color="primary" />}
                  label="Travas Baú Traseira"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgPainel" color="primary" />}
                  label="Painel"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgTravaQuintaRoda" color="primary" />}
                  label="Trava Quinta Roda"
                />
              </Grid>
              <Grid xs={6} md={3} lg={3} item>
                <FormControlLabel
                  control={<Checkbox name="tgSetasContinuas" color="primary" />}
                  label="Setas Contínuas"
                />
              </Grid>
            </Grid>

            {/* Select Inputs */}
            <Grid container style={{ flexGrow: 1 }} spacing={3}>
              <Grid xs={12} md={6} lg={6} item>
                <FormControl style={{ width: '100%' }}>
                <CustomSelect
  options={mapSensoresToOptions(sensores)}
  value={sensorConfig1}
  onChange={handleSensor1Change}
  placeholder="Sensor Configurável 1"
  isClearable
/>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6} lg={6} item>
                <FormControl style={{ width: '100%' }}>
                  <CustomSelect
                    options={mapSensoresToOptions(sensores)}
                    value={sensorConfig2}
                    onChange={handleSensor2Change}
                    placeholder="Sensor Configurável 2"
                    isClearable
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6} lg={6} item>
                <FormControl style={{ width: '100%' }}>
                  <CustomSelect
                    options={mapSensoresToOptions(sensores)}
                    value={sensorConfig3}
                    onChange={setSensorConfig3}
                    placeholder="Sensor Configurável 3"
                    isClearable
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6} lg={6} item>
                <FormControl style={{ width: '100%' }}>
                  <CustomSelect
                    options={mapSensoresToOptions(sensores)}
                    value={sensorConfig4}
                    onChange={setSensorConfig4}
                    placeholder="Sensor Configurável 4"
                    isClearable
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6} lg={6} item>
                <FormControl style={{ width: '100%' }}>
                  <CustomSelect
                    options={tipoTravaBauOptions}
                    value={tipoTravaBau}
                    onChange={setTipoTravaBau}
                    placeholder="Tipo Trava Baú"
                    isClearable
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container style={{ flexGrow: 1 }} spacing={2}>
              <Grid item xs={12} sm={8}></Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ textAlign: 'right', marginTop: '3px' }}
              >
                <div style={{ display: 'flex', paddingTop: 15 }}>
                  <BlueButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    onClick={() => setOpcaoForm(1)}
                    style={{ height: 55 }}
                    startIcon={
                      efeitoCarregandoBaseInstalada ? (
                        <CircularProgress size={20} />
                      ) : (
                        <UpdateIcon />
                      )
                    }
                    disabled={efeitoCarregandoBaseInstalada}
                  >
                    <span style={{ fontSize: '0.7rem' }}>
                      {efeitoCarregandoBaseInstalada
                        ? ' ATUALIZANDO...'
                        : ' ATUALIZAR BASE'}
                    </span>
                  </BlueButton>
                </div>
              </Grid>
              <Grid item xs={12}>
                {sensorPainel1Selecionado !== 'Sensor de Painel' &&
                  sensorPainel2Selecionado !== 'Sensor de Painel' && (
                  <Alert severity="error">
                    É obrigatório selecionar a opção de <strong>Sensor de Painel</strong> no sensor configurável 1 ou 2!
                  </Alert>
                  )}
              </Grid>
            </Grid>
          </form>
        )}
      </div>
    </Container>
  );
}
