import authService from '../auth/auth-service';
import config from '../../config';

const _userInfo = authService.getUserInfo();
const _configAPITecnico = config.configAPITecnico();

class PerfilService {
  async AlterarSenha(usuario) {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/usuario/senha`,
        {
          method: 'PUT',
          headers: authService.headerAPITecnico(),
          body: usuario,
        },
      );

      if (response.status === 204) return { success: true };
      if (response.status === 400) return await response.json();

    } catch (error) {
      return { success: false, message: error };
    }
  }

  async getInformacoesPerfilOuro() {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/perfilValeOuro/${_userInfo.usuario.areaAtendimentoErp}`,
        {
          method: 'GET',
          headers: authService.headerAPITecnico(),
        },
      );
      if (response.status === 200) return await response.json();
    } catch (error) {
      return { success: false, message: error };
    }
  }

  async getInformacoesTecnico() {
    try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const email = userInfo?.usuario?.login;

        const response = await fetch(
            `${_configAPITecnico.URI_API}/tecnico/buscarTecnico?Email=${email}`,
            {
                method: 'GET',
                headers: authService.headerAPITecnico(),
            },
        );

        if (response.status === 200) {
            const data = await response.json();
            return data;
        } else {
            return { success: false, message: `Erro na API: Status ${response.status}` };
        }
    } catch (error) {
        return { success: false, message: error.message || 'Erro desconhecido' };
    }
}
  

  async consultaperfiltecnico(idtecnico) {
    try {
      let response = await fetch(`http://localhost:58584/api/consultaperfil/consultacadastroperfil${idtecnico}`, {
        method: 'GET',
        headers: authService.headerAPITecnico()
      });
      if (response.status === 200)
        return await response.json();

    } catch (error) {
      return { success: false, message: error };
    }
  }

  async redefinirSenha(dados) {
    try {
      let response = await fetch(
        `${_configAPITecnico.URI_API}/usuario/senha/atualizarSenha`,
        {
          method: 'PUT',
          headers: authService.headerAPITecnico(),
          body: JSON.stringify(dados),
        },
      );

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 204){
        return {
          success: true
        };
      }

      return {
        error: true,
        message: data.erro || errorsMessage,
      };

    } catch (error) {
      return { success: false, message: error };
    }
  }
}
export default new PerfilService();