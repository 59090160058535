import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import CryptoJS from 'crypto-js';
import React, { useEffect, useState } from 'react';
import ParticleAnimation from 'react-particle-animation';
import logoOmnilink from '../../src/assets/img/logoOmni.png';
import CustomAlert from '../components/alert/CustomAlert';
import BlueButton from '../components/button/BlueButton';
import FacebookCircularProgress from '../components/progress/CircularProgressBlue';
import BlueTextField from '../components/textfield/BlueTextField';
import useSignUpForm from '../hooks/useSignUpForm';
import authService from '../Services/auth/auth-service';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff'
  },
  recuperar: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(1, 'auto'),
  },
  container: {
    backgroundColor: '#ffffff',
    position: 'relative',
    borderRadius: 10,
    maxWidth: '355px',
    maxHeight: '550px',
    marginTop: theme.spacing(8),
  },
  credenciadoLink: {
    marginBottom: theme.spacing(3),
  },
  esqueciSenhaLink: {
    color: '#50afff',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default function LoginPage(props) {
  const login = async () => {
    setLoad(true);
    setValidaLogin(true);
    if (inputs.email && inputs.password) {
      let authResult = await authService.login(JSON.stringify({
        usuario: inputs.email,
        senha: CryptoJS.MD5(inputs.password).toString()
      }));

      if (authResult) {
        const currentUser = inputs.email;

        // Armazenar o usuário logado no localStorage
        localStorage.setItem('firstLoginUser', currentUser);

        // Armazene também os dados completos do usuário
        localStorage.setItem('userInfo', JSON.stringify(authResult));

        // Armazene o horário de login (em milissegundos)
        localStorage.setItem('loginTime', Date.now());


        if (authResult.usuario.gestor) {
          props.history.push('/gestor');
        } else if (authResult.usuario.revenda) {
          props.history.push('/revenda');
        } else {
          props.history.push('/agenda');
        }
      } else {
        setAlertType('fail');
        setAlertMessage({ title: 'Atenção!', body: 'Usuário ou senha incorretos. Tente novamente.' });
        setAlertOpen(true);
      }
      setValidaLogin(false);
    }
    setLoad(false);
  };

  const { inputs, handleInputChange, handleSubmit, initiChange } = useSignUpForm(login);
  const [load, setLoad] = React.useState(false);
  const [, setValidaLogin] = React.useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    initiChange({ email: '' })
  }, []);

  const classes = useStyles();

  function alterarSenha() {
    window.location.href = '/esqueci-a-senha'
  }

  function sejaUmCredenciado() {
    window.location.href = '/credeciamento-tecnico'
  }

  return (
    <div style={{ overflow: 'hidden', position: 'absolute', width: '100%', height: '100%' }}>
      <ParticleAnimation
        numParticles={110}
        interactive={true}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
        particleRadius={2.5}
        particleSpeed={1}
        background={{ r: 32, g: 155, b: 222, a: 255 }}
        color={{ r: 255, g: 255, b: 255, a: 255 }}
      />
      <Container className={classes.container} component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <img style={{ padding: 20 }} src={logoOmnilink}></img>
          <form onSubmit={handleSubmit} className={classes.form}>
            <BlueTextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              name="email"
              autoComplete="email"
              onChange={handleInputChange}
              value={inputs.email || ''}
              placeholder="Usuário"
              label="Usuário"
            />
            <BlueTextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              name="password"
              type="password"
              autoComplete="current-password"
              onChange={handleInputChange}
              value={inputs.password || ''}
              placeholder="Senha"
              label="Senha"
            />
            <Box style={{ padding: 5 }} display="flex" justifyContent="left">
              <a href="#" onClick={alterarSenha} className={classes.esqueciSenhaLink}>Esqueci minha senha</a>
            </Box>

            {load ?
              <div style={{ margin: 'auto', width: '50%' }}>
                <FacebookCircularProgress></FacebookCircularProgress>
              </div>
              :
              <BlueButton
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                ENTRAR
              </BlueButton>

            }
            <BlueButton
              onClick={sejaUmCredenciado}
              fullWidth
              className={classes.credenciadoLink}
              variant="contained"
            >
              SEJA UM CREDENCIADO
            </BlueButton>
          </form>
        </div>
      </Container>
      <CustomAlert
        open={alertOpen}
        message={alertMessage}
        type={alertType}
        onClose={() => setAlertOpen(false)}
      />
    </div>
  );
}
